// utils/chartHelpers.js


// components/LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend } from 'chart.js';
// import { createMasterArray, mapSentiments } from '../utils/chartHelpers';
// import { chartColors } from '../constants/colors';

// Register the required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);


const createMasterArray = (...dateArrays) => {
    const combinedDates = dateArrays.flat();
    const uniqueDates = new Set(combinedDates);
    return Array.from(uniqueDates).sort((a, b) => new Date(a) - new Date(b));
};

const mapSentiments = (masterDates, individualDates, sentiments) => {
    return masterDates.map(date => {
        const index = individualDates.indexOf(date);
        return index !== -1 ? sentiments[index] : undefined;
    });
};

// constants/colors.js
const chartColors = {
    'positive_1': 'rgba(3, 198, 82, 1)',
    'positive_2': 'rgba(3, 198, 82, 1)',
    'negative_1': 'rgba(238, 19, 7, 1)',
    'negative_2': 'rgba(238, 19, 7, 1)',
    'neutral_1': 'rgba(255, 232, 175, 1)',
    'neutral_2': 'rgba(255, 232, 175, 1)',
    // 'positive_1': 'rgba(118, 237, 166, 1)',
    // 'positive_1': 'rgba(253, 127, 120, 1)',
    // 'positive_1': 'rgba(252, 216, 126, 1)',
};


const TrendsInSentiments = ({
    theme=false,
    downloadFeature = false,
    topic1Filter,
    topic2Filter,
    width = 1000,
    positive_date_1,
    title_2 = '',
    title_1 = '',
    negative_date_1,
    neutral_date_1,
    positive_date_2,
    negative_date_2,
    neutral_date_2,

    positive_value_1,
    negative_value_1,
    neutral_value_1,
    positive_value_2,
    negative_value_2,
    neutral_value_2

}) => {

    const masterDates = createMasterArray(positive_date_1, negative_date_1, neutral_date_1);
    const masterDates2 = createMasterArray(positive_date_2, negative_date_2, neutral_date_2);

    // Map sentiments to master dates
    const mappedPositive1 = mapSentiments(masterDates, positive_date_1, positive_value_1);
    const mappedNegative1 = mapSentiments(masterDates, negative_date_1, negative_value_1);
    const mappedNeutral1 = mapSentiments(masterDates, neutral_date_1, neutral_value_1);
    const mappedPositive2 = mapSentiments(masterDates2, positive_date_2, positive_value_2);
    const mappedNegative2 = mapSentiments(masterDates2, negative_date_2, negative_value_2);
    const mappedNeutral2 = mapSentiments(masterDates2, neutral_date_2, neutral_value_2);

    // Helper function to create datasets
    const createDataset = (label, data, borderColor, pointBackgroundColor, xAxisID = 'x1', borderDash) => ({
        label,
        data,
        borderColor,
        pointBackgroundColor,
        fill: false,
        spanGaps: true,
        xAxisID,
        pointRadius: 4,
        borderDash: borderDash
    });
    const sentimentTrendsDataset = [
        topic1Filter.positive && createDataset('Positive 1', mappedPositive1, chartColors.positive_1, chartColors.positive_1, 'x1', [3, 5]),
        topic1Filter.negative && createDataset('Negative 1', mappedNegative1, chartColors.negative_1, chartColors.negative_1, 'x1', [3, 5]),
        topic1Filter.neutral && createDataset('Neutral 1', mappedNeutral1, chartColors.neutral_1, chartColors.neutral_1, 'x1', [3, 5]),
        topic2Filter.positive && createDataset('Positive 2', mappedPositive2, chartColors.positive_2, chartColors.positive_2, 'x2', [3, 0]),
        topic2Filter.negative && createDataset('Negative 2', mappedNegative2, chartColors.negative_2, chartColors.negative_2, 'x2', [3, 0]),
        topic2Filter.neutral && createDataset('Neutral 2', mappedNeutral2, chartColors.neutral_2, chartColors.neutral_2, 'x2', [3, 0]),
    ]
    // Prepare the data for the chart
    const data = {
        datasets: sentimentTrendsDataset
    };

    // Options for the chart, including multiple x-axes

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x1: {
                type: 'category',
                labels: (topic1Filter.positive || topic1Filter.negative || topic1Filter.neutral) && masterDates,
                grid: {
                    display: true,
                    drawOnChartArea: false,
                    // tickColor: '#1f1f1f',
                    
                tickColor:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // tickColor: downloadFeature == true ? 'white' : '#1f1f1f',
                    drawBorder: true,
                },
                border: {
                    display: true, // Ensure the axis border is shown
                    
                color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f', // Axis border color
                },
                title: {
                    display: downloadFeature == true ? false: true,
                    text: title_1,
                    // color: '#1f1f1f',
                    
                color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f',
                },
                ticks: {
                    // color: '#1f1f1f',

                    color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f',
                },
            },
            x2: {
                type: 'category',
                position: 'top',
                labels: (topic2Filter.positive || topic2Filter.negative || topic2Filter.neutral) && masterDates2,

                grid: {
                    display: true,
                    drawOnChartArea: false,
                    // tickColor: '#1f1f1f ',
                    
                    tickColor:theme== true ? downloadFeature == true ? '' :  '#1f1f1f' : '',
                    // tickColor: downloadFeature == true ? 'white' : '#1f1f1f',
                    drawBorder: true,
                },
                border: {
                    display: true, // Ensure the axis border is shown
                    
                color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f', // Axis border color
                },
                title: {
                    display: downloadFeature == true ? false: true,
                    text: title_2,
                    // color: '#1f1f1f',
                    
                color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f',
                },
                ticks: {
                    // color: '#1f1f1f',

                    display: downloadFeature == true ? false: true,
                    color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f',
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: true,
                    // tickColor: '#1f1f1f',
                    // tickColor: downloadFeature == true ? 'white' : '#1f1f1f',
                    
                tickColor:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    drawBorder: true,
                },
                border: {
                    display: true, // Ensure the axis border is shown
                    
                color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f', // Axis border color
                },
                title: {
                    display: true,
                    text: 'Reviews',
                    
                color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f', // Axis border color
                },
                ticks: {
                    // color: '#1f1f1f',

                    color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f',
                },
            },
            rightY: {
                position: 'right', // Position this axis on the right
                grid: {
                    display: false, // Disable grid lines for this axis
                    drawTicks: false, // Disable ticks
                },
                border: {
                    display: true, // Enable border on the right
                    
                color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f',// Border color for the opposite Y-axis
                },
                ticks: {
                    display: false, 
                    color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black',
                    // color: downloadFeature == true ? 'white' : '#1f1f1f',// Hide tick labels on this side
                },
            },
        },


        plugins: {
            legend: {
                display: false,

                color:theme== true ? downloadFeature == true ? 'white' : '#1f1f1f' : 'black', // Axis border color
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        return tooltipItems[0].label;
                    },

                },
                enabled: true,
            },
        },
    };

    return (
        // <div style={{ width: '100%', height: 500, marginBottom: '10px' }}>
        <Line
            data={data}
            options={options}
            width={900}
            height={400}
        />
        // </div>
    );
};

export default TrendsInSentiments;
