import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px 20px;
  text-align: left;
  position: relative;
`;

export const Text = styled.div`
  width: 95%;
  color: #636363;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px;
`;

export const ColumnWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
    width: 50%;
    max-width: 50%;
`;

export const MainContainer = styled.div`
  display: flex;
  gap: 15px;

  & input {
    // width: 100% !important;
    ::placeholder {
      color: #636363;
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 400;
    }
  }

  textarea[type='text'] {
    height: 293px;
  }
`;

export const QueryBuilderTitle_with_PreviewButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

export const TopicText = styled.div`
  color: #353cf7;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
`;

export const Preview = styled.button`
  background: #ffff;
  color: #636363;
  border: 1px solid rgba(162, 162, 162, 0.6);
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
  border-radius: 68px;
  padding: 8px 24px;

  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
  cursor: pointer;

  // background: #353cf7;
  // color: #fff;
`;

export const KeywordWrapper = styled.div`
  font-family: Montserrat;
  width: 100%;
`;

export const InstaWrapper = styled.div`
    border: 1px solid rgba(162, 162, 162, 1);
    border-radius: 15px;
    padding: 20px;
`;

export const  InstagramCrossWrapper = styled.div`

display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const InstaLogoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 17px;
    font-weight: 500;
`;

export const InstaTopicTitle = styled.div`
    margin-top: 10px;
    color: rgba(99, 99, 99, 1);
    font-size: 15px;
    font-weight: 400;
`;

export const FlexForLogoDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

export const InstagramImgDiv = styled.div`
width: ${(props) => props?.whithVal ? '60px' : '70px'};
height: ${(props) => props?.whithVal ? '60px' : '70px'};
border-radius: 50%;
// border:${(props) =>props.searchType == 'user' ? 'none' : '1px solid gray'};

`;
export const H3TitleForInsta = styled.div`
    margin: 0px;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
`;

export const PTitleforInsta = styled.div`
margin: 0px;
    margin-left: 20px;
    color: rgba(139, 139, 139, 1);
    font-size: 15px;
    font-weight: 400;
`;



export const Label = styled.span`
  color: #000000;
  margin-left: ${(props) => props?.showMargin == true ? '0px' : '8px'};

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;

  & > span {
    color: #fc6758;
  }
`;
export const LabelDiv = styled.div`
  color: #000000;
  margin-left: 8px;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;

  & > span {
    color: #fc6758;
  }
`;
export const SubLabel = styled.span`
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 10px;

  margin-left: ${(props) => props?.showMargin == true ? '0px' : '8px'};
  color: #636363;

  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.15px;
`;

export const SubLabelDiv = styled.div`
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 13px;
  margin-left: 8px;

  color: #636363;

  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.15px;
`;
export const ErrorMessage = styled.div`
  padding: 2px 10px;
  color: #d74748;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.1);

  & > div {
    position: fixed;
    top: 50%;
    left: 60%;
  }

  & svg {
    transform: scale(3);
  }
`;

export const ShowAlertWrapper = styled.div`
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  gap: 10px
`;

export const FlexAlertWrapper = styled.div` 
  display: flex;
  flex-direction: row;
  gap: 20px
`;

export const LabelStyle = styled.span`
color: rgb(0, 0, 0);
margin-left: 8px;
font-family: Montserrat;
font-size:16px;
font-weight: 500
`;

export const LabelParaStyle = styled.span`
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 8px;
  color: rgb(99, 99, 99);
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400
`;