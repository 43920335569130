import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Listen } from '../../../assests/images/LandingAssets/Listen.svg';
import { ReactComponent as Observe } from '../../../assests/images/LandingAssets/Observe.svg';
import { ReactComponent as Act } from '../../../assests/images/LandingAssets/Act.svg';
const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
const ColoredContainer = styled.div`
  flex-shrink: 0;
  background: linear-gradient(101deg, #5937c8 0%, #411c99 99.91%);
`;

const InnerContainer = styled.div`
  display: flex;
  padding: 100px 0px;
  flex-direction: column;
  align-items: center;
  gap: 75px;
  @media only screen and ${device.tablet} {
    padding: 60px 0px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  @media only screen and ${device.tablet} {
    padding: 0px 24px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  width: 674px;
  justify-content: space-between;
  align-items: center;
  @media only screen and ${device.tablet} {
    width: 80%;
    padding: 0px 24px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionText = styled.div`
  color: rgba(250, 250, 250, 0.8);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 0.2) 0%,
    rgba(125, 125, 125, 0.2) 100%
  );
  padding: 10px;
  align-items: center;
  @media only screen and ${device.tablet} {
    font-size: 12px;
  }
`;

const TopTitleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  @media only screen and ${device.tablet} {
    max-width: 342px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const TitleText = styled.div`
  color: #fafafa;
  text-align: center;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and ${device.tablet} {
    font-size: 40px;
  }
`;

const TitleTextHighlighted = styled.div`
  color: #fbbc05;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media only screen and ${device.tablet} {
    font-size: 40px;
  }
`;

const BottomTitle = styled.div`
  color: #fafafa;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and ${device.tablet} {
    font-size: 24px;
  }
`;

const IndividualImageContainer = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 2px solid #fafafa;
  box-shadow: 4px 4px 4px 0px rgba(0, 106, 104, 0.5);
  @media only screen and ${device.tablet} {
    width: 65px;
    height: 65px;
    padding: 10px;
  }
`;
const ImageText = styled.div`
  color: #fafafa;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  @media only screen and ${device.tablet} {
    font-size: 12px;
    margin-top: 10px;
  }
`;
const ListenObserveActBanner = () => {
  return (
    <ColoredContainer>
      <InnerContainer>
        <TextContainer>
          <DescriptionText>
            With us, you gain a bird’s eye view of the emotional landscape
            shaping consumer perceptions
          </DescriptionText>
          <TopTitleContainer>
            <TitleText>So,&nbsp;</TitleText>
            <TitleTextHighlighted>Listen</TitleTextHighlighted>
            <TitleText>,&nbsp;</TitleText>
            <TitleTextHighlighted>Observe</TitleTextHighlighted>
            <TitleText>,&nbsp;</TitleText>
            <TitleText>&&nbsp;</TitleText>
            <TitleTextHighlighted>Act</TitleTextHighlighted>
          </TopTitleContainer>
          <BottomTitle>to Get Ahead in Business</BottomTitle>
        </TextContainer>
        <BottomContainer>
          <ImageContainer>
            <IndividualImageContainer>
              <Listen height={'50px'} width={'50px'} />
            </IndividualImageContainer>
            <ImageText>Listen</ImageText>
          </ImageContainer>
          <ImageContainer>
            <IndividualImageContainer>
              <Observe height={'50px'} width={'50px'} />
            </IndividualImageContainer>
            <ImageText>Observe</ImageText>
          </ImageContainer>
          <ImageContainer>
            <IndividualImageContainer>
              <Act height={'50px'} width={'50px'} />
            </IndividualImageContainer>
            <ImageText>Act</ImageText>
          </ImageContainer>
        </BottomContainer>
      </InnerContainer>
    </ColoredContainer>
  );
};
export default ListenObserveActBanner;
