import React from 'react';
import styled from 'styled-components';
import EllipsisBg from '../../../assests/images/LandingAssets/EllipsisBg.svg';
import MobileEllipsisBG from '../../../assests/images/LandingAssets/MobileEllipsisBG.svg';
import 'typeface-inter';
import { useNavigate } from 'react-router-dom';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

const ImageBackground = styled.div`
  margin-top: 102px;
  margin-bottom: 102px;
  background-image: url(${EllipsisBg});
  height: 414px;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media only screen and ${device.tablet} {
    background-image: url(${MobileEllipsisBG});
    padding: 0px 24px;
  }
`;

const LightText = styled.div`
  color: #151515;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and ${device.tablet} {
    font-size: 20px;
    width: 288px;
  }
`;

const BoldText = styled.div`
  color: #151515;
  text-align: center;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media only screen and ${device.tablet} {
    font-size: 32px;
  }
`;

const DemoButton = styled.button`
  display: flex;
  width: 200px;
  padding: 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: linear-gradient(95deg, #5937c8 -1.61%, #391c99 98.16%);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  border: none;
  line-height: normal;
  margin-top: 25px;
  &:hover {
    color: #fbbc05;
    cursor: pointer;
  }
`;

const BookaDemoBanner = () => {
  let navigate = useNavigate();
  return (
    <ImageBackground animate={true}>
      <LightText>Are You Ready to BUILD Emotional Engagement?</LightText>
      <BoldText>Let’s make your BRAND BIGGER!</BoldText>
      <DemoButton
        onClick={() => {
          navigate('/book-a-demo');
        }}
      >
        Book a Demo
      </DemoButton>
    </ImageBackground>
  );
};
export default BookaDemoBanner;
