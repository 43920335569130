import React from 'react';
import styled from 'styled-components';
import { StarIcon } from '../SvgIcon/CommonSvgIcons';
import TitleWithInfo from '../TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
display:flex;
flex-direction: row;
font-family: Open Sans;
width: 95%;
/* height: 40%; */


//change this to your own
margin: 30px 0px 0px 0px; 
`;

const Label = styled.span`
  width: 50px;
  margin: 10px 0px 0px 25px;
  color: #636363;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const Bar = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 50px;
`;

const FilledBar = styled.div`
  /* flex: ${(props) => props.percentage}; */
  height: 15px;
  background-color: ${(props) => props.backgroundColor || '#6F62FE'};
  /* border-radius: ${(props) =>
    props.percentage === 100 ? '3px' : '3px 0 0 3px'}; */
  margin: 0px 0px 0px 0px;
`;

const AverageRatingGraph = ({backgroundColor,rating,title,infoText=''}) => {


  return (
   <Wrapper>
    <Label>

    <TitleWithInfo
              title={title}
              infoText={infoText}
            />
    </Label>
    {/* <Label>{title}</Label> */}
    <Bar>
          <FilledBar style={{ width: `${rating * 15}%`,backgroundColor}}></FilledBar>
          <div style={{margin:'5px 0px 0px 5px'}}>{`${rating }`}{StarIcon}</div>
          </Bar>
   </Wrapper>
  );
};

export default AverageRatingGraph;
