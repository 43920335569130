import React from 'react';
import styled from 'styled-components';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import CorrelationGraph from '../../../components/CorrelationGraph/CorrelationGraph';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import WordCloudAnalysis from '../../../components/InsightsWordCloud/WordCloudAnalysis';
import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import {
  emoji_anger,
  emoji_disgust,
  emoji_fear,
  emoji_neutral,
  emoji_sadness,
  emoji_surprise,
  emoji_joy,
} from '../../../Common/SvgIcon/CommonSvgIcons';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import MapChart from '../../../components/Maps/MapsIndia';
import MapTableView from '../../../components/Maps/MapsTableView';
import TitleWithInfo from '../../../Common/TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  margin: 40px 25px;
  width: 100%;
  height: 100%;
  font-family: Open Sans;

  & > div {
    margin-bottom: 40px;
  }
`;

export const PieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0px 0px 0px;
  width: 100%;
  min-height: 300px;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  p {
    /* width: 411px;
    margin: 100px 0px 0px 20px; */

    color: #636363;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`;

export const HeadingSpan = styled.span`
  display: flex;
  margin: 30px 0px 0px 30px;
  color: #636363;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const EmotionAnalysisWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;

  .titleContainer {
    margin: 25px 20px;
  }
  .correlationGraphContainer {
    padding: 1px 20px 20px 20px;
  }
 & > p:first-child {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    margin: 20px 0px 0px 10px;
  }
  
`;

const FilteredGraphContainer = styled.div`
flex: 1;
display: flex;
width: 100%;
margin: 20px 0px 20px 30px;
align-items: center;
`;

const Div = styled.div`
  margin: 20px 0px 0px 30px;
`;


const MapContainer = styled.div`
  /* width: 100%; */
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  & > p:first-child {
    color: #636363;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const Container1 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 10px;
  margin-bottom: 10px;
`;

function InsightsTab({ plan, selectedReport, view, hideDropdown, showTableView }) {
  let planSpecificData;
  if (selectedReport) {
    if (plan === 'BrandAstraPro') {
      planSpecificData = selectedReport?.data;
    } else if (plan === 'BrandEssence') {

      planSpecificData = selectedReport?.data;
      // planSpecificData = selectedReport?.BrandAstraEssence;
    } else if (plan === 'BrandAstraElite') {
      planSpecificData = selectedReport?.data;
      // planSpecificData = selectedReport?.BrandAstraElite;
    } else {

      planSpecificData = selectedReport?.data;
    }
  }

  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

  const COLOR_RANGE_1 = [
    '#C4C4CD',
    '#87D3F2',
    '#4EBEEB',
    '#35B8E8',
    '#188CE6',
    '#1777CF',
    '#155CB4',
  ];
  const COLOR_RANGE_2 = [
    '#C4C4CD',
    '#57E188',
    '#2DB757',
    '#36C768',
    '#189D3E',
    '#168736',
  ];
  const COLOR_RANGE_3 = [
    '#C4C4CD',
    '#F9FFA1',
    '#FFF060',
    '#F2DB00',
    '#E1B602',
    '#E7A300',
  ];
  const COLOR_RANGE_4 = [
    '#C4C4CD',
    '#FF9A91',
    '#F95D54',
    '#FF4237',
    '#F4362C',
    '#B9251C',
  ];


  const emotionData = [
    {
      name: 'Neutral',
      value: planSpecificData?.insights_tab?.emotion_analysis[0]?.neutral,
      // fill: '#F5CC61',
      
      fill:'rgba(251, 214, 121, 1)'
    },
    {
      name: 'Surprise',
      value: planSpecificData?.insights_tab?.emotion_analysis[0]?.surprise,
      // fill: '#5CE390',
      fill:'rgba(3, 198, 82, 1)'
    },
    {
      name: 'joy',
      value: planSpecificData?.insights_tab?.emotion_analysis[0]?.joy,
      // fill: '#8B2AEA',
      fill:'rgba(218, 6, 184, 1)'
    },
    {
      name: 'Fear',
      value: planSpecificData?.insights_tab?.emotion_analysis[0]?.fear,
      // fill: '#F74945',
      fill:'rgba(255, 153, 0, 1)'
    },
    {
      name: 'Sadness',
      value: planSpecificData?.insights_tab?.emotion_analysis[0]?.sadness,
      // fill: '#B7875B',
      fill:'rgba(21, 112, 203, 1)'
    },
    {
      name: 'Anger',
      value: planSpecificData?.insights_tab?.emotion_analysis[0]?.anger,
      // fill: '#FEEC00',
      fill:'rgba(238, 19, 7, 1)'
    },
    {
      name: 'Disgust',
      value: planSpecificData?.insights_tab?.emotion_analysis[0]?.disgust,
      // fill: '#353CF7',
      fill:'rgba(84, 30, 192, 1)'
    },
  ];

  const renderScreen = {
    'Bar Graph': view === 'vertical' ? (
      <VerticalBarGraph
        data={emotionData}
        maxWidth={'70%'}
        type={'%'}
        barHeight={'32px'}
        emojiList={{
          neutral: emoji_neutral,
          surprise: emoji_surprise,
          joy: emoji_joy,
          fear: emoji_fear,
          sadness: emoji_sadness,
          anger: emoji_anger,
          disgust: emoji_disgust,
          length: 2,
        }}
      />
    ) : (
      <BarGraph
        data={emotionData}
        maxWidth={'70%'}
        type={'%'}
        barHeight={'32px'}
        emojiList={{
          neutral: emoji_neutral,
          surprise: emoji_surprise,
          joy: emoji_joy,
          fear: emoji_fear,
          sadness: emoji_sadness,
          anger: emoji_anger,
          disgust: emoji_disgust,
          length: 2,
        }}
      />
    ),
    'Pie Chart': <RatingDonutChart data={emotionData} type='percentage' />,
    'Donut Chart': <DonutChart data={emotionData} type='percentage' />,
    'Funnel Chart': (
      <FunnelGraph data={emotionData} maxWidth={'80%'} type={'%'} />
    ),
  };

  const mapData = selectedReport?.data?.insights_tab?.map_data?.reviews_by_state;

  const positiveMapData = selectedReport?.data?.insights_tab?.map_data?.positive_reviews_by_state;
  const negativeMapData =
    selectedReport?.data?.insights_tab?.map_data?.negative_reviews_by_state || [];
  const neutralMapData =
    selectedReport?.data?.insights_tab?.map_data?.neutral_reviews_by_state || []
    ;
  let stateWithHighestReviews = "";
  let highestReviews = 0;
  let stateWitPositiveReview = '';
  let stateWithNegativeReview = '';
  let stateWithNeutralReview = '';

  if (mapData && typeof mapData === 'object' && Object.keys(mapData)?.length > 0) {

    [stateWithHighestReviews, highestReviews] = Object.entries(mapData).reduce((maxPair, currentPair) => {
      return currentPair[1] > maxPair[1] ? currentPair : maxPair;
    });
  } else {
    stateWithHighestReviews = null;
    highestReviews = null;
  }
  if (positiveMapData && typeof positiveMapData === 'object' && Object.keys(positiveMapData)?.length > 0) {

    [stateWitPositiveReview, highestReviews] = Object.entries(positiveMapData).reduce((maxPair, currentPair) => {
      return currentPair[1] > maxPair[1] ? currentPair : maxPair;
    });
  } else {
    stateWitPositiveReview = null;
  }


  //   const emotionAnalysisGraph = renderScreen[filterOption]
  let reviewhelpfullnessData = [];
  if (
    selectedReport?.channel === 'amazon'
  ) {
    reviewhelpfullnessData = planSpecificData?.insights_tab?.review_helpfullness || [];
  } else {
    reviewhelpfullnessData = planSpecificData?.insights_tab?.review_liked || [];
  }

  if (negativeMapData && typeof negativeMapData === 'object' && Object.keys(negativeMapData)?.length > 0) {
    // if (negativeMapData) {
    [stateWithNegativeReview] = Object?.entries(negativeMapData)?.reduce((maxPair, currentPair) => {
      return currentPair[1].count > maxPair[1].count ? currentPair : maxPair;
    });
  } else {
    stateWithNegativeReview = null;
  }

  if (neutralMapData && typeof neutralMapData === 'object' && Object.keys(neutralMapData)?.length > 0) {
    // if (neutralMapData) {
    [stateWithNeutralReview] = Object?.entries(neutralMapData)?.reduce((maxPair, currentPair) => {
      return currentPair[1].count > maxPair[1].count ? currentPair : maxPair;
    });
  } else {
    stateWithNeutralReview = null;
  }
  const ratingColors = ['#D5D5DC', '#C5C6F3', '#ACAFFC', '#898DFB', '#676DFF']

  const transformedData = reviewhelpfullnessData.map((item) => ({
    name: `${item?.rating} star`,

    fill: ratingColors[item.rating - 1],
    value: selectedReport?.channel === 'amazon'
      ? item.helpfull_per
      : item.likes_per,
  }));


  const renderReviewScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={transformedData}
          maxWidth={'70%'}
          type={'%'}
          barHeight={'32px'}
        />
      ) : (
        <BarGraph
          data={transformedData}
          maxWidth={'70%'}
          type={'%'}
          barHeight={'32px'}
        />
      ),
    'Pie Chart': <RatingDonutChart data={transformedData} type='percentage' />,
    'Donut Chart': <DonutChart data={transformedData} type='percentage' />,
    'Funnel Chart': (
      <FunnelGraph data={transformedData} maxWidth={'80%'} type={'%'} />
    ),
  };

  return (
    <div style={{ display: 'flex', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Wrapper>
        <WordCloudAnalysis
          showSwitchTab={false}
          insightsData={planSpecificData?.insights_tab}
          hideDropdown={hideDropdown}
          startDate={
            selectedReport?.start_date
          }
          endDate={
            selectedReport?.end_date
          }
        />
        {
          view === 'vertical' ? (
            <PieWrapper>
              <HeadingSpan>Review Helpfulness</HeadingSpan>
              <div>
                <VerticalBarGraph data={transformedData} type={'%'} maxWidth={'50%'} />
              </div>
            </PieWrapper>
          ) : (
            <PieWrapper>
              {/* <HeadingSpan>Review Helpfulness</HeadingSpan> */}
              {/* <BarGraph data={transformedData} type={'%'} maxWidth={'50%'} /> */}

              <GraphHOC
                title={selectedReport?.channel === 'flipkart' || selectedReport?.channel === 'flipkart' ? 'Review Liked' : 'Review Helpfulness'}
                options={options}
                renderScreen={renderReviewScreen}
                graphWidth={'100%'}
                margin={'20px 0px 20px 50px'}
                startDate={
                  selectedReport?.start_date
                }
                endDate={
                  selectedReport?.end_date
                }
                hideDropdown={hideDropdown}
              />

            </PieWrapper>
          )
        }
        {/* {filterOption ? (
            <EmotionAnalysisWrapper>
              <p>Emotion Analysis</p>
              <FilteredGraphContainer>{emotionAnalysisGraph}</FilteredGraphContainer>
            </EmotionAnalysisWrapper>
          ) : */}
        {/* (plan === 'BrandAstraPro' || plan === 'AstraLite') && */}
        {(
          <>

            <EmotionAnalysisWrapper>
              <GraphHOC
                title={'Emotion Analysis'}
                options={options}
                renderScreen={renderScreen}
                graphWidth={'100%'}
                margin={'20px 0px 20px 50px'}
                startDate={selectedReport?.start_date}
                endDate={selectedReport?.end_date}
                hideDropdown={hideDropdown}
              />
            </EmotionAnalysisWrapper>


            <div style={{ marginTop: '30px' }}>
              <CorrelationGraph
                RatingLabels={
                  selectedReport?.channel === 'amazon'
                    ? [
                      'Rating',
                      'Helpful',
                      'Anger',
                      'Neutral',
                      'Sadness',
                      'Disgust',
                      'Fear',
                      'Joy',
                      'Surprise',
                    ]
                    : [
                      'Rating',
                      'Likes',
                      'Anger',
                      'Neutral',
                      'Sadness',
                      'Disgust',
                      'Fear',
                      'Joy',
                      'Surprise',
                    ]
                }
                correlationAnalysisData={
                  planSpecificData?.insights_tab?.correlation_analysis
                }
                hideDropdown={hideDropdown}
                startDate={selectedReport?.start_date}
                endDate={selectedReport?.end_date}
              />
            </div>
            {/*  </Sentiment> */}
          </>
        )}
        {selectedReport?.channel === 'flipkart' ? (
          <MapContainer style={{
            border: showTableView == true && '1px solid rgb(217, 217, 217)',
            padding: showTableView == true && '20px 40px',
            borderRadius: showTableView == true && '15px'
          }}>
            <p><TitleWithInfo
              title={'Geographical View of Sentiments'}
              infoText={`A correlation matrix is a table showing correlation coefficients
                              between variables. Each cell in the table shows the correlation
                              between two variables`}
            /></p>

            <Container1 style={{
              marginTop: showTableView == true && '20px',
              justifyContent: showTableView == true && 'space-evenly'
            }}>
              {
                showTableView == true ?

                  <MapTableView

                    countType={'Engagers Count'}
                    data={
                      selectedReport?.data?.insights_tab?.map_data
                        ?.reviews_by_state

                    } />
                  :

                  <MapChart
                    mapData={
                      selectedReport?.data?.insights_tab?.map_data
                        ?.reviews_by_state
                    }
                    text={`The majority of the engagers were from ${stateWithHighestReviews}`}
                    colorRange={COLOR_RANGE_1}
                  />
              }

              {

                showTableView == true ?


                  <MapTableView

                    countType={'Positive reviews Count'}
                    data={
                      selectedReport?.data?.insights_tab?.map_data
                        ?.positive_reviews_by_state

                    } />

                  :

                  <MapChart
                    mapData={
                      selectedReport?.data?.insights_tab?.map_data
                        ?.positive_reviews_by_state
                    }
                    text={`The majority of Positive reviews were from ${stateWitPositiveReview}`}
                    colorRange={COLOR_RANGE_2}
                  />
              }
            </Container1>

            <Container1 style={{
              marginTop: showTableView == true && '20px',
              justifyContent: showTableView == true && 'space-evenly'
            }}>

              {

                showTableView == true ?

                  <MapTableView

                    countType={'Neutral reviews Count'}
                    data={
                      selectedReport?.data?.insights_tab?.map_data
                        ?.neutral_reviews_by_state

                    } />

                  :
                  <MapChart
                    mapData={
                      selectedReport?.data?.insights_tab?.map_data
                        ?.neutral_reviews_by_state
                    }
                    text={`The majority of Neutral reviews were from ${stateWithNeutralReview}`}
                    colorRange={COLOR_RANGE_3}
                  />
              }
              {

                showTableView == true ?

                  <MapTableView

                    countType={'Negative reviews Count'}
                    data={
                      selectedReport?.data?.insights_tab?.map_data
                        ?.negative_reviews_by_state

                    } />
                  :

                  <MapChart
                    mapData={
                      selectedReport?.data?.insights_tab?.map_data
                        ?.negative_reviews_by_state
                    }
                    text={`The majority of Negative reviews were from ${stateWithNegativeReview}`}
                    colorRange={COLOR_RANGE_4}
                  />
              }
            </Container1>
          </MapContainer>
        ) : null}

      </Wrapper>
    </div>
  );
}

export default InsightsTab;
