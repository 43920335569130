import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import Pagination from '../Pagination/Pagination';
import { QuestionIcon, sort_icon } from '../../Common/SvgIcon/CommonSvgIcons';
import { useEffect } from 'react';
import CollapsibleText from '../ReviewText/collapsibleText';

const Wrapper = styled.div`
  // margin: 50px 25px;
  height: 100%;
  /* width: 100%; */
  .header {
    color: #636363;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;

    & > div:nth-child(2),
    & > div:nth-child(3) {
      color: #636363;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

const RowData = styled.div`
  display: grid;
  grid-template-columns: 45% 1fr 1fr 1fr;
  gap: 5px;
  text-align: left;

  color: #2a2929;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;

  & .review {
    padding-left: 15px;

    & > div:nth-child(1) {
      color: #2a2929;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  & > div:nth-child(2),
  & > div:nth-child(3) {
    color: #000;
    font-size: 15px;
    font-weight: 600;
  }

  & > div:not(:first-child) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .filterColumn {
    cursor: pointer;
  }

  & .profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: Open Sans;
    font-size: 15px;
    font-weight: 600;

    & :last-child {
      color: #636363;
    }
  }
`;

const RowWrapper = styled.div`
  & > div {
    width: 100%;
    min-height: 120px;
    box-sizing: border-box;
    margin-top: 15px;
    border-radius: 15px;
    border: 1px solid #d9d9d9;
    padding: 20px 0;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;

  margin-bottom: 30px;

  font-size: 15px;
  font-weight: 600;

  & > :first-child {
    box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
    padding: 8px 7px 5px 7px;
    border-radius: 10px;

    & svg {
      width: 30px;
      height: 30px;
    }
  }

  & input {
    box-shadow: 0px 0px 8px 0px #a2a2a23d;
    border: 1px solid #d9d9d9;
    margin-top: 5px;
    margin-left: -3px;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    color: #636363;

    padding: 5px 20px;
    border-radius: 5px;
  }
`;
const InfoWrapper = styled.div`
  /* display: none; */
  color: #636363;
  position: absolute;

  top: -12px;
  margin-left: 260px;
  min-width: 205px;
  max-width: 300px;
  min-height: 30px;
  background: #d0ccff;
  padding: 10px;
  border-radius: 8px;
  z-index: 100;

  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;

  box-shadow: 0px 4px 4px 0px #00000040;

  &:before {
    content: '';
    position: absolute;

    left: -14px;
    top: 12px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid #d0ccff;
    z-index: 11;
  }
`;
const IconWrapper = styled.div`
  position: relative;
  margintop: 2px;
  color: #636363;
  border-radius: 50%;
  border: 2.5px solid #636363;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;

  cursor: pointer;
`;
const CommonMessagesTab = ({
  isNews = false,
  download,
  reviews = [],
  filterCol = [2, 3, 4],
  columns = [],
  PageSize = 8,
  // startDate,
  // endDate,
  graphStyles = {
    gridTemplateColumns: '45% 1fr 1fr 1fr',
  },
  channel,
  infoVal = '',
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsData, setReviewsData] = useState([]);
  const [infoText, setInfotext] = useState(false);

  // const [dateRange, setdateRange] = useState({
  //   startDate: startDate,
  //   endDate: endDate,
  // });
  useEffect(() => {
    setReviewsData([...reviews]);
  }, [reviews]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    if (download) {
      return reviewsData;
    } else {
      return reviewsData.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, reviewsData]);

  const formatDate = (dateString) => {
    try {
      const parts = dateString.split('/');

      const formattedDate = `${parts[2]}-${parts[1].padStart(
        2,
        '0'
      )}-${parts[0].padStart(2, '0')}`;

      const date = new Date(formattedDate);
      const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      };
      return date.toLocaleDateString('en-US', options);
    } catch (error) {
      return '';
    }
  };

  function formatNumber(val) {
    if (val >= 1000000) {
      return (val / 1000000).toFixed(1) + 'M'; // Convert to million
    } else if (val >= 1000) {
      return (val / 1000).toFixed(1) + 'k';
    } else if (val > 0) {
      return val.toString();
    } else {
      return '';
    }
  }

  const handleFilter = (index) => {
    const tempReviews = [...reviewsData];
    /* tempReviews.sort(
      (a, b) =>
        b[columns[index].toLowerCase()] - a[columns[index].toLowerCase()]
    ); */
    if (columns[index] === 'Profile') {
      tempReviews.sort((a, b) => {
        const profileA = a.user_name || a.Name || '';
        const profileB = b.user_name || b.Name || '';
        return profileA.localeCompare(profileB);
      });
    } else {
      tempReviews.sort(
        (a, b) =>
          b[columns[index].toLowerCase()] - a[columns[index].toLowerCase()]
      );
    }
    setReviewsData([...tempReviews]);
  };
  const getDetails = (channel, message) => {
    switch (channel) {
      case 'twitter':
        return formatNumber(message?.engagement) || 0;
      case 'facebook':
        return formatNumber(message?.comments) || 0;
      case 'instagram':
        return formatNumber(message?.comments) || 0;
      case 'flipkart':
        return formatNumber(message?.dislikes) || 0;
      case 'amazon':
        return (
          message.verified === 'Verified Purchase' && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='27'
              height='27'
              viewBox='0 0 27 27'
              fill='#209E0B'
            >
              <path
                d='M0.920898 13.2318C4.6835 17.3029 8.33166 20.9679 11.8385 25.6033C15.6512 18.0201 19.5535 10.4105 25.9924 2.16971L24.2574 1.375C18.8205 7.14098 14.5965 12.599 10.9262 19.0853C8.37385 16.7861 4.24897 13.5324 1.73037 11.8607L0.920898 13.2318Z'
                fill='#209E0B'
              />
            </svg>
          )
        );
      default:
        return 0;
    }
  };
  function formatNumberVal(num) {
    if (num >= 1_000_000_000) {
      // Billion
      return (num / 1_000_000_000).toFixed(1) + 'B';
    } else if (num >= 1_000_000) {
      // Million
      return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      // Thousand
      return (num / 1000).toFixed(1) + 'k';
    } else {
      return num; // Return the number as is if it's less than 1000
    }
  }
  return (
    <Wrapper>
      <RowData className='header' style={{ ...graphStyles }}>
        {columns.map((column, i) => {
          if (filterCol.includes(i + 1)) {
            return (
              <div
                className='filterColumn'
                key={i}
                onClick={() => handleFilter(i)}
              >
                {column}&nbsp;
                {sort_icon}
              </div>
            );
          }
          if (column === 'Posts') {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  key={i}
                  style={{
                    paddingLeft: '15px',
                    paddingRight: '10px',
                    textAlign: isNews == true ? 'center' : 'justify',
                  }}
                >
                  {column}
                </div>
                <IconWrapper
                  onMouseOver={() => setInfotext(true)}
                  onMouseLeave={() => setInfotext(false)}
                >
                  i{infoText && <InfoWrapper>{infoVal}</InfoWrapper>}
                </IconWrapper>
              </div>
            );
          }
          return (
            <div
              key={i}
              style={{
                paddingLeft: '15px',
                textAlign: isNews == true ? 'center' : 'justify',
              }}
            >
              {column}
            </div>
          );

          // return <div key={i} style={{ paddingLeft: '15px' }}>{column}</div>;
        })}
      </RowData>
      <RowWrapper>
        {currentTableData.map((message, index) =>
          isNews == true ? (
            <RowData key={index} style={{ ...graphStyles }}>
              <div className='profile'>
                <span>{message?.NewsSource} The Times of India</span>
              </div>
              <div className='review'>
                <div>
                  <CollapsibleText
                    text={message?.text}
                    link={message?.post_url}
                  />
                </div>
                {/* <CollapsibleText text={message.Posts} /> */}
              </div>

              <div className='helpful'>{message?.date}</div>
            </RowData>
          ) : (
            //     (
            //       <RowData key={index} style={{ ...graphStyles }}>
            //         <div className='review'>
            //           <div>
            //             Post on{' '}
            //             {formatDate(
            //               channel === 'twitter'
            //                 ? message.date
            //                 : message.date || message['Reviewed on']
            //             )}
            //           </div>
            //           <div>
            //             <CollapsibleText
            //               text={
            //                 channel === 'twitter'
            //                   ? message.text
            //                   : message.posts || message.review_meta || message?.text
            //               }
            //               link={
            //                 channel === 'facebook' || channel === 'instagram'
            //                   ? message?.post_url
            //                   : channel === 'twitter'
            //                   ? message?.tweet_url
            //                   : ''
            //               }
            //             />
            //           </div>
            //           <div>
            //           <CollapsibleText
            //             text={message.review_text && message.review_text}
            //           />
            //         </div>
            //         {/* <CollapsibleText text={message.Posts} /> */}
            //       </div>

            //       <div className='helpful'>
            //         {message?.date}
            //       </div>

            //     </RowData>
            // )
            // :
            <RowData key={index} style={{ ...graphStyles }}>
              <div className='review'>
                <div>
                  Post on{' '}
                  {formatDate(
                    channel === 'twitter'
                      ? message.date
                      : message.date || message['Reviewed on']
                  )}
                </div>
                <div>
                  <CollapsibleText
                    text={
                      channel === 'twitter'
                        ? message.text
                        : message.posts || message.review_meta || message?.text
                    }
                    link={
                      channel === 'facebook' || channel === 'instagram'
                        ? message?.post_url
                        : channel === 'twitter'
                        ? message?.tweet_url
                        : ''
                    }
                  />
                </div>
                <CollapsibleText
                  text={message.review_text && message.review_text}
                />
              </div>
              {columns.includes('Profile') && (
                <div className='profile'>
                  <span>{message.user_name || message.name}</span>
                  <span>{message['@user_name']}</span>
                  {channel === 'instagram' && (
                    <span
                      style={{ fontSize: '12px', color: 'rgb(99, 99, 99)' }}
                    >
                      {message['handle']}
                    </span>
                  )}
                  {channel === 'instagram' && (
                    <span style={{ fontSize: '12px' }}>
                      {formatNumberVal(message['followers_count'] || 0)}
                    </span>
                  )}
                </div>
              )}
              <div className='helpful'>
                {channel === 'twitter'
                  ? formatNumber(message?.likes) || 0
                  : // formatNumber(message?.followers)
                  channel === 'amazon'
                  ? formatNumber(message.helpful) || 0
                  : formatNumber(message.likes) || 0}
              </div>
              <div className='verified'>
                {channel === 'twitter'
                  ? formatNumber(message?.replies) || 0
                  : getDetails(channel, message)}
              </div>
              {channel === 'twitter' ? (
                <div>
                  {(channel === 'twitter' && formatNumber(message?.views)) || 0}
                </div>
              ) 
              // : channel === 'facebook' ? (
              //   <div>{message?.shares || 0}</div>
              // )
               : channel === 'instagram' && (
                <div>{message?.views || 0}</div>
              )}
            </RowData>
          )
        )}
      </RowWrapper>
      {!download && (
        <Pagination
          currentPage={currentPage}
          totalCount={reviewsData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
          reviewsData={reviewsData}
        />
      )}
    </Wrapper>
  );
};

export default CommonMessagesTab;
