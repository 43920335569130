import React, { useEffect, useRef, useState } from 'react';
import {
  Wrapper,
  HeaderWrapper,
  Span1,
  Span2,
  ChannelsText,
  TwitterText,
  DateWrapper,
  StyledContainer,
  Container,
  Container2,
  ChildContainer,
  MainComponent,
  SubMainComponent,
  SubComponent1,
  SubComponent2,
  TopicBDiv,
  TopicADiv,
  ButtonDiv,
  ButtonStyle,
  EmotionContainer,
  TabHeading,
  TabWrapper,
  ChildContainerForCustom,
  CancelButtonStyle
} from './ViewCompetitive.styled.js'
import { AmazonIcon, AmazonIconSmall, FacebookIcon, FlipkartIcon, FlipkartIconSmall, InstagramLogo, StarIcon, X_LOGO, X_icon, download_icon, share_icon } from '../../Common/SvgIcon/CommonSvgIcons.js';
import Button from '../../Common/Button/Button.jsx';
import CustomAnalysisTab from '../ActiveTopic/CustomAnalysisTab/CustomAnalysisTab.js';
import PercentageBarComp from '../../components/Competitive/PercentageBar.js';
import TrendsMainContainer from './TrendsMainContainer.jsx';
import TopicMentions from './TopicMentions'
import { competitiveIntelligenceReport, competitiveIntelligenceShareReport } from '../../store/redux/slices/competitiveIntelligenceSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import Percentage3BarComp from '../../components/Competitive/Percentage3Bar.js';
import { LoadingWrapper } from '../TopicBuilder/MainContent/QueryBuilder/TopicBuilder.styled.js';
import { CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { formatDateFilter } from '../../utils/index.jsx';
import { getPersonalDetails } from '../../store/redux/slices/tenantSettingSlice.js';
import MyDatePicker from '../../components/DatePicker/DatePicker.jsx';
import { shareReport, shareReportCI } from '../../store/redux/slices/sentinelHubSlice.js';

const ButtonStyles = {
  background: 'white',
  color: 'rgb(53, 60, 247)',
  border: '1px solid rgb(53, 60, 247)',
  fontSize: '14px',
  fontWeight: '400',
  borderRadius: '0px'
}
const Button2Styles = {

  border: '1px solid rgb(53, 60, 247)',
  fontSize: '14px',
  fontWeight: '400',
  borderRadius: '0px'
}


const ViewCompetitive = ({ loading = false, shareReport = false, ci_id, showReportPopup = false, handleCancelClick = () => { console.log("error in report") }, handleGeneratePdf, downloadFeature = false }) => {

  let topicPreviewData = {
    topic_title: 'Competitive Intelligence',
    channel: 'Twitter',
    topicA: {
      title: 'Google Pixel 8',
      startDate: '',
      endDate: ''
    },
    topicB: {
      title: 'Iphone 15',
      startDate: '',
      endDate: ''
    },

  };
  const [isActive, setIsActive] = useState(true);
  const [activeTab, setActiveTab] = useState('overViewTab');
  const [isTopic, setIsTopic] = useState(false)
  const [compData, setCompData] = useState([])
  const [analysisData, setAnalysisData] = useState()
  const [loader, setLoader] = useState(false)

  const { personalDetailsData } = useSelector((state) => state.tenantSetting) || {};
  const dispatch = useDispatch();
  const id = window.location.pathname?.split('/')?.[3]?.split('_')?.[1];


  const transformText = (inputText) => {
    // if (inputText == 'twitter') {
    //   return ' ';
    // }
    const words = inputText?.split(' ');
    const transformedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));
    return transformedWords?.join(' ')
  };

  // const { ciPreviewData } = useSelector((state) => state.ciBuilder) || {};
  const { ciPreviewData } = useSelector((state) => state.competitiveIntelligence);

  // const ciPreviewData2 = useSelector((state) => state.ciBuilder.ciPreviewData) || {}
  useEffect(() => {
    setLoader(true)
    if (shareReport) {
      dispatch(competitiveIntelligenceShareReport(ci_id))
        .then((res) => {
          setTimeout(() => {

            setLoader(false)
            setAnalysisData(res?.payload?.data?.report?.ci_analysis || '')
            setCompData(res?.payload?.data?.report?.ci_report || [])
          }, 1000);
        })
        .catch((err) => {

          setLoader(false)
          setAnalysisData('')
          setCompData([])
        })
    } else if (id) {
      dispatch(competitiveIntelligenceReport(id))
        .then((res) => {

          setTimeout(() => {

            setLoader(false)
            setAnalysisData(res?.payload?.data?.ci_analysis || '')
            setCompData(res?.payload?.data?.ci_report || [])
          }, 1000);
        })
        .catch((err) => {

          setLoader(false)
          setAnalysisData('')
          setCompData([])
        })
    } else {

      dispatch(competitiveIntelligenceReport(ci_id))
        .then((res) => {

          setTimeout(() => {

            setLoader(false)
            setAnalysisData(res?.payload?.data?.ci_analysis || '')
            setCompData(res?.payload?.data?.ci_report || [])
          }, 1000);
        })
        .catch((err) => {

          setLoader(false)
          setAnalysisData('')
          setCompData([])
        })

    }

    if (personalDetailsData === null) {
      dispatch(getPersonalDetails());
    }

  }, [id, dispatch]);

  // console.log(id, "id");

  const graphData = useMemo(() => {
    return [{
      "label": compData?.[0]?.chatter_topic_title,
      "channel": compData?.[0]?.channel,
      "male": compData?.[0]?.ci_report?.gender_demographics?.male_percentage,
      "female": compData?.[0]?.ci_report?.gender_demographics?.female_percentage,
      "unknown": compData?.[0]?.ci_report?.gender_demographics?.unknown_percentage

    },
    {
      "label": compData?.[1]?.chatter_topic_title,
      "channel": compData?.[1]?.channel,
      "male": compData?.[1]?.ci_report?.gender_demographics?.male_percentage,
      "female": compData?.[1]?.ci_report?.gender_demographics?.female_percentage,
      "unknown": compData?.[1]?.ci_report?.gender_demographics?.unknown_percentage

    }];
  }, [compData]);

  const graphDataSentiments = useMemo(() => {
    return [{
      "label": compData?.[0]?.chatter_topic_title || '',
      "channel": compData?.[0]?.channel || '',
      "positive": compData?.[0]?.ci_report?.sentiment_metrics?.positive_sentiment || 0,
      "negitive": compData?.[0]?.ci_report?.sentiment_metrics?.negative_sentiment || 0,
      "neutral": compData?.[0]?.ci_report?.sentiment_metrics?.neutral_sentiment || 0

    },
    {
      "label": compData?.[1]?.chatter_topic_title || '',
      "channel": compData?.[1]?.channel || '',
      "positive": compData?.[1]?.ci_report?.sentiment_metrics?.positive_sentiment || 0,
      "negitive": compData?.[1]?.ci_report?.sentiment_metrics?.negative_sentiment || 0,
      "neutral": compData?.[1]?.ci_report?.sentiment_metrics?.neutral_sentiment || 0
    }];
  }, [compData]);

  const emotionsData = [
    {
      "label": 'Joy',
      "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.joy || 0,
      "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.joy || 0,

    },
    {
      "label": 'Neutral',
      "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.neutral || 0,
      "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.neutral || 0,
    },

    {
      "label": 'Surprise',
      "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.surprise || 0,
      "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.surprise || 0,
    },
    {
      "label": 'Fear',
      "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.fear || 0,
      "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.fear || 0,
    },
    {
      "label": 'Sadness',
      "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.sadness || 0,
      "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.sadness || 0,
    },

    {
      "label": 'Anger',
      "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.anger || 0,
      "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.anger || 0,
    },

    {
      "label": 'Disgust',
      "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.disgust || 0,
      "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.disgust || 0,
    }

  ];

  const shareComp = () => {
    if (id) {
      dispatch(shareReportCI({
        "ci_id": id,
        "newReportData": {
          ci_analysis: analysisData,
          ci_report: compData
        },
        "render_field_string": {
          trends:'Days',
          sentiments:'Months'
        },
        "title_name": `${compData?.[0]?.chatter_topic_title} vs ${compData?.[1]?.chatter_topic_title}`,

        "subtitle": `${transformText(compData?.[0]?.channel)} vs ${transformText(compData?.[1]?.channel)}`,

      }))
        .then((res) => {
          // onClose();
        });

    }

  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const trendsDataSet = [
    {
      fill: false,
      data: 'topic1_values',
      borderColor: 'rgba(54, 162, 235, 1)', // Line color for topic 1
      pointBackgroundColor: 'green', // Marker color for topic 1
      pointBorderWidth: 0,
      pointRadius: 5, // Size of the markers
    },
    {
      fill: false,
      data: 'topic2_values',
      borderColor: 'rgba(255, 99, 132, 1)', // Line color for topic 1
      pointBackgroundColor: 'red', // Marker color for topic 2

      pointBorderWidth: 0,
      pointRadius: 5, // Size of the markers
      xAxisID: 'topic2_dates', // Use a secondary x-axis for energy
    }
  ];


  const sentimentTrendsDataSet = [
    {
      fill: false,
      data: 'topic1_values',
      borderColor: 'rgba(54, 162, 235, 1)', // Line color for topic 1
      pointBackgroundColor: 'green', // Marker color for topic 1
      pointBorderWidth: 0,
      pointRadius: 5, // Size of the markers
    },
    {
      fill: false,
      data: 'topic2_values',
      borderColor: 'rgba(255, 99, 132, 1)', // Line color for topic 1
      pointBackgroundColor: 'red', // Marker color for topic 2

      borderDash: [10, 5], // Dotted line (10px line, 5px gap)
      pointBorderWidth: 0,
      pointRadius: 5, // Size of the markers
      xAxisID: 'topic2_dates', // Use a secondary x-axis for energy
    }
  ];

  const avgRatingData = [{
    label: 'Average rating',
    topicA: compData?.[0]?.ci_report?.average_rating || 0,
    topicB: compData?.[1]?.ci_report?.average_rating || 0
  }]


  const avgEngagementData = [{
    label: 'Likes',
    topicA: compData?.[0]?.ci_report?.engagement_metrics?.likes || 0,
    topicB: compData?.[1]?.ci_report?.engagement_metrics?.likes || 0
  },
  {
    label: 'Comments',
    topicA: compData?.[0]?.ci_report?.engagement_metrics?.comments || 0,
    topicB: compData?.[1]?.ci_report?.engagement_metrics?.comments || 0
  }
  ]


  const emotionsDataAllValues = emotionsData.flatMap(item => [item.topicA, item.topicB]);

  const emotionsValue = Math.max(...emotionsDataAllValues);
  const emotionMultiplier = 100 / emotionsValue;


  const avgEngageVAlues = avgEngagementData.flatMap(item => [item.topicA, item.topicB]);

  const avgEngageValue = Math.max(...avgEngageVAlues);
  const avgEnagageMultiplier = 100 / avgEngageValue;


  const avgRatingAllVAlues = avgRatingData.flatMap(item => [item.topicA, item.topicB]);

  const avgRatingValue = Math.max(...avgRatingAllVAlues);
  const avgRatingMultiplier = 100 / avgRatingValue;


  const allValues = graphData.flatMap(item => [item.male, item.female, item.unknown]);

  // Find the maximum value among all values
  const maxValue = Math.max(...allValues);
  const multiplier = 100 / maxValue;


  const allValueSentiments = graphDataSentiments.flatMap(item => [item.positive, item.negitive, item.neutral]);

  // Find the maximum value among all values
  const maxValueSentiments = Math.max(...allValueSentiments);
  const multiplierSentiments = 100 / maxValueSentiments;
  // useEffect(() => {
  //   const resizeObserver = new ResizeObserver((entries) => {
  //     const parentWidth = parentRef?.current?.offsetWidth ;
  //     setNumericWidth(parentWidth);
  //   });

  //   resizeObserver.observe(parentRef?.current);

  //   return () => resizeObserver.disconnect();
  // }, []);


  const renderTabContent = () => {
    switch (activeTab) {
      case 'overViewTab':
        return (
          <div style={{ marginBottom: '50px' }}>
            <Container color={downloadFeature}>
              <ChildContainer>
                <TopicMentions
                  data={compData}
                  iconA={
                    compData?.[0]?.channel === "twitter" ? X_icon :
                      compData?.[0]?.channel === "instagram" ? InstagramLogo :
                        compData?.[0]?.channel === "facebook" ? FacebookIcon :
                          compData?.[0]?.channel === "amazon" ? AmazonIconSmall :
                            compData?.[0]?.channel === "flipkart" ? FlipkartIconSmall :
                              ''
                  }
                  iconB={
                    compData?.[1]?.channel === "twitter" ? X_icon :
                      compData?.[1]?.channel === "instagram" ? InstagramLogo :
                        compData?.[1]?.channel === "facebook" ? FacebookIcon :
                          compData?.[1]?.channel === "amazon" ? AmazonIconSmall :
                            compData?.[1]?.channel === "flipkart" ? FlipkartIconSmall :
                              ''
                  }
                />
              </ChildContainer>

              <Percentage3BarComp
                analysisData={analysisData}
                compData={compData}
                title={'Gender Demographics'}
                data={graphData}
                multiplier={multiplier}
                summary1={analysisData?.gender_demographics?.data_1 || ''}
                summary2={analysisData?.gender_demographics?.data_2 || ''}
              />


            </Container>

            <div style={{
              padding: '30px 3px 0px 30px',
              width: '30%',

              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
              <Button
                value={'User Activity'}
                style={!isActive ? ButtonStyles : Button2Styles}
                onClick={() => setIsActive(true)}
              />
              <Button
                value={'Sentiment Trends'}
                style={isActive ? ButtonStyles : Button2Styles}
                onClick={() => setIsActive(false)}
              />
            </div>
            {
              isActive ?

                <Container2>
                  <TrendsMainContainer
                    downloadFeature={downloadFeature}
                    compData={compData}
                    showGraphLabel={false}
                    trendsDataSet={trendsDataSet}
                    analysisData={analysisData}
                    summary1={analysisData?.trends?.data_1}
                    summary2={analysisData?.trends?.data_2}
                    showsummary={true}
                  />
                </Container2>
                :

                <Container2>

                  <TrendsMainContainer
                    downloadFeature={downloadFeature}
                    compData={compData}
                    showGraphLabel={true}
                    trendsDataSet={sentimentTrendsDataSet}
                    analysisData={analysisData}
                    summary1={analysisData?.sentiment_trends}
                    summary2={''}
                    showsummary={false}
                    showFilter={!shareReport}
                  />

                </Container2>
            }
            {
              isActive &&

              <Container>
                {
                  compData?.[0]?.channel === 'amazon' || compData?.[0]?.channel === 'flipkart' ?
                    <PercentageBarComp
                      analysisData={analysisData}
                      title={'Average Rating'}
                      data={avgRatingData}
                      multiplier={avgRatingMultiplier}
                      compData={compData}
                      summary1={analysisData?.rating_engagement_metrics?.average_rating || ''}
                    // summary2={analysisData?.gender_demographics?.data_2 || ''}
                    />

                    :
                    <PercentageBarComp
                      analysisData={analysisData}
                      title={'Engagement Metrics'}
                      data={avgEngagementData}
                      multiplier={avgEnagageMultiplier}
                      compData={compData}

                      summary1={analysisData?.rating_engagement_metrics?.likes || ''}
                      summary2={analysisData?.rating_engagement_metrics?.comments || ''}
                    />

                }

                <Percentage3BarComp
                  analysisData={analysisData}
                  compData={compData}
                  title={'Sentiments'}
                  data={graphDataSentiments}
                  showSentiment={true}
                  multiplier={multiplierSentiments}

                  summary1={''}
                  summary2={''}
                />


              </Container>


            }

            {

              isActive &&
              <EmotionContainer>

                <PercentageBarComp
                  title={'Emotions'}
                  data={emotionsData}
                  multiplier={emotionMultiplier}
                  compData={compData}

                  summary1={analysisData?.emotion?.data_1 || ''}
                  summary2={analysisData?.emotion?.data_2 || ''}
                />


              </EmotionContainer>

            }


          </div>
        );

      case 'CustomQuery':
        return (
          <div>
            <Container>

              <ChildContainerForCustom>
                <CustomAnalysisTab
                // job_id={job_id}
                // preview_uuid={topicPreviewData?.preview_uuid}
                // onUpdateSearchQuery={updateSearchQuery}
                // channel={topicPreviewData?.channel}
                />
              </ChildContainerForCustom>
            </Container>


          </div>);
    };

  };


  const formatValue = personalDetailsData?.app_preference?.date_format === "dd/mm/yyyy" ? 'dd-MM-yyyy' :
    personalDetailsData?.app_preference?.date_format === "mm/dd/yyyy" ? 'MM-dd-yyyy' :
      personalDetailsData?.app_preference?.date_format === "yyyy/mm/dd" ? 'yyyy-MM-dd' :
        'dd-MM-yyyy';

  return (
    <Wrapper color={downloadFeature}>
      {
        loader &&
        <LoadingWrapper>
          <div>
            <CircularProgress />
          </div>
        </LoadingWrapper>
      }
      <HeaderWrapper>
        {/* <Span1>{transformText(topicPreviewData?.topic_title)}</Span1> */}

        <MainComponent>
          <SubMainComponent >
            <SubComponent1>
              <StyledContainer>
                <ChannelsText>Channel:</ChannelsText>
                <TwitterText>
                  {/* <Icon>{topicPreviewData?.channel === 'twitter' ? X_icon : InstagramIconSmall}</Icon> */}
                  {compData?.[0]?.channel && compData?.[0]?.channel == 'twitter' ? X_LOGO : transformText(compData?.[0]?.channel || '')}
                </TwitterText>
              </StyledContainer>
              <TopicADiv >
                <Span1>Topic A : </Span1>
                <Span2>&nbsp; {compData?.[0]?.chatter_topic_title || ''}</Span2>

              </TopicADiv>
              <DateWrapper>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}>
                  <div>Start Date:</div>
                  <MyDatePicker
                    name='firstPostDate'
                    value={formatDateFilter(compData?.[0]?.start_date) || ''}
                    // dateHandler={dateHandler}
                    format={
                      formatValue
                    }
                    min={formatDateFilter(compData?.[0]?.start_date) || ''}
                    max={formatDateFilter(compData?.[0]?.start_date) || ''}
                  />
                  {/* <input
                    type='date'
                    name='firstPostDate'
                    value={formatDateFilter(compData?.[0]?.start_date) || ''}
                  // onChange={(e) => dateHandler(e)}
                  // min={initialStartDate}
                  // max={initialEndDate}
                  /> */}
                </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}>
                  <div>End Date:</div>
                  <MyDatePicker
                    name='firstPostDate'
                    value={formatDateFilter(compData?.[0]?.end_date) || ''}
                    // dateHandler={dateHandler}
                    format={
                      formatValue
                    }
                    min={formatDateFilter(compData?.[0]?.end_date) || ''}
                    max={formatDateFilter(compData?.[0]?.end_date) || ''}
                  />
                  {/* <input
                    type='date'
                    name='lastPostDate'
                    value={formatDateFilter(compData?.[0]?.end_date) || ''}
                  // value={endDate || initialEndDate}
                  // onChange={(e) => dateHandler(e)}
                  // min={startDate}
                  // max={initialEndDate}
                  /> */}
                </div>
              </DateWrapper>
            </SubComponent1>
            <SubComponent2 >
              <StyledContainer>
                <ChannelsText>Channel:</ChannelsText>
                <TwitterText>
                  {/* <Icon>{topicPreviewData?.channel === 'twitter' ? X_icon : InstagramIconSmall}</Icon> */}
                  {compData?.[1]?.channel && compData?.[1]?.channel == 'twitter' ? X_LOGO : transformText(compData?.[1]?.channel || '')}
                </TwitterText>
              </StyledContainer>
              <TopicBDiv>
                <Span1>Topic B : </Span1>
                <Span2>&nbsp; {compData?.[1]?.chatter_topic_title || ''}</Span2>

              </TopicBDiv>
              <DateWrapper>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}>
                  <div>Start Date:</div>
                  <MyDatePicker
                    name='firstPostDate'
                    value={formatDateFilter(compData?.[1]?.start_date) || ''}
                    // dateHandler={dateHandler}
                    format={
                      formatValue
                    }
                    min={formatDateFilter(compData?.[1]?.start_date) || ''}
                    max={formatDateFilter(compData?.[1]?.start_date) || ''}
                  />
                  {/* <input
                    type='date'
                    name='firstPostDate'
                    value={formatDateFilter(compData?.[1]?.start_date) || ''}
                  // value={startDate || initialStartDate}
                  // onChange={(e) => dateHandler(e)}
                  // min={initialStartDate}
                  // max={initialEndDate}
                  /> */}
                </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}>
                  <div>End Date:</div>
                  <MyDatePicker
                    name='firstPostDate'
                    value={formatDateFilter(compData?.[1]?.end_date) || ''}
                    // dateHandler={dateHandler}
                    format={
                      formatValue
                    }
                    min={formatDateFilter(compData?.[1]?.end_date) || ''}
                    max={formatDateFilter(compData?.[1]?.end_date) || ''}
                  />
                  {/* <input
                    type='date'
                    name='lastPostDate'
                    value={formatDateFilter(compData?.[1]?.end_date) || ''}
                  // value={endDate || initialEndDate}
                  // onChange={(e) => dateHandler(e)}
                  // min={startDate}
                  // max={initialEndDate}
                  /> */}
                </div>
              </DateWrapper>
            </SubComponent2>
            {/* <br /> lang: en since: 2023-01-01 until: 2023-05-18 geocode: 20.5937,78.9629,2000 km */}
          </SubMainComponent>
        </MainComponent>


      </HeaderWrapper>
      <Wrapper >
        <TabWrapper>
          <TabHeading>
            <div
              className={activeTab === 'overViewTab' ? 'activePlanTab' : ''}
              onClick={() => handleTabClick('overViewTab')}
            >
              Overviews
            </div>
            {/* <div
              className={activeTab === 'CustomQuery' ? 'activePlanTab' : ''}
              onClick={() => handleTabClick('CustomQuery')}
            >
              Custom Queries
            </div> */}

          </TabHeading>
          {
            showReportPopup ?

              <ButtonDiv style={{ paddingRight: '0px' }}>

                <CancelButtonStyle style={{ marginRight: '15px' }} onClick={handleCancelClick}>
                  Cancel
                </CancelButtonStyle>
                {
                  loading ?
                    <ButtonStyle >{`...Downloading`}</ButtonStyle>

                    :
                    <ButtonStyle onClick={() => handleGeneratePdf()}> Download {download_icon}</ButtonStyle>

                }

              </ButtonDiv>
              :

              <ButtonDiv >
                <ButtonStyle onClick={() => shareComp()}>Share {share_icon}</ButtonStyle>

              </ButtonDiv>
          }

        </TabWrapper>

        {renderTabContent()}

      </Wrapper>


    </Wrapper>
  );
};


export default ViewCompetitive;