import React, { useState } from 'react';
import styled from 'styled-components';
import { HelpIcon } from '../../Common/SvgIcon/CommonSvgIcons';
import InfoWrapper from '../../Common/InfoWrapper/InfoWrapper';
import TitleWithInfo from '../../Common/TitleWithInfo/TitleWithInfo';

const OverallPosts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const ParagraphTitle = styled.p`
margin:0px;
display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    min-height:${(props) => props?.title ? '50px' : ''};
`

const RelevantPost = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: 90%;

  padding: 20%;
  height: 100%;

  border-radius: 36px 36px 0px 36px;
  background:${(props) => props?.backgroundColor ? props?.backgroundColor : 'rgba(111, 98, 254, 0.22)'} ;

  margin-top: 20px;
  margin-left: 30px;

  color: #000;

  text-align: center;
  font-family: Open Sans;
  font-size: 17px;
`;

const Logo = styled.div`
  position: absolute;
  top: -7%;
  left: -7%;

  width: 90px;
  height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: #fff;
  border: 1px solid rgba(85, 172, 238, 0.84);

  box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
`;

const OverallReview = ({ backgroundColor = '', title = 'Overall Posts', icon, inforText = "", message = "", val = "Authoritative Score = Share + Likes + Comments + Views" }) => {
  const [infoText, setInfotext] = useState(false);
  return (
    <OverallPosts>
      <TitleWithInfo
        title={title}
        infoText={inforText != '' ? inforText : `In ${title} is a tile which shows the total number of posts collected and the specific time frame during which the data was gathered.`}
      />
      {/* <Title>
      {title} </Title> */}
      <RelevantPost backgroundColor={backgroundColor}>
        <Logo>{icon}</Logo>
        {message}
      </RelevantPost>
    </OverallPosts>
  );
};

export default OverallReview;
