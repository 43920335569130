import React from 'react';
import styled from 'styled-components';
import OverallReview from '../../../../components/OverallReview/OverallReview';
import { InstagramLogo } from '../../../../Common/SvgIcon/CommonSvgIcons';
import GraphHOC from '../../../../Common/GraphHOC/GraphHOC';
import BarGraph from '../../../../Common/BarGraph/BarGraph';
import RatingDonutChart from '../../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../../Common/FunnelGraph/FunnelGraph';
import VerticalBarGraph from '../../../../Common/BarGraph/VarticalBargraph';
import TrendsInUserActivity from './TrendInUserActivity';
import { useParsedEndDate, useParsedStartDate } from '../../../../utils/ParseDate';

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const Container = styled.div`
  display: flex;
  gap: 30px;
  height: 380px;
`;

const ChildContainer = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const ChildContainer1 = styled(ChildContainer)`
  width: 50%;
  height: max-content;
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box;
  padding: 5px;

  span {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px 10px;
  }

  div {
    margin: 10px 0px 0px 0px;
  }
`;

const OverviewTabInsta = ({
  overview_tab,
  filteredStartDate,
  filteredEndDate,
  filterOption,
  filteredData,
  resetFlag,
  view,
  channel,
  child,
  hideDropdown,
  
  startDate = '',
  endDate = '',
  formatValue
}) => {
  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];

  const engagementData = [
    {
      name: 'Likes',
      value: overview_tab?.engagement?.likes || 0,
      // fill: '#6640B8',
      fill: 'rgba(53, 60, 247, 0.5)'
    },
    {
      name: 'Comments',
      value: overview_tab?.engagement?.comments || 0,
      // fill: '#5FE495',
      fill: 'rgba(0, 213, 208, 1)'
    },
    // {
    //   name: 'Shares',
    //   // value: 869,
    //   value: overview_tab?.engagement?.shares || 0,
    //   fill: '#6F62FE',
    // },
    // {
    //   name: 'Saved',
    //   value: overview_tab?.engagement?.saved || 0,
    //   fill: '#AB71CE'
    // }
  ];

  const genderDemographicsData = [
    {
      name: 'Male',
      value: overview_tab?.gender_demographics?.male_percentage || 0,
      // fill: '#6640B8',
      fill: 'rgba(13, 135, 246, 1)',
    },
    {
      name: 'Female',
      value:overview_tab?.gender_demographics?.female_percentage || 0,
      // fill: '#EAAABD',
            fill: 'rgba(246, 79, 129, 1)',
    },
    {
      name: 'Unknown',
      value: overview_tab?.gender_demographics?.unknown_percentage || 0,
      // fill: '#E4E4E4',
            fill: 'rgba(218, 217, 219, 1)',
    },
  ];

  const verifiedAndNonVerified = [
    {
        name:'Verified',
        value: overview_tab?.verified_non_verified_users?.verified || 0,
         fill:'#6F62FE'
    },
    {
        name:'Non Verified',
        value: overview_tab?.verified_non_verified_users?.nonverified || 0,
        fill:'#5FE495'
    }
  ]

  const totalNoOfPosts = overview_tab?.total_count


  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={engagementData}
          /*  type={'%'} */
          view={view}
        />
      ) : (
        <BarGraph
          data={engagementData}
          /*  type={'%'} */
          borderRadius={'0px 12px 12px 0px'}
          barHeight={'35px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={engagementData}
        width={450}
      />
    ),
    'Donut Chart': (
      <DonutChart data={engagementData} />
    ),
    'Funnel Chart': <FunnelGraph data={engagementData} />,
  };

  const renderVerifiedNonVerified = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={verifiedAndNonVerified}
          type={'%'}
          view={view}
        />
      ) : (
        <BarGraph
          data={verifiedAndNonVerified}
          type={'%'}
          borderRadius={'0px 12px 12px 0px'}
          barHeight={'35px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={verifiedAndNonVerified}
        width={450}
        type='percentage'
      />
    ),
    'Donut Chart': (
      <DonutChart data={verifiedAndNonVerified} type='percentage' />
    ),
    'Funnel Chart': <FunnelGraph data={verifiedAndNonVerified} type={'%'} />,
  }

  const renderGenderDemography = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={genderDemographicsData}
          type={'%'}
          view={view}
        />
      ) : (
        <BarGraph
          data={genderDemographicsData}
          type={'%'}
          borderRadius={'0px 12px 12px 0px'}
          barHeight={'35px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={genderDemographicsData}
        width={450}
        type='percentage'
      />
    ),
    'Donut Chart': (
      <DonutChart data={genderDemographicsData} type='percentage' />
    ),
    'Funnel Chart': <FunnelGraph data={genderDemographicsData} type={'%'} />,
  };


  let genderDemographicsGraph;

  if (filterOption) {
    if (filterOption === 'Bar Graph') {
      genderDemographicsGraph = renderScreen['Bar Graph'];
    } else if (filterOption === 'Pie Chart') {
      genderDemographicsGraph = renderScreen['Pie Chart'];
    } else if (filterOption === 'Donut Chart') {
      genderDemographicsGraph = renderScreen['Donut Chart'];
    } else {
      genderDemographicsGraph = renderScreen['Funnel Chart'];
    }
  }


  const formattedDate = useParsedStartDate(false, '', startDate, formatValue);
  const endDateParsed = useParsedEndDate(false, '', endDate, formatValue);
  return (
    <>
      {
        <Wrapper>
          <Container>
            <ChildContainer>
              <OverallReview
                icon={InstagramLogo}
                message={`${totalNoOfPosts} Relevant posts were observed From ${formattedDate} to ${endDateParsed}`}
              />
            </ChildContainer>
            {/* {filterOption ? (
            <FilterContainer>
            <span>Engagement</span>
            <div>{genderDemographicsGraph}</div>
          </FilterContainer>
          ) : ( */}
            {
              child?.length > 0 && child[0]?.value == true &&
              <ChildContainer>
                <GraphHOC
                  title={'Engagement'}
                  options={options}
                  renderScreen={renderScreen}
                  startDate={formattedDate}
                  endDate={endDateParsed}
                  option={
                    child[0]?.child?.find(i => i?.value === true)?.name
                  }
                  hideDropdown={hideDropdown}
                />
              </ChildContainer>
            }

            {/* )} */}
          </Container>
          <Container className="pdf-page" style={{
            pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
          }}>
            {
              child?.length > 0 && child[1]?.value == true &&
              <ChildContainer>
                <GraphHOC
                  title={'Gender Demographics'}
                  options={options}
                  renderScreen={renderGenderDemography}

                  startDate={formattedDate}
                  endDate={endDateParsed}
                  option={child[1]?.child?.find(i => i?.value === true)?.name}
                  hideDropdown={hideDropdown}
                />
              </ChildContainer>
            }

            {/* {filterOption ? (
            <FilterContainer>
            <span>Verified and Non verified Users</span>
            <div>{genderDemographicsGraph}</div>
          </FilterContainer>
          ) : ( */}
            {/* {child?.length > 0 && child[0]?.value == true && */}
            {/* <ChildContainer>
              <GraphHOC
                title={'Verified and Non verified Users'}
                options={options}
                renderScreen={renderVerifiedNonVerified}
                
                startDate={formattedDate}
                endDate={endDateParsed}
                option={filterOption}
                hideDropdown={hideDropdown}
              />
            </ChildContainer> */}
            {/* // } */}

            {/* )} */}
          </Container>


          <Container className="pdf-page" style={{
            pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid',
          }}>
            {child?.length > 0 && child[2]?.value == true &&
              <ChildContainer1>
                <TrendsInUserActivity
                  data1={{
                    trends_in_user_activity: overview_tab?.volume,
                  }}
                  title={`Volume`}
                  ylabel={'Volume'}
                  countName={'likes_count'}
                  option='Years'
                  showLineGraph={child[2]?.child?.find(i => i?.value === true)?.name}
                  // showLineGraph="Line Graph"
                  hideDropdown={hideDropdown}
typeVal='instagram'
                  startDate={formattedDate}
                  endDate={endDateParsed}
                />
              </ChildContainer1>
            }

            {/* <ChildContainer1 >
              <TrendsInUserActivity
                data1={{
                  trends_in_user_activity: overview_tab?.comments_trend_activity,
                }}
                title={`Comments`}
                ylabel={'Comments'}
                countName={'likes_count'}
                option='Years'
                  // option={child[1]?.child[1]?.child?.find(i => i?.value === true)?.name}
                showLineGraph="Line Graph"
                hideDropdown={hideDropdown}
                
                startDate={formattedDate}
                endDate={endDateParsed}
              />
            </ChildContainer1> */}
          </Container>

          {child?.length > 0 && child[3]?.value == true &&
            <ChildContainer className="pdf-page" style={{
              pageBreakBefore: 'auto', pageBreakAfter: 'auto', pageBreakInside: 'avoid',
            }}>
              <TrendsInUserActivity
                data1={{
                  trends_in_user_activity: overview_tab?.trends_in_user_activity,
                }}
                title={`Trends in User Activity`}
                ylabel={'Engagement'}
                // countName={'engagement_count'}
                option='Years'
                showLineGraph={child[3]?.child?.find(i => i?.value === true)?.name}
                hideDropdown={hideDropdown}
                typeVal='instagram'
                startDate={formattedDate}
                endDate={endDateParsed}
              />
            </ChildContainer>
          }
        </Wrapper>

      }
    </>
  );

};

export default OverviewTabInsta;
