import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BrandAstraLightLogo } from '../../../assests/images/LandingAssets/BrandAstraLightLogo.svg';
import HeroSectionBG from '../../../assests/images/LandingAssets/HeroSectionBG.svg';
import MobileHeroSectionBG from '../../../assests/images/LandingAssets/MobileHeroSectionBG.svg';
import { useNavigate } from 'react-router-dom';
const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
const Wrapper = styled.div`
  padding-bottom: 102px;
  padding-left: 80px;
  padding-right: 120px;
  background-image: url(${HeroSectionBG});
  height: 684px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  @media only screen and ${device.tablet} {
    background-image: url(${MobileHeroSectionBG});
    padding: unset;
    height: 869px;
    margin-top: unset;
  }
`;
const LogoContainer = styled.div`
  padding-top: 240px;
  justify-content: start;
  display: flex;
  text-align: left;
  @media only screen and ${device.tablet} {
    padding-top: 100px;
    margin-left: -15px;
  }
`;

const TitleText = styled.div`
  color: #fbbc05;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  padding-left: 40px;
  margin-top: 30px;
  @media only screen and ${device.tablet} {
    padding-left: 24px;
    font-size: 40px;
  }
`;

const Description = styled.div`
  color: #d2d2d2;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  padding-left: 40px;
  @media only screen and ${device.tablet} {
    padding-left: 24px;
    font-size: 20px;
    max-width: 300px;
  }
`;
const BookaDemoButton = styled.button`
  color: #151515;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fff;
  margin-right: 28px;
  &:hover {
    background: linear-gradient(94deg, #fbbc05 0%, #f8a51b 100%);
    cursor: pointer;
  }
  @media only screen and ${device.tablet} {
    width: 100%;
    margin-right: unset;
  }
`;
const SeeOurPricingButton = styled.div`
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #fff;
  background: none;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &:hover {
    border: 2.5px solid #fff;
    margin: -1.5px -1.5px -1.5px -1.5px;
    cursor: pointer;
  }
  @media only screen and ${device.tablet} {
    width: 100%;
  padding: 8px 0px;

  }
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-left: 40px;
  margin-top: 80px;
  @media only screen and ${device.tablet} {
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding-left: unset;
    margin-top: 50px;
    margin-right: 24px;
    margin-left: 24px;
  }
`;
const BrandAstraBanner = () => {
  let navigate = useNavigate();
  return (
    <Wrapper>
      <LogoContainer>
        <BrandAstraLightLogo width={'271px'} />
      </LogoContainer>
      <TitleText>Your Brands Emotional GPS</TitleText>
      <Description>Navigate with Precision, Connect with Impact.</Description>
      <ButtonContainer>
        <BookaDemoButton
          onClick={() => {
            navigate('/book-a-demo');
          }}
        >
          Book a Demo
        </BookaDemoButton>
        <SeeOurPricingButton
          onClick={() => {
            const anchor = document.querySelector('#pricing');
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }}
        >
          See Our Pricing
        </SeeOurPricingButton>
      </ButtonContainer>
    </Wrapper>
  );
};
export default BrandAstraBanner;
