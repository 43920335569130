import React from 'react';
import styled from 'styled-components';
import { StarIcon } from '../SvgIcon/CommonSvgIcons';

const Wrapper = styled.div`
  font-family: Open Sans;
  width: 100%;
  max-width: ${(props) => props.maxWidth};
`;

const BarsWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  
  padding-top:${(props) => props?.paddingVal ? props?.paddingVal : '5px'};
  box-sizing: border-box;

  color: #636363;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;


  // text styling
  & > :nth-child(1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: right;
  }

  & .barLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    padding-left: 50px;
  }
  & .emoji {
    width: 28px;
    height: 28px;
    & svg {
      width: 100%;
      height: 100%;
    }
  }
`;


const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;

  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  
  min-height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bar = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const FilledBar = styled.div`
  height: ${(props) => props.barHeight};
  background: ${(props) => props.color};
  width: ${(props) => props.width};
  min-width: 1%;
  border-radius: ${(props) =>
    props.percentage === 100 ? '3px' : '3px 0 0 3px'};
  // margin: 8px 0px 0px 0px;

  ${(props) => {
    if (props.borderRadius) {
      return `border-radius: ${props.borderRadius}`;
    }
  }}
`;
const BarGraph = ({
  paddingVal='5px',
  data,
  maxWidth = '100%',
  type = '',
  borderRadius = '',
  barHeight = '27px',
  emojiList = {},
  starRating = false,
  
}) => {
  const sortedData = data.sort((a, b) => b.value - a.value);
  const maxValue = Math.max(...sortedData.map((item) => item.value));
  const multiplier = 100 / maxValue;
  return (
    <Wrapper maxWidth={sortedData && sortedData.length > 0 ? maxWidth : 100} className="pdf-page" style={{ pageBreakInside: 'avoid' }}>
      {(sortedData && sortedData.length > 0) ? sortedData?.map((rating, index) => (
        <BarsWrapper key={index} paddingVal={paddingVal}>
          {emojiList.length > 0 ? (
            <div className='barLabel'>
              <div className='emoji'>
                {emojiList[rating.name?.toLowerCase()]}
              </div>
              {rating.name}
            </div>
          ) : (
            <div>{rating.name}</div>
          )}
          {/* {emojiList.length > 0 ? (
            <div className='barLabel'>
              <div className='emoji'>{emojiList[index]}</div>
              {rating.name}
            </div>
          ) : (
            <div>{rating.name}</div>
          )} */}
          <Bar>
            <FilledBar
              width={Math.floor(rating.value * multiplier) + '%'}
              actualWidth={rating.value}
              color={rating.fill || '#353cf7'}
              borderRadius={borderRadius}
              barHeight={barHeight}
            ></FilledBar>
            <div style={{ marginLeft: '5px' }}>{`${typeof rating.value === 'number' && rating.value
              }`}{starRating === true ? <span>{StarIcon}</span> : type}</div>
          </Bar>
        </BarsWrapper>
      ))
        :
        <NoDataWrapper>
          {/* // <BarsWrapper> */}
          {/* No Data Available */}Insufficient Data

          {/* // </BarsWrapper> */}

        </NoDataWrapper>

      }
    </Wrapper>
  );
};

export default BarGraph;
