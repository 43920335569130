import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Check } from '../../../../assests/images/LandingAssets/Check.svg';
import { ReactComponent as Checkhighlighted } from '../../../../assests/images/LandingAssets/Checkhighlighted.svg';
import { useNavigate } from 'react-router-dom';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
const CardContainer = styled.div`
  display: flex;
  margin-top: 19px;
  width: 200px;
  height: 930px;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #b2b2b2;
  background: ${(props) =>
    props?.highlighted === true
      ? 'linear-gradient(180deg, #5937C9 0%, #391C99 100%)'
      : '#fafafa'};
  box-shadow: 4px 4px 5.1px 0px rgba(116, 116, 116, 0.15);
  @media only screen and ${device.tablet} {
    width: unset;
    padding: 30px;
    margin-left: 10px;
    margin-right: 10px;
    height: unset;
  }
`;
const TitleText = styled.div`
  color: ${(props) => (props?.highlighted === true ? '#E8E8E8' : ' #151515')};
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const TitleDescriptionText = styled.div`
  color: ${(props) => (props?.highlighted === true ? '#E8E8E8' : ' #151515')};
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  min-height: 66px;
`;
const TrialText = styled.div`
  color: ${(props) => (props?.highlighted === true ? '#FBBC05' : ' #5937c9')};
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 30px;
  @media only screen and ${device.tablet} {
    font-size: 24px;
  }
`;

const SubscriptionText = styled.div`
  color: ${(props) => (props?.highlighted === true ? '#B4B4B4' : ' #7d7d7d')};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
  text-align: left;
`;
const SubscriptionBold = styled.div`
  color: ${(props) => (props?.highlighted === true ? '#E8E8E8' : ' #151515')};
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  & > div {
    font-size: 16px;
    align-self: end;
  }
`;

const BookADemoButton = styled.button`
  margin-top: 40px;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #5937c9;
  background: ${(props) => (props?.highlighted === true ? '#FAFAFA' : 'none')};
  color: ${(props) => (props?.highlighted === true ? '#391C99' : ' #5937c9')};
  text-align: center;
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:hover {
    background: linear-gradient(94deg, #fbbc05 0%, #f8a51b 100%);
    cursor: pointer;
    color: #151515;
  }
  @media only screen and ${device.tablet} {
    margin-left: unset;
    margin-right: unset;
    font-size: 28px;
    padding: 10px;
  }
`;

const PlanTitle = styled.div`
  color: ${(props) => (props?.highlighted === true ? '#FBBC05' : ' #151515')};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 40px;
  text-align: left;
  @media only screen and ${device.tablet} {
    font-size: 18px;
  }
`;

const PlanDescription = styled.div`
  color: ${(props) =>
    props?.highlighted === true ? '#E8E8E8' : ' rgba(0, 0, 0, 0.6)'};

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-top: 15px;
  display: flex;
  gap: 13px;
  align-items: center;
  @media only screen and ${device.tablet} {
    font-size: 18px;
  }
`;

const TopMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  border-radius: 10px;
  background: #151515;
  color: #fafafa;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: ${(props) => (props?.planName === 'Scale' ? '10px' : '22px')};
  @media only screen and ${device.tablet} {
    display: none;
  }
`;
const HighestSellingMessage = styled.div`
  display: none;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fbbc05;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  @media only screen and ${device.tablet} {
    display: flex;
  }
`;
const PlaceHolder = styled.div`
  height: ${(props) => (props?.planName === 'Scale' ? '10px' : '44px')};
  @media only screen and ${device.tablet} {
    height: 10px;
  }
`;
const SubscriptionPlaceHolder = styled.div`
  height: 93px;
  @media only screen and ${device.tablet} {
    height: unset;
  }
`;
const PlanCard = (props) => {
  let navigate = useNavigate();
  const planData = props?.planData;
  return (
    <div>
      {planData?.planName === 'Scale' ? (
        <TopMessage planName={planData?.planName}>
          Highest Selling Package​
        </TopMessage>
      ) : (
        <PlaceHolder planName={planData?.planName} />
      )}

      <CardContainer highlighted={props?.highlighted}>
        {planData?.planName === 'Scale' && (
          <HighestSellingMessage>
            Highest Selling Package​
          </HighestSellingMessage>
        )}
        <TitleText highlighted={props?.highlighted}>
          {planData?.planName}
        </TitleText>
        <TitleDescriptionText highlighted={props?.highlighted}>
          {planData?.planDesc}
        </TitleDescriptionText>
        <TrialText
          highlighted={props?.highlighted}
          planName={planData?.planName}
        >
          {planData?.plancost}​
        </TrialText>
        {planData?.showSubscription ? (
          <div>
            <SubscriptionText highlighted={props?.highlighted}>
              Subscription
            </SubscriptionText>
            <SubscriptionBold highlighted={props?.highlighted}>
              Monthly - ${planData?.monthlySubscription}
            </SubscriptionBold>
            <SubscriptionBold highlighted={props?.highlighted}>
              Yearly - ${planData?.yearlySubscription}
              <div>/month</div>
            </SubscriptionBold>
          </div>
        ) : (
          <SubscriptionPlaceHolder />
        )}
        <BookADemoButton
          onClick={() => {
            navigate('/book-a-demo');
          }}
          highlighted={props?.highlighted}
        >
          Book a Demo
        </BookADemoButton>
        <div style={{ height: '164px', paddingBottom: '40px' }}>
          <PlanTitle highlighted={props?.highlighted}>Fundamentals:</PlanTitle>
          {planData?.fundamentals?.map((data, index) => {
            return (
              <PlanDescription highlighted={props?.highlighted} key={index}>
                {props?.highlighted === true ? (
                  <Checkhighlighted style={{ flexShrink: 0 }} width={18} />
                ) : (
                  <Check style={{ flexShrink: 0 }} width={18} />
                )}
                {data}
              </PlanDescription>
            );
          })}
        </div>
        <PlanTitle highlighted={props?.highlighted}>
          Everything in Starter, plus:
        </PlanTitle>
        {planData?.includes?.map((data, index) => {
          return (
            <PlanDescription key={index} highlighted={props?.highlighted}>
              {props?.highlighted === true ? (
                <Checkhighlighted style={{ flexShrink: 0 }} width={18} />
              ) : (
                <Check style={{ flexShrink: 0 }} width={18} />
              )}
              {data}
            </PlanDescription>
          );
        })}
      </CardContainer>
    </div>
  );
};
export default PlanCard;
