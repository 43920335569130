import React, { useState } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import Loader from '../../../assests/animation/Loader2.json'
import OverallReview from '../../../components/OverallReview/OverallReview';
import { InstagramLogo, NewsIcon, NewsLogo, X_icon } from '../../../Common/SvgIcon/CommonSvgIcons';
import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import WordCloudComponent from '../../../components/WordCloud/WordCloud';
// import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUserActivity';
import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUser_activity';

import BarGraph from '../../../Common/BarGraph/BarGraph';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import { Bar } from 'recharts';
import { format, parse } from 'date-fns';
import { useMemo } from 'react';
import { useParsedEndDate, useParsedStartDate } from '../../../utils/ParseDate';
import TitleWithInfo from '../../../Common/TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  padding: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const Container = styled.div`
  display: flex;
  gap: 30px;
  height: 380px;
`;

const NewsContainer = styled.div`
  display: flex;
  gap: 30px;
  height: 180px;
`;

const ChildContainer = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const ChildContainer2 = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
padding:15px 25px 15px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  box-sizing: border-box;
  flex-direction:column;    
  min-height: 200px;
`;


const RelevantPost = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: 90%;

  // padding: 20%;    
  min-height: 110px;

  border-radius: 36px 36px 36px;
  // background: rgba(111, 98, 254, 0.22);
  background:rgba(0, 213, 208, 0.5);

  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  color: #000;

  text-align: center;
  font-family: Open Sans;
  font-size: 17px;
`;

const Logo = styled.div`
  // position: absolute;
  // top: 7%;
  // left: -3%;

  // width: 90px;
  // height: 90px;

  position: absolute;
     top: 8%;
  left: -3%;
    width: 90px;
    height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: #fff;
  border: 1px solid rgba(85, 172, 238, 0.84);

  box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box;
  padding: 5px;

  span {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px 10px;
  }

  div {
    margin: 10px 0px 0px 0px;
  }
`;

const OverallPosts = styled.div`
  // display: flex;
  // flex-direction: column;
  // gap: 20px;
  // padding: 25px;
  // box-sizing: border-box;

  
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    padding: 25px;
    box-sizing: border-box;
    height: 90px;
    align-items: center;
    justify-content: center;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;


const OverviewNews = ({

  overview_tab,
  startDate = '',
  endDate = '',
  filteredStartDate,
  filteredEndDate,
  filterOption,
  filteredData,
  resetFlag,
  view,
  channel,
  hideDropdown,
  formatValue = "dd/yyyy/MM"
}) => {
  const totalNoOfPosts = resetFlag === true && filteredData ? filteredData?.total_count :
    overview_tab?.total_count



  const startDateParsed = useParsedStartDate(resetFlag, filteredStartDate, startDate, formatValue);
  const endDateParsed = useParsedEndDate(resetFlag, filteredEndDate, endDate, formatValue);

  return (
    <>
      {
        resetFlag === true && filteredData === undefined ? (
          <div style={{ marginTop: '-80px' }}>
            <Lottie
              data-testid='loader'
              animationData={Loader}
              loop={true}
              width='100px'
              height='100px'
            />
          </div>
        ) : (
          <Wrapper>
            <NewsContainer>
              <ChildContainer2>
      <Title>
        <TitleWithInfo
              title={'Overall Posts'}
              infoText={`In Overall posts is a tile which shows the total number of posts collected and the specific time frame during which the data was gathered.`}
            /></Title>
                {/* <Title>{'Overall Posts'}</Title> */}
                <RelevantPost>
                  <Logo>{NewsLogo}</Logo>
                  <OverallPosts>
                    {totalNoOfPosts} Relevant posts were observed From {startDateParsed} to {endDateParsed}
                  </OverallPosts>
                </RelevantPost>

              </ChildContainer2>


            </NewsContainer>
            <ChildContainer>
              <TrendsInUserActivity
                data1={{
                  trends_in_user_activity: resetFlag === true && filteredData ? filteredData?.trends_in_user_activity : overview_tab?.trends_in_user_activity,
                }}
                ylabel='Reviews'
                title='Trends In User Activity'
                hideDropdown={hideDropdown}
                startDate={startDateParsed}
                endDate={endDateParsed}
                formatValue={formatValue}
                infoText={'Trends in user activity highlight user engagement on posts across daily, weekly, and monthly intervals. This data helps identify the total number of posts and the specific dates of highest activity.'}
              />
            </ChildContainer>

          </Wrapper >
        )
      }
    </>
  );

};

export default OverviewNews;