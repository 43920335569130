import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: left;
  //width:95px;
  width: 100%;
  position: relative;
  // margin-top:100px;

  & > span {
    position: absolute;
  top: ${(props) => props.changeHeight ? '83px' : '175px'};
  
  right: ${(props) => props.changeHeight ? '8px' : '20px'};
    color: rgba(99, 99, 99, 0.7);
    font-family: Montserrat;
  }
`;


const TextField = styled.textarea`
  box-sizing: border-box;
  //display: flex;
  //flex-direction: row;
  align-items: center;
  padding: ${(props) => props.changeHeight ? ' 7px 23px' : ' 7px 21px'};
  gap: 10px;

  width: 100%;

  height: ${(props) => props.changeHeight ? '85px !important' : '180px !important'};
  background: #ffffff;
  border: 1px solid #a2a2a2;
  border-radius: 15px;
  font-family: Montserrat;
  color: black;
  font-size: 16px;
  //font-weight: 500;
`;

export const TextLabel = styled.label`
  //position: absolute;
  width: auto;
  height: 20px;
  /* left: 370px;
top: 350px; */
  // margin-left: 8px;
  margin-bottom: ${(props) => props.changeHeight ? '5px' : '10px'};
  font-family: 'Montserrat';
  display: inline-block;
  /* padding: 2px 18px; */
  font-weight: 500;
  font-size: 16px;
  color: #000000;
`;

const Description = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  characterLimit,
  changeHeight=false
}) => {
  //const characterLimit = 50;

  const [remainingCharacters, setRemainingCharacters] =
    useState(characterLimit);

  useEffect(() => {
    if (value) {
      setRemainingCharacters(characterLimit - value.length);
    }
  }, [value, characterLimit]);
  //const remainingCharacters = characterLimit - inputValue.length;
  return (
    <Wrapper changeHeight={changeHeight}>
      <TextLabel changeHeight={changeHeight}>{label}</TextLabel>
      <TextField
        type={type || 'text'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={250}
        changeHeight={changeHeight}
      />
      {value && characterLimit ? (
        <span>{remainingCharacters}</span>
      ) : (
        <span>250</span>
      )}
    </Wrapper>
  );
};

export default Description;
