import React, { useEffect, useMemo, useState } from 'react';
import {
    Wrapper,
    HeaderWrapper,
    Span1,
    Span2,
    ChannelsText,
    TwitterText,
    DateWrapper,
    StyledContainer,
    Container,
    Container2,
    ChildContainer,
    MainComponent,
    SubMainComponent,
    SubComponent1,
    SubComponent2,
    TopicBDiv,
    TopicADiv,
    ButtonDiv,
    ButtonStyle,
    EmotionContainer,
    TabHeading,
    TabWrapper,
    ChildContainerForCustom,
    CancelButtonStyle,
    DivGridForDownload,
    TableHeaderContentBody,
    TableHeaderContent,
    FontForTitleVs,
    HeaderDivMain
} from '../CompetitiveIntelligence/ViewCompetitive.styled';
import BrandLogo from '../../assests/images/BrandLogo.png'
import BrandAstraLogo from '../../assests/images/dark_brandLogo.png'

import { formatDateFilter } from '../../utils';
import MyDatePicker from '../../components/DatePicker/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonalDetails } from '../../store/redux/slices/tenantSettingSlice';
import { competitiveIntelligenceReport, competitiveIntelligenceShareReport } from '../../store/redux/slices/competitiveIntelligenceSlice';

import { AmazonIconSmall, emoji_anger, emoji_disgust, emoji_fear, emoji_joy, emoji_neutral, emoji_sadness, emoji_surprise, FacebookIcon, FlipkartIconSmall, InstagramLogo, X_icon, X_ICON_WHITE } from '../../Common/SvgIcon/CommonSvgIcons';

import TopicMentions from './Download/TopicMentions';
import Percentage3BarComp from './Download/Percentage3BarComp';
import TrendsMainContainer from './Download/TrendsMainContainer';
import PercentageBarComp from './Download/PercentageBar';
import HeaderForInsights from './HeaderForInsights';
import FooterForInsights from './FooterForInsights';
import { display } from '@mui/system';


const DownladInsights = ({ ci_id, theme = false }) => {
    const [jsonVal, setJsonVal] = useState('')
    const [loader, setLoader] = useState(false)
    const [compData, setCompData] = useState([])
    const [analysisData, setAnalysisData] = useState()
    const dispatch = useDispatch();
    const { personalDetailsData } = useSelector((state) => state.tenantSetting) || {};
    const transformText = (inputText) => {

        const words = inputText?.split(' ');
        const transformedWords = words?.map(word => word?.charAt(0).toUpperCase() + word?.slice(1));
        return transformedWords?.join(' ')
    };
    useEffect(() => {
        setLoader(true)


        dispatch(competitiveIntelligenceShareReport(ci_id))
            // dispatch(competitiveIntelligenceReport(ci_id))
            .then((res) => {

                setTimeout(() => {

                    setLoader(false)
                    setJsonVal(JSON?.parse(res?.payload?.data?.render_field_string || " "))
                    setAnalysisData(res?.payload?.data?.report?.ci_analysis || '')
                    setCompData(res?.payload?.data?.report?.ci_report || [])
                }, 1000);
            })
            .catch((err) => {

                setLoader(false)
                setAnalysisData('')
                setCompData([])
            })


        if (personalDetailsData === null) {
            dispatch(getPersonalDetails());
        }

    }, [ci_id, dispatch]);


    const formatValue = personalDetailsData?.app_preference?.date_format === "dd/mm/yyyy" ? 'dd-MM-yyyy' :
        personalDetailsData?.app_preference?.date_format === "mm/dd/yyyy" ? 'MM-dd-yyyy' :
            personalDetailsData?.app_preference?.date_format === "yyyy/mm/dd" ? 'yyyy-MM-dd' :
                'dd-MM-yyyy';


    const graphData = useMemo(() => {
        return [{
            "label": compData?.[0]?.chatter_topic_title,
            "channel": compData?.[0]?.channel,
            "male": compData?.[0]?.ci_report?.gender_demographics?.male_percentage,
            "female": compData?.[0]?.ci_report?.gender_demographics?.female_percentage,
            "unknown": compData?.[0]?.ci_report?.gender_demographics?.unknown_percentage

        },
        {
            "label": compData?.[1]?.chatter_topic_title,
            "channel": compData?.[1]?.channel,
            "male": compData?.[1]?.ci_report?.gender_demographics?.male_percentage,
            "female": compData?.[1]?.ci_report?.gender_demographics?.female_percentage,
            "unknown": compData?.[1]?.ci_report?.gender_demographics?.unknown_percentage

        }];
    }, [compData]);

    const allValues = graphData.flatMap(item => [item.male, item.female, item.unknown]);

    // Find the maximum value among all values
    const maxValue = Math.max(...allValues);
    const multiplier = 100 / maxValue;

    const emotionsData = [
        {
            'labelVal': emoji_joy,
            "label": 'Joy',
            "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.joy || 0,
            "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.joy || 0,

        },
        {
            'labelVal': emoji_neutral,
            "label": 'Neutral',
            "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.neutral || 0,
            "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.neutral || 0,
        },

        {
            'labelVal': emoji_surprise,
            "label": 'Surprise',
            "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.surprise || 0,
            "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.surprise || 0,
        },
        {
            'labelVal': emoji_fear,
            "label": 'Fear',
            "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.fear || 0,
            "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.fear || 0,
        },
        {
            'labelVal': emoji_sadness,
            "label": 'Sadness',
            "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.sadness || 0,
            "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.sadness || 0,
        },

        {
            'labelVal': emoji_anger,
            "label": 'Anger',
            "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.anger || 0,
            "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.anger || 0,
        },

        {
            'labelVal': emoji_disgust,
            "label": 'Disgust',
            "topicA": compData?.[0]?.ci_report?.emotion?.[0]?.disgust || 0,
            "topicB": compData?.[1]?.ci_report?.emotion?.[0]?.disgust || 0,
        }

    ];

    const graphDataSentiments = useMemo(() => {
        return [{
            "label": compData?.[0]?.chatter_topic_title || '',
            "channel": compData?.[0]?.channel || '',
            "positive": compData?.[0]?.ci_report?.sentiment_metrics?.positive_sentiment || 0,
            "negitive": compData?.[0]?.ci_report?.sentiment_metrics?.negative_sentiment || 0,
            "neutral": compData?.[0]?.ci_report?.sentiment_metrics?.neutral_sentiment || 0

        },
        {
            "label": compData?.[1]?.chatter_topic_title || '',
            "channel": compData?.[1]?.channel || '',
            "positive": compData?.[1]?.ci_report?.sentiment_metrics?.positive_sentiment || 0,
            "negitive": compData?.[1]?.ci_report?.sentiment_metrics?.negative_sentiment || 0,
            "neutral": compData?.[1]?.ci_report?.sentiment_metrics?.neutral_sentiment || 0
        }];
    }, [compData]);


    const sentimentTrendsDataSet = [
        {
            fill: false,
            data: 'topic1_values',
            borderColor: 'rgba(54, 162, 235, 1)', // Line color for topic 1
            pointBackgroundColor: 'green', // Marker color for topic 1
            pointBorderWidth: 0,
            pointRadius: 5, // Size of the markers
        },
        {
            fill: false,
            data: 'topic2_values',
            borderColor: 'rgba(255, 99, 132, 1)', // Line color for topic 1
            pointBackgroundColor: 'red', // Marker color for topic 2

            borderDash: [10, 5], // Dotted line (10px line, 5px gap)
            pointBorderWidth: 0,
            pointRadius: 5, // Size of the markers
            xAxisID: 'topic2_dates', // Use a secondary x-axis for energy
        }
    ];
    const trendsDataSet = [
        {
            fill: false,
            data: 'topic1_values',
            borderColor: 'rgba(54, 162, 235, 1)', // Line color for topic 1
            pointBackgroundColor: 'green', // Marker color for topic 1
            pointBorderWidth: 0,
            pointRadius: 5, // Size of the markers
        },
        {
            fill: false,
            data: 'topic2_values',
            borderColor: 'rgba(255, 99, 132, 1)', // Line color for topic 1
            pointBackgroundColor: 'red', // Marker color for topic 2

            pointBorderWidth: 0,
            pointRadius: 5, // Size of the markers
            xAxisID: 'topic2_dates', // Use a secondary x-axis for energy
        }
    ];


    const emotionsDataAllValues = emotionsData.flatMap(item => [item.topicA, item.topicB]);

    const emotionsValue = Math.max(...emotionsDataAllValues);
    const emotionMultiplier = 100 / emotionsValue;


    const allValueSentiments = graphDataSentiments.flatMap(item => [item.positive, item.negitive, item.neutral]);

    // Find the maximum value among all values
    const maxValueSentiments = Math.max(...allValueSentiments);
    const multiplierSentiments = 100 / maxValueSentiments;

    const avgRatingData = [{
        label: 'Average rating',
        topicA: compData?.[0]?.ci_report?.average_rating || 0,
        topicB: compData?.[1]?.ci_report?.average_rating || 0
    }]


    const avgEngagementData = [{
        label: 'Likes',
        topicA: compData?.[0]?.ci_report?.engagement_metrics?.likes || 0,
        topicB: compData?.[1]?.ci_report?.engagement_metrics?.likes || 0
    },
    {
        label: 'Comments',
        topicA: compData?.[0]?.ci_report?.engagement_metrics?.comments || 0,
        topicB: compData?.[1]?.ci_report?.engagement_metrics?.comments || 0
    }
    ]


    const avgRatingAllVAlues = avgRatingData.flatMap(item => [item.topicA, item.topicB]);

    const avgRatingValue = Math.max(...avgRatingAllVAlues);
    const avgRatingMultiplier = 100 / avgRatingValue;



    const avgEngageVAlues = avgEngagementData.flatMap(item => [item.topicA, item.topicB]);

    const avgEngageValue = Math.max(...avgEngageVAlues);
    const avgEnagageMultiplier = 100 / avgEngageValue;

    return (
        <Wrapper color={theme}>

            <HeaderWrapper>


                <MainComponent className='page'>
                    <div style={{
                        // height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'center',
                        alignItems: 'center',
                        // textAlign: 'center',
                        // pageBreakAfter: "always",
                        // breakAfter: 'page'
                        // pageBreakBefore: 'always', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                    }}>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <img src={theme == true ? BrandLogo : BrandAstraLogo} />

                        </div>
                        <p style={{
                            fontSize: '35px',
                            textAlign: 'center',
                            fontWeight: '600',
                            background: theme ? 'black' : '',
                            color: theme ? 'white' : 'black'
                        }}>
                            Comprehensive Competitive Analysis
                        </p>
                        <div style={{
                            padding: '2px', background: ' linear-gradient(90deg, #353CF7 0%, #97DBFC 25.5%, #00D5D0 67%, #97DBFC 100%)',


                            width: '85%'
                        }}></div>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '47% 6% 47%',
                            border: theme ? '2px solid white' : '2px solid rgba(152, 152, 152, 1)',
                            borderRadius: '5px',
                            padding: '10px',
                            width: '85%',
                            alignItems: 'center',
                            minHeight: '55px',
                            marginTop: '35px'
                        }}>
                            <FontForTitleVs theme={theme}>{compData?.[0]?.chatter_topic_title}</FontForTitleVs>
                            <FontForTitleVs theme={theme} style={{ fontSize: '16px' }}>vs</FontForTitleVs>
                            <FontForTitleVs theme={theme}>{compData?.[1]?.chatter_topic_title}</FontForTitleVs>
                        </div>
                        <div className="pdf-page" style={{
                            display: 'grid',
                            gridTemplateColumns: '49% 2% 49%',
                            gap: '0px',
                            // pageBreakAfter: "always",
                            // pageBreakAfter: 'always',
                            // breakAfter: 'page',
                            width: '80%',
                            // alignItems: 'center',
                            // justifyContent: 'center',
                            marginTop: '70px',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}>
                            <div style={{ padding: '15px', }}>
                                <DivGridForDownload theme={theme}>
                                    <div className='label'>Client</div>
                                    <div className='value'>{compData?.[0]?.chatter_topic_title}</div>
                                </DivGridForDownload>
                                {/* <DivGridForDownload theme={theme}>
                                    <div className='label'>Channel</div>
                                    <div className='value'>{compData?.[0]?.channel == 'twitter' ? X_ICON_WHITE : transformText(compData?.[0]?.channel || '')}</div>

                                </DivGridForDownload>
                                <DivGridForDownload theme={theme}>
                                    <div className='label'>Start Date</div>
                                    <div className='value'>{formatDateFilter(compData?.[0]?.start_date)}</div>
                                </DivGridForDownload>
                                <DivGridForDownload theme={theme}>
                                    <div className='label'>End Date</div>
                                    <div className='value'>{formatDateFilter(compData?.[0]?.end_date)}</div>
                                </DivGridForDownload> */}
                            </div>
                            <div
                            // style={{
                            //     width: '1px',
                            //     height: '100%',
                            //     background:theme ==true ? 'white' : 'black',
                            //     marginLeft: 'auto',
                            //     marginRight: 'auto'
                            // }} 
                            >

                            </div>

                            <div style={{
                                padding: '15px',
                            }}>
                                <DivGridForDownload theme={theme}>
                                    <div className='label'>Client</div>
                                    <div className='value'>{compData?.[1]?.chatter_topic_title}</div>
                                </DivGridForDownload>
                            </div>

                        </div>
                        <div style={{
                            gridTemplateColumns: '49% 2% 49%',
                            gap: '0px',
                            pageBreakAfter: "always",
                            pageBreakAfter: 'always',
                            breakAfter: 'page',
                            width: '41%',
                            // alignItems: 'center',
                            // justifyContent: 'center',
                            // marginTop: '70px',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}>

                            <DivGridForDownload theme={theme}>
                                <div className='label'>Channel</div>
                                <div className='value'>{compData?.[1]?.channel == 'twitter' && theme == true  ? X_ICON_WHITE : compData?.[1]?.channel == 'twitter' &&   theme == false ? X_icon : transformText(compData?.[1]?.channel || '')}</div>
                            </DivGridForDownload>
                            <DivGridForDownload theme={theme}>
                                <div className='label'>Start Date</div>
                                <div className='value'>{formatDateFilter(compData?.[1]?.start_date)}</div>
                            </DivGridForDownload>
                            <DivGridForDownload theme={theme}>
                                <div className='label'>End Date</div>
                                <div className='value'>{formatDateFilter(compData?.[1]?.end_date)}</div>
                            </DivGridForDownload>
                        </div>
                    </div>

                    <div className="pdf-page" style={{
                        // minHeight: '100%',
                        // display: "flex",
                        // flexDirection: "column",
                        // justifyContent: 'space-between',
                        // // alignItems: 'center',
                        // textAlign: 'center',
                        position: 'relative',
                        // pageBreakAfter: "always",
                        // breakAfter: 'page',
                        padding: '20px',
                        // minHeight: '100%',
                        pageBreakInside: 'avoid'
                        // pageBreakBefore: 'always', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                    }}>
                        <HeaderForInsights margin={'0px 0px 35px 0px'} theme={theme} />
                        <div >
                            <TableHeaderContent theme={theme}>
                                <div className='title'>Table of Contents</div>
                                <div className='value'>01</div>
                            </TableHeaderContent>
                            <TableHeaderContentBody theme={theme}>
                                <div className='title'>Topic Mentions and Gender Demographics</div>
                                <div className='value'>02</div>
                            </TableHeaderContentBody>
                            <TableHeaderContentBody theme={theme}>
                                <div className='title'>Trends In User Activity</div>
                                <div className='value'>03</div>
                            </TableHeaderContentBody>
                            <TableHeaderContentBody theme={theme}>
                                <div className='title'>Sentiment Trends</div>
                                <div className='value'>04</div>
                            </TableHeaderContentBody>
                            <TableHeaderContentBody theme={theme}>
                                <div className='title'>Engagement Metrics and Sentiments</div>
                                <div className='value'>05</div>
                            </TableHeaderContentBody>
                            <TableHeaderContentBody theme={theme}>
                                <div className='title'>Emotions</div>
                                <div className='value'>06</div>
                            </TableHeaderContentBody>

                        </div>
                        <FooterForInsights number="01" theme={theme} />
                    </div>
                </MainComponent>
            </HeaderWrapper>

            <Wrapper className='page'>
                <div
                    className="pdf-page" style={{
                        // pageBreakAfter: "always",
                        // breakAfter: 'page',
                        breakInside: 'avoid',
                        position: 'relative',

                        minHeight: '100%',
                        pageBreakBefore: 'avoid',
                        //  pageBreakAfter: 'auto', 
                        pageBreakInside: 'avoid'
                    }}
                >

                    <HeaderForInsights margin={'0px 30px 15px 30px'} theme={theme} />
                    <Container  >

                        <ChildContainer style={{ minHeight: '417px' }}>
                            <TopicMentions

                                theme={theme}
                                data={compData}
                                iconA={
                                    compData?.[0]?.channel === "twitter" ? X_icon :
                                        compData?.[0]?.channel === "instagram" ? InstagramLogo :
                                            compData?.[0]?.channel === "facebook" ? FacebookIcon :
                                                compData?.[0]?.channel === "amazon" ? AmazonIconSmall :
                                                    compData?.[0]?.channel === "flipkart" ? FlipkartIconSmall :
                                                        ''
                                }
                                iconB={
                                    compData?.[1]?.channel === "twitter" ? X_icon :
                                        compData?.[1]?.channel === "instagram" ? InstagramLogo :
                                            compData?.[1]?.channel === "facebook" ? FacebookIcon :
                                                compData?.[1]?.channel === "amazon" ? AmazonIconSmall :
                                                    compData?.[1]?.channel === "flipkart" ? FlipkartIconSmall :
                                                        ''
                                }
                            />
                        </ChildContainer>

                        <Percentage3BarComp
                            theme={theme}
                            desc={'Breakdown of engagement by male, female, and unknown users.'}
                            analysisData={analysisData}
                            compData={compData}
                            title={'Gender Demographics'}
                            data={graphData}
                            multiplier={multiplier}
                            summary1={analysisData?.gender_demographics?.data_1 || ''}
                            summary2={analysisData?.gender_demographics?.data_2 || ''}
                            style={{ display: 'none' }}
                            showTable={false}
                        />


                    </Container>

                    <FooterForInsights theme={theme} show={true} number="02" />
                </div>
                <div
                    className="pdf-page" style={{
                        // pageBreakAfter: "always",
                        // breakAfter: 'page',
                        breakInside: 'avoid',
                        position: 'relative',
                        minHeight: '100%',
                        // pageBreakBefore: 'always',
                        //  pageBreakAfter: 'auto', 
                        pageBreakInside: 'avoid',
                        // pageBreakAfter: "always",
                        // breakAfter: 'page',
                    }}
                >
                    <HeaderForInsights margin={'20px 30px 15px 30px'} theme={theme} />

                    <Container2
                    //  style={{ minHeight: '620px' }}
                    // style={{
                    //     pageBreakAfter: "always",
                    //     breakAfter: 'page',
                    //     breakInside: 'avoid',

                    //     // pageBreakBefore: 'always', 
                    //     pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                    // }}
                    >
                        <TrendsMainContainer
                            desc={'Daily volume of posts and mentions highlighting engagement trends.'}
                            downloadFeature={true}
                            showTitleForSentiment={false}
                            compData={compData}
                            showGraphLabel={false}
                            trendsDataSet={trendsDataSet}
                            analysisData={analysisData}
                            summary1={analysisData?.trends?.data_1}
                            // {analysisData?.trends?.data_1}
                            summary2={analysisData?.trends?.data_2}
                            showsummary={true}
                            theme={theme}
                            option={jsonVal?.trends || 'Days'}
                        />
                    </Container2>

                    <FooterForInsights theme={theme} show={true} number="03" />
                </div>


                <div
                    className="pdf-page" style={{
                        // pageBreakAfter: "always",
                        // breakAfter: 'page',
                        breakInside: 'avoid',
                        position: 'relative',

                        minHeight: '100%',
                        // pageBreakAfter: "always",
                        // breakAfter: 'page',
                        // pageBreakBefore: 'always',
                        //  pageBreakAfter: 'auto', 
                        pageBreakInside: 'avoid'
                    }}
                >
                    <HeaderForInsights margin={'20px 30px 15px 30px'} theme={theme} />
                    <Container2
                    // style={{
                    //     // pageBreakAfter: "always",
                    //     // breakAfter: 'page',
                    //     breakInside: 'avoid',

                    //     // pageBreakBefore: 'always',
                    //     //  pageBreakAfter: 'auto',
                    //     pageBreakInside: 'avoid'
                    // }}
                    >

                        <TrendsMainContainer
                            theme={theme}
                            desc={'Daily positive, negative, and neutral sentiment trends towards the brand.'}
                            downloadFeature={true}
                            showTitleForSentiment={true}
                            compData={compData}
                            showGraphLabel={true}
                            trendsDataSet={sentimentTrendsDataSet}
                            analysisData={analysisData}
                            summary1={analysisData?.sentiment_trends}
                            summary2={''}
                            showsummary={false}
                            showFilter={false}
                            option={jsonVal?.sentiments || 'Days'}
                        />

                    </Container2>

                    <FooterForInsights theme={theme} show={true} number="04" />

                </div>

                <div
                    className="pdf-page" style={{
                        // pageBreakAfter: "always",
                        // breakAfter: 'page',
                        position: 'relative',
                        breakInside: 'avoid',
                        minHeight: '100%',
                        // pageBreakAfter: "always",
                        // breakAfter: 'page',
                        // pageBreakBefore: 'always',
                        //  pageBreakAfter: 'auto', 
                        pageBreakInside: 'avoid'
                    }}
                >

                    <HeaderForInsights margin={'20px 30px 15px 30px'} theme={theme} />
                    <Container marginTopValue={'60px'}


                    // className="pdf-page" style={{
                    //     breakInside:'avoid', pageBreakBefore: 'always', pageBreakAfter: 'always',breakAfter:'page', pageBreakInside: 'avoid',background:'black'

                    //     // pageBreakBefore: 'always', pageBreakAfter: 'auto', pageBreakInside: 'avoid'
                    // }}
                    >
                        {
                            compData?.[0]?.channel === 'amazon' || compData?.[0]?.channel === 'flipkart' ?
                                <PercentageBarComp
                                    analysisData={analysisData}
                                    title={'Average Rating'}
                                    data={avgRatingData}
                                    multiplier={avgRatingMultiplier}
                                    compData={compData}
                                    summary1={analysisData?.rating_engagement_metrics?.average_rating || ''}
                                    summary2={analysisData?.gender_demographics?.data_2 || ''}
                                />

                                :
                                <PercentageBarComp
                                    // desc={' Likes, comments, and reposts showing user engagement.'}
                                    desc={' Likes, and comments showing user engagement.'}
                                    analysisData={analysisData}
                                    title={'Engagement Metrics'}
                                    data={avgEngagementData}
                                    multiplier={avgEnagageMultiplier}
                                    compData={compData}
                                    theme={theme}
                                    // summary1={`McDonald's receives more likes on Twitter due to frequent humorous, relatable, and viral content, including memes, challenges, and pop culture references, which resonate with a broad audience.`}
                                    // summary2={`The BurgerKing has more comments due to frequent promotions, collaborations, and strong customer interaction, including responses to both positive and negative feedback, fueling conversation.`}
                                    summary1={analysisData?.rating_engagement_metrics?.likes || ''}
                                    summary2={analysisData?.rating_engagement_metrics?.comments || ''}
                                />

                        }

                        <Percentage3BarComp
                            desc={'Summary of positive, negative, and neutral sentiment for the analysis period.'}
                            analysisData={analysisData}
                            compData={compData}
                            title={'Sentiments'}
                            data={graphDataSentiments}
                            showSentiment={true}
                            multiplier={multiplierSentiments}
                            theme={theme}
                            summary1={''}
                            summary2={''}
                        />


                    </Container>

                    <FooterForInsights theme={theme} show={true} number="05" />
                </div>
                <div className="pdf-page" style={{
                    // height: '790px',
                    breakInside: 'avoid',

                    position: 'relative',
                    minHeight: '100%',
                    // pageBreakBefore: 'always',
                    pageBreakInside: 'avoid'
                }}>


                    <HeaderForInsights margin={'20px 30px 15px 30px'} theme={theme} />
                    <EmotionContainer >

                        <PercentageBarComp
                            theme={theme}
                            desc={' Categorization of emotions—joy, neutral, surprise, fear, sadness, anger, and disgust—expressed in user activity.'}
                            title={'Emotions'}
                            data={emotionsData}
                            multiplier={emotionMultiplier}
                            compData={compData}
                            // summary1={`Burger King observed two predominant emotions with fear accounting for 31.7% due to the high interaction volume sparked by a controversial \"we don't snitch\" meme attributed to the brand, leading to discussions and neutral accounting for 22.2% stemming from conversations around everyday activities, brand preferences, fast food services, and discussions on product promotions and customer experiences, contributing to consistent interaction.`}
                            // summary2={`McDonald's observed two predominant emotions, with 23.6% of the emotions being fear, due to heightened discussions surrounding real and fictional events, misinformation, and public safety concerns, especially involving an arrest connected to a manifesto, and 19.4% being surprise resulting from unexpected associations between McDonald's and diverse unrelated topics, including unconventional advertisements and surprising mentions by celebrities or public figures.`}
                            summary1={analysisData?.emotion?.data_1 || ''}
                            summary2={analysisData?.emotion?.data_2 || ''}
                        />


                    </EmotionContainer>

                    <FooterForInsights theme={theme} show={true} number="06" />
                </div>

            </Wrapper>

        </Wrapper >
    );
};


export default DownladInsights;