import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Facebook } from '../../../assests/images/LandingAssets/TickerLogos/Facebook.svg';
import { ReactComponent as Amazon } from '../../../assests/images/LandingAssets/TickerLogos/Amazon.svg';
import { ReactComponent as Discord } from '../../../assests/images/LandingAssets/TickerLogos/Discord.svg';
import { ReactComponent as Google } from '../../../assests/images/LandingAssets/TickerLogos/Google.svg';
import { ReactComponent as Instagram } from '../../../assests/images/LandingAssets/TickerLogos/Instagram.svg';
import { ReactComponent as Myntra } from '../../../assests/images/LandingAssets/TickerLogos/Myntra.svg';
import { ReactComponent as Snapchat } from '../../../assests/images/LandingAssets/TickerLogos/Snapchat.svg';
import { ReactComponent as TheNewYorkTimes } from '../../../assests/images/LandingAssets/TickerLogos/TheNewYorkTimes.svg';
import { ReactComponent as Twitter } from '../../../assests/images/LandingAssets/TickerLogos/Twitter.svg';
import { ReactComponent as YouTube } from '../../../assests/images/LandingAssets/TickerLogos/YouTube.svg';
import Marquee from 'react-fast-marquee';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
const TitleText = styled.div`
  color: #7d7d7d;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 114px;
  margin-bottom: 27px;
  @media only screen and ${device.tablet} {
    margin-bottom: 50px;
  }
`;
const LogoContainer = styled.div`
  margin-right: 70px;
`;
const PlatformIntegrationTicker = () => {
  return (
    <div>
      <TitleText>Platform Integrations:</TitleText>
      <Marquee
        direction='left'
        speed={250}
        gradient={true}
        gradientColor='#ebebeb'
        style={{ marginBottom: '145px' }}
      >
        <LogoContainer>
          <Facebook />
        </LogoContainer>
        <LogoContainer>
          <Amazon />
        </LogoContainer>
        <LogoContainer>
          <Discord />
        </LogoContainer>
        <LogoContainer>
          <Google />
        </LogoContainer>
        <LogoContainer>
          <Instagram />
        </LogoContainer>
        <LogoContainer>
          <Myntra />
        </LogoContainer>
        <LogoContainer>
          <Snapchat />
        </LogoContainer>
        <LogoContainer>
          <TheNewYorkTimes />
        </LogoContainer>
        <LogoContainer>
          <Twitter />
        </LogoContainer>
        <LogoContainer>
          <YouTube />
        </LogoContainer>
      </Marquee>
    </div>
  );
};

export default PlatformIntegrationTicker;
