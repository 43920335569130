import React, { useEffect, useState } from 'react';
import MessagesTab from '../../../../../components/MessageTab/MessageTab';
import { useDispatch, useSelector } from 'react-redux';
import {
  reviewsForChatter,
  reviewsForChatterPreview,
  reviewsFilterChatter,
  reviewsFilterChatterPreview,
  chatterReviewsForDownload,
} from '../../../../../store/redux/slices/chatterSlice';
import styled from 'styled-components';
import Button from '../../../../../Common/Button/Button';
import { AmazonIconSmall, FlipkartIconSmall } from '../../../../../Common/SvgIcon/CommonSvgIcons';
import { flexbox } from '@mui/system';
import MyDatePicker from '../../../../../components/DatePicker/DatePicker';
import Lottie from 'lottie-react';
import Loader from '../../../../../assests/animation/Loader2.json'

const Wrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;
  margin: 30px;
  font-size: 15px;
  font-weight: 600;

  & > :first-child {
    box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
    padding: 8px 7px 5px 7px;
    border-radius: 10px;

    & svg {
      width: 30px;
      height: 30px;
    }
  }

  & input {
    box-shadow: 0px 0px 8px 0px #a2a2a23d;
    border: 1px solid #d9d9d9;
    margin-top: 5px;
    margin-left: -3px;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    color: #636363;

    padding: 5px 20px;
    border-radius: 5px;
  }
`;

function CommentsTab({ email_id,
  download,
  job_id,
  channelName,
  startDate = '',
  endDate = '',
  hideDropdown,
  preview_uuid,
  min = '',
  max = '',

  formatValue = 'dd-MM-yyyy' }) {
  const [loading, setLoading] = useState(false);
  const [firstDate, setFirstDate] = useState(startDate);
  const [lastDate, setLastDate] = useState(endDate);
  const [filterData, setFilteredData] = useState(null)
  const dispatch = useDispatch();
  const { reviewsForPreviewData } =
    useSelector((state) => state.chatterTracker) || {};

  const { reviewsForChatterData } =
    useSelector((state) => state.chatterTracker) || {};

  const { reviewsData } = useSelector((state) => state.chatterTracker) || {};


  useEffect(() => {

    setFirstDate(startDate);
    setFilteredData(null);
  }, [startDate, job_id])
  useEffect(() => {

    setFilteredData(null);
    setLastDate(endDate);
  }, [endDate, job_id])


  useEffect(() => {
    if (download) {
      dispatch(chatterReviewsForDownload({
        job_id,
        email_id
      }));
    } else if (typeof job_id === 'number' && reviewsForChatterData === null) {
      dispatch(reviewsForChatter(job_id));
    } else if (preview_uuid) {
      dispatch(reviewsForChatterPreview({preview_uuid}));
    } else {
      dispatch(reviewsForChatter(job_id));
    }
  }, [job_id]);

  const dateHandler = (e) => {
    const { name, value } = e;
    // console.log(value,"value");
    const formatDate = (isoString) => {
      const date = new Date(isoString); // Create a Date object from the ISO string
      const year = date.getFullYear(); // Get the year
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-based index, hence +1)
      const day = String(date.getDate()).padStart(2, '0'); // Get the day

      return `${year}-${month}-${day}`; // Return the formatted date
    };


    const formattedDate = formatDate(value);
    if (name === 'firstDate') {
      setFirstDate(formattedDate);
    } else if (name === 'lastDate') {
      setLastDate(formattedDate);
    }
  };

  const handleSave = () => {
    setLoading(true);
    const isValidDate = (date) => {
      return date instanceof Date && !isNaN(date.getTime());
    };

    const validateDate = (date) => {
      // Convert date strings to Date objects
      const start = new Date(min); // Explicitly parse the initialStartDate
      const end = new Date(max);     // Explicitly parse the initialEndDate
      const datedate = new Date(date);          // Explicitly parse the date to be validated

      if (!isValidDate(start) || !isValidDate(end) || !isValidDate(datedate)) return false;

      const isAfterMin = datedate >= start;
      const isBeforeMax = datedate <= end;


      return isAfterMin && isBeforeMax;
    };
    if (validateDate(firstDate) && validateDate(lastDate)) {
      const dates = {
        start_date: firstDate, end_date: lastDate
      }
      if (preview_uuid) {
        dispatch(reviewsForChatterPreview({dates,preview_uuid}))
          .then((res) => {
            setFilteredData(res.payload.data.reviews);
            setLoading(false)
          })
          .catch((error) => {
            console.error('Error:', error); // Handling any errors
            setLoading(false)
          });
      } else {

        dispatch(
          reviewsFilterChatter({ dates, job_id })
        )
          .then((res) => {
            setFilteredData(res.payload.data.reviews);
            setLoading(false)
          })
          .catch((error) => {
            console.error('Error:', error); // Handling any errors
            setLoading(false)
          });
      }

    } else {
      alert(`Enter valid Input between ${min} and ${max}`)

    }

  }
  return (
    <div style={{ display: flexbox, flexDirection: 'column', width: '96%', marginLeft: 'auto', marginRight: 'auto', marginTop: '40px' }}>
      {
        channelName && !hideDropdown &&
        <Wrapper>
          <div>{channelName === 'flipkart' ? FlipkartIconSmall : AmazonIconSmall}</div>
          <div>
            <div>First Date:</div>
            {/* <input
              type='date'
              name='firstDate'
              value={firstDate}
              onChange={dateHandler}
              min={startDate}
              max={endDate}
              /> */}
            <MyDatePicker
                width='100px' 
              name='firstDate'
              value={firstDate ? firstDate : ''}
              dateHandler={dateHandler}
              format={
                formatValue
              }
              min={min ? min : ''}
              max={max ? max : ''}
            />
          </div>
          <div>
            <div>Last Date:</div>
            <MyDatePicker
                width='100px'
              name='lastDate'
              value={lastDate ? lastDate : ''}
              dateHandler={dateHandler}
              format={
                formatValue
              }
              min={min ? min : ''}
              max={max ? max : ''}
            />
            {/* <input
              type='date'
              name='lastDate'
              value={lastDate}
              onChange={dateHandler}
              min={startDate}
              max={endDate}
            /> */}
          </div>
          <Button
            value={'Save'}
            onClick={handleSave}
            style={{
              width: '100px',
              borderRadius: '10px',
              height: '35px',
              fontSize: '18px'
            }}
          />
        </Wrapper>
      }
      {
        loading == true ? (
          <div style={{ marginTop: '-80px' }}>
            <Lottie
              data-testid='loader'
              animationData={Loader}
              loop={true}
              width='100px'
              height='100px'
            />
          </div>
        ) :
          <MessagesTab
            channel={channelName}
            reviews={
              filterData ? filterData : reviewsForChatterData?.reviews || []
            }
            columns={channelName === 'flipkart' ? ['Comments', 'Profile', 'Likes', 'Dislikes'] : ['Reviews', 'Profile', 'Helpful', 'Verified']}
          />
      }

    </div>
  );
}

export default CommentsTab;