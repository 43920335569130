import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BrandAstraLightLogo } from '../../../assests/images/LandingAssets/BrandAstraLightLogo.svg';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

const FooterContainer = styled.div`
  background: #151515;
  padding-bottom: 20px;
  @media only screen and ${device.tablet} {
    height: unset;
    padding: 53px 24px 70px 24px;
  }
`;

const FooterContent = styled.div`
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 65px;
  display: flex;
  justify-content: space-between;
  @media only screen and ${device.tablet} {
    flex-direction: column;
    padding: unset;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and ${device.tablet} {
    align-items: flex-start;
    padding-top: 53px;
  }
`;
const LogoText = styled.div`
  color: #fafafa;
  text-align: center;
  font-family: Inter;
  font-size: 10.688px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  @media only screen and ${device.tablet} {
    margin-top: 60px;
    width: 300px;
  }
`;

const TitleText = styled.div`
  color: #fafafa;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-align: left;
  @media only screen and ${device.tablet} {
    margin-bottom: 10px;
  }
`;

const ListText = styled.a`
  color: #b4b4b4;
  font-family: 'Inter';
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  letter-spacing: 0.8px;
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;
const EmailText = styled.div`
  color: #b4b4b4;
  font-family: 'Inter';
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  letter-spacing: 0.8px;
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  text-decoration: none;
`;
const AddressText = styled.div`
  color: #b4b4b4;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  margin-top: 20px;
  text-align: left;
  & > div {
    color: #b4b4b4;
    font-family: 'Inter';
    font-size: 15.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
  }
`;

const LandingFooter = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <LogoContainer>
          <BrandAstraLightLogo height={'29px'} width={'147px'} />
          <LogoText>A Copperpod Digital Product</LogoText>
        </LogoContainer>
        <SubContainer>
          <TitleText>COMPANY</TitleText>
          <ListText href='https://www.copperpod.digital/' target='_blank'>
            About
          </ListText>
        </SubContainer>
        <SubContainer>
          <TitleText>LEGAL</TitleText>
          <ListText
            href='https://drive.google.com/file/d/1984Ll1aSnUo7qIw2DmLfAUhMXyvbJdTI/view?usp=sharing'
            target='_blank'
          >
            Privacy Policy
          </ListText>
          <ListText
            href='https://drive.google.com/file/d/1-yOOT2UdQgPGBnugghCzC2cEEDP1H6Uw/view?usp=sharing'
            target='_blank'
          >
            Terms and Conditions
          </ListText>
        </SubContainer>
        <SubContainer>
          <TitleText>CONTACT</TitleText>
          <EmailText>info@brandastra.ai</EmailText>
          <AddressText>
            USA Headquarters
            <div>3 Springhill Drive Princeton Junction, New Jersey 08550</div>
          </AddressText>
          <AddressText>
            India Office
            <div>
              3rd floor, 78 New, Maruthi Emerald, Graphite India Main Rd ITPL
              Main Road, BEML Layout, Brookefield, Bengaluru, Karnataka 560066
            </div>
          </AddressText>
        </SubContainer>
      </FooterContent>
    </FooterContainer>
  );
};
export default LandingFooter;
