import React, { useSelector } from 'react';
// import ViewReport from './ViewReport';




// import React, { useState } from 'react';
import styled from 'styled-components';
import {
  download_icon,
  report_icon,
} from '../../Common/SvgIcon/CommonSvgIcons';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PopUp from '../../Common/PopUp/PopUp';
import ViewReport from './ViewReport';
import ViewReportsChatter from './ViewReportsChatter';
import { convertToTimeZone } from '../../utils';

const Wrapper = styled.div`
  border-left: 1px solid rgba(99, 99, 99, 0.2);
  text-align: left;
  padding: 0 20px;
  padding-bottom: 100px;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #353cf7;
    border-radius: 10px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
`;

const Subject = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 20px 0;
`;
const DownloadButton = styled.button`
  border-radius: 10px;
  background: #353cf7;
  color: #fff;

  font-size: 20px;
  font-weight: 600;

  padding: 5px 15px;
  border: none;

  display: flex;
  justify=-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

const MailPreview = styled.div`
  border-radius: 10px;
  background: rgba(174, 177, 252, 0.16);
  min-height: 70%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;

  color: #000;
  font-size: 24px;
  font-weight: 600;

  & > span {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 400;
  }
`;

const OpenButton = styled(DownloadButton)`
  font-size: 15px;
  padding: 9px 50px;
`;

const ReportWrapper = styled.div`
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #a2a2a2;
  position: relative;

  .fullScreenIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 20;
    cursor: pointer;
  }
`;

const PopupReportWrapper = styled.div`
  overflow: scroll !important;
  width: 100%;
`;



const ReportsModal = ({
  time_zone, date_format, time_format,
  formatValue = 'dd-MM-yyyy', data, open, handleClose }) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

  };

  let { formattedDate, formattedTime } = convertToTimeZone(data.creation_time, time_zone, time_format, date_format)

  return (
    <div>
      onClose={() => handleClose()}
      <PopUp >
        <PopupReportWrapper>
          {

            data.is_topic ?
              <ViewReport

                formattedDate={formattedDate} 
                formattedTime={formattedTime}
                formatValue={formatValue}
                data={data}
                job_id={data?.job_id + ''}
                email_id={data?.email_id + ''}
                handleCancelClick={handleClose}
                created_at={data.creation_time}
                is_topic={data.is_topic}
                hideDropdown={true}
              />
              :
              <ViewReportsChatter

                formattedDate={formattedDate} formattedTime={formattedTime}
                formatValue={formatValue}
                data={data}
                report_id={data?.report_id}
                email_id={data?.email_id + ''}
                handleCancelClick={handleClose}
                created_at={data.creation_time}
                is_topic={data.is_topic}
                hideDropdown={true}
              />

          }

        </PopupReportWrapper>
      </PopUp>
    </div>
  );
};


export default ReportsModal;