import React, { useState } from 'react';
import styled from 'styled-components';
import { NewsLogo } from '../../../../Common/SvgIcon/CommonSvgIcons';
import GlobalNews from '../../../../assests/images/GlobalNews.png'
import { useParsedEndDate, useParsedStartDate } from '../../../../utils/ParseDate';
import TrendsInUserActivity from './TrendInUserActivity';
import TitleWithInfo from '../../../../Common/TitleWithInfo/TitleWithInfo';

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const NewsContainer = styled.div`
  display: flex;
  gap: 30px;
  height: 180px;
`;

const ChildContainer = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const ChildContainer2 = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
padding:15px 25px 15px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  box-sizing: border-box;
  flex-direction:column;    
  min-height: 200px;
`;


const RelevantPost = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: 90%;

  // padding: 20%;    
  min-height: 110px;

  border-radius: 36px 36px 36px;
  background: rgba(111, 98, 254, 0.22);

  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;

  color: #000;

  text-align: center;
  font-family: Open Sans;
  font-size: 17px;
`;

const Logo = styled.div`
  // position: absolute;
  // top: 7%;
  // left: -3%;

  // width: 90px;
  // height: 90px;

  position: absolute;
     top: 8%;
  left: -3%;
    width: 90px;
    height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: #fff;
  border: 1px solid rgba(85, 172, 238, 0.84);

  box-shadow: 0px 0px 11px 0px rgba(63, 63, 63, 0.25) inset;
`;

const OverallPosts = styled.div`
  // display: flex;
  // flex-direction: column;
  // gap: 20px;
  // padding: 25px;
  // box-sizing: border-box;

  
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    padding: 25px;
    box-sizing: border-box;
    height: 90px;
    align-items: center;
    justify-content: center;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;


const OverviewNews = ({
  child,
  overview_tab,
  startDate = '',
  endDate = '',
  filteredStartDate,
  filteredEndDate,
  formatValue = "dd/yyyy/MM"
}) => {
  const totalNoOfPosts = overview_tab?.total_count;



  const startDateParsed = useParsedStartDate(false, filteredStartDate, startDate, formatValue);
  const endDateParsed = useParsedEndDate(false, filteredEndDate, endDate, formatValue);


  return (
    <>
      {
        (
          <Wrapper>
            <NewsContainer>
              <ChildContainer2>

                <Title> <TitleWithInfo
              title={'Overall Posts'}
              infoText={`A correlation matrix is a table showing correlation coefficients
            between variables. Each cell in the table shows the correlation
            between two variables`}
            /></Title>
                <RelevantPost>
                  <Logo > <img src={GlobalNews} /></Logo>
                  <OverallPosts>
                    {totalNoOfPosts} Relevant posts were observed From {startDateParsed} to {endDateParsed}
                  </OverallPosts>
                </RelevantPost>

              </ChildContainer2>


            </NewsContainer>

            {child?.length > 0 && child?.[0]?.value == true &&
              child?.[0]?.child?.[0]?.value == true &&
              <ChildContainer>
                <TrendsInUserActivity
                  data1={{
                    trends_in_user_activity: overview_tab?.trends_in_user_activity,
                  }}
                  ylabel='Reviews'
                  title='Trends In User Activity'
                  hideDropdown={true}
                  startDate={startDateParsed}
                  endDate={endDateParsed}
                  formatValue={formatValue}
                  option={child?.[0]?.child?.[0]?.child?.find(i => i?.value === true)?.name}
                  showLineGraph={'Line Graph'}

                />
              </ChildContainer>
            }
            {child?.length > 0 && child[0]?.value == true &&
              child[0]?.child[1]?.value == true &&
              <ChildContainer>
                <TrendsInUserActivity
                  data1={{
                    trends_in_user_activity: overview_tab?.trends_in_user_activity,
                  }}
                  ylabel='Reviews'
                  title='Trends In User Activity'
                  hideDropdown={true}
                  startDate={startDateParsed}
                  endDate={endDateParsed}
                  formatValue={formatValue}
                  option={child?.[1]?.child?.find(i => i?.value === true)?.name}
                  showLineGraph={'Area Graph'}
                />
              </ChildContainer>
            }


          </Wrapper >
        )
      }
    </>
  );

};

export default OverviewNews;