import React from 'react';
import styled from 'styled-components';
import CarousalCards from './partials/CarousalCards';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
const Wrapper = styled.div`
  margin-top: 256px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and ${device.tablet} {
    margin-top: 103px;
    padding-left: 24px;
    padding-right: 24px;
    width: unset;
  }
`;

const TitleText = styled.div`
  color: #391c99;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 1060px;
  @media only screen and ${device.tablet} {
    font-size: 24px;
    width: unset;
  }
`;
const CustomerReviewsCarousal = () => {
  return (
    <Wrapper>
      <TitleText>
        Listening to your customers isn’t just a strategy, it’s the heartbeat of
        your brand.
      </TitleText>
      <CarousalCards />
    </Wrapper>
  );
};
export default CustomerReviewsCarousal;
