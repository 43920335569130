import React, { useEffect, useState } from 'react';
import MessagesTab from '../../../components/MessageTab/MessageTab';
import { useDispatch, useSelector } from 'react-redux';

import {
  reviewsForTopic,
  reviewsForCreatedTopic,
  reviewsFilterTopic,
  reviewsFilterTopicPreview,
  reviewsForDownload,
} from '../../../store/redux/slices/topicSlice';
import styled from 'styled-components';
import {
  FacebookLogo,
  InstagramIconSmall,
  NewsLogo,
  X_icon,
} from '../../../Common/SvgIcon/CommonSvgIcons';
import Button from '../../../Common/Button/Button';
import { formatDateFilter } from '../../../utils';
import MyDatePicker from '../../../components/DatePicker/DatePicker';
import Lottie from 'lottie-react';
import Loader from '../../../assests/animation/Loader2.json';

const Wrapper = styled.div`
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-bottom: 85px;
`;

const LogoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px 0px #a2a2a23d;
  border: 1px solid #d9d9d9;
  width: 43px;
  height: 42px;
  border-radius: 5px;
  & svg {
    width: 20px;
    height: 20px;
    padding-top: 5px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  gap: 30px;
  font-size: 15px;
  font-weight: 600;
  padding: 30px 0px;
`;

const ContainerInput = styled.div`
  display: flex;
  align-items: end;
  gap: 10px;
  font-size: 15px;
  font-weight: 600;

  & input {
    box-shadow: 0px 0px 8px 0px #a2a2a23d;
    border: 1px solid #d9d9d9;
    margin-top: 5px;
    margin-left: -3px;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    color: #636363;

    padding: 5px 10px;
    border-radius: 5px;
  }
`;

function ReviewsTab({
  download,
  showReport,
  startDate = '',
  endDate = '',
  preview_uuid = '',
  job_id,
  email_id,
  filteredReviews,
  channel,
  min = '',
  max = '',
  hideDropdown,
  formatValue = 'dd-MM-yyyy',
  search_type = '',
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [firstDate, setFirstDate] = useState(startDate);
  const [lastDate, setLastDate] = useState(endDate);
  // const [firstTime, setFirstTime] = useState('');
  // const [lastTime, setLastTime] = useState('');
  const [filterData, setFilteredData] = useState(null);

  const dateHandler = (e) => {
    const { name, value } = e;
    // console.log(value,"value");
    const formatDate = (isoString) => {
      const date = new Date(isoString); // Create a Date object from the ISO string
      const year = date.getFullYear(); // Get the year
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-based index, hence +1)
      const day = String(date.getDate()).padStart(2, '0'); // Get the day

      return `${year}-${month}-${day}`; // Return the formatted date
    };

    const formattedDate = formatDate(value);

    if (name === 'firstPostDate' && value) {
      // const [year, month, day] = value?.split('-');
      setFirstDate(formattedDate);
    } else if (name === 'lastPostDateReview' && value) {
      // const [year, month, day] = value?.split('-');
      setLastDate(formattedDate);
    }
  };

  // const timeHandler = (e) => {
  //   const { name, value } = e.target;
  //   if (name === 'firstPostTime') {
  //     setFirstTime(value);
  //   } else if (name === 'lastPostTime') {
  //     setLastTime(value);
  //   }
  // };

  const handleSave = () => {
    setLoading(true);
    const isValidDate = (date) => {
      return date instanceof Date && !isNaN(date.getTime());
    };

    const validateDate = (date) => {
      // Convert date strings to Date objects
      const start = new Date(min); // Explicitly parse the initialStartDate
      const end = new Date(max); // Explicitly parse the initialEndDate
      const datedate = new Date(date); // Explicitly parse the date to be validated

      if (!isValidDate(start) || !isValidDate(end) || !isValidDate(datedate))
        return false;

      const isAfterMin = datedate >= start;
      const isBeforeMax = datedate <= end;

      // console.log("Start Date Range:", start);
      // console.log("End Date Range:", end);
      // console.log("Date Being Validated:", datedate);

      return isAfterMin && isBeforeMax;
    };

    if (validateDate(firstDate) && validateDate(lastDate)) {
      const dates = {
        start_date: firstDate,
        end_date: lastDate,
      };
      dispatch(
        preview_uuid
          ? reviewsFilterTopicPreview({ dates, preview_uuid })
          : reviewsFilterTopic({ dates, job_id })
      )
        .then((res) => {
          setFilteredData(res.payload.data.reviews);
          setLoading(false);
          // setFilter(true)
        })
        .catch((error) => {
          setLoading(false);
          console.error('Error:', error); // Handling any errors
        });
    } else {
      alert(`Enter valid Input between ${min} and ${max}`);
    }
  };
  const { reviewsForTopicData } =
    useSelector((state) => state.topicBuilder) || null;

  useEffect(() => {
    setFirstDate(startDate);
    setFilteredData(null);
  }, [startDate, job_id]);
  useEffect(() => {
    setFilteredData(null);
    setLastDate(endDate);
  }, [endDate, job_id]);

  useEffect(() => {
    setLoading(true);
    if (download || showReport) {
      dispatch(reviewsForDownload({ job_id, email_id }));
      setLoading(false);
    } else if (job_id && reviewsForTopicData === null && filteredReviews) {
      const dates = {
        start_date: formatDateFilter(startDate),
        end_date: formatDateFilter(endDate),
      };
      dispatch(reviewsFilterTopic({ dates, job_id }));
      setLoading(false);
    } else if (preview_uuid) {
      dispatch(reviewsForTopic(preview_uuid));
      setLoading(false);
    } else if (job_id) {
      dispatch(reviewsForCreatedTopic(job_id));
      setLoading(false);
    }
    // setFirstDate((prevStartDate) => {

    //   const newStartDate = startDate
    //     ? formatDateFilter(startDate)
    //     : '2024-01-01';

    //   // You can now compare newStartDate with prevStartDate if needed
    //   return prevStartDate !== newStartDate ? newStartDate : prevStartDate;
    // });

    // setLastDate((prevStartDate) => {

    //   const newEndDate = endDate
    //     ? formatDateFilter(endDate)
    //     : '2024-01-01';

    //   // You can now compare newStartDate with prevStartDate if needed
    //   return prevStartDate !== newEndDate ? newEndDate : prevStartDate;
    // });
    // } else if (reviewsForTopicData === null || reviewsForTopicData?.length === 0) {

    // }
  }, [job_id]);

  const formatDateFilterForDisplay = (dateString) => {
    if (dateString) {
      const [day, month, year] = dateString.split('/');
      const date = new Date(year, month - 1, day);
      const Cyear = date.getFullYear();
      const Cmonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based

      const Cday = String(date.getDate()).padStart(2, '0');
      // console.log(date,year, month - 1, day, "month",`${Cyear}-${Cmonth}-${Cday}`);
      return `${Cyear}-${Cmonth}-${Cday}`; // Returns YYYY-MM-DD
    } else {
      return '';
    }
  };

  // console.log(lastDate, endDate, formatValue, channel, reviewsForTopicData, "formatValue");

  return (
    <Wrapper>
      {!hideDropdown && (
        <Container>
          <LogoImage>
            {channel === 'news'
              ? NewsLogo
              : channel === 'facebook'
                ? FacebookLogo
                : channel === 'twitter'
                  ? X_icon
                  : InstagramIconSmall}
          </LogoImage>
          <ContainerInput>
            <div>
              <div>First Date:</div>
              {/* <input
                type="date"
                name="firstPostDate"
                value={firstDate ? formatDateFilterForDisplay(firstDate) : ''}
                // value={firstDate}
                min={min ? formatDateFilterForDisplay(min) : ''}
                max={max ? formatDateFilterForDisplay(max) : ''}
                onChange={dateHandler}
              /> */}
              <MyDatePicker 
                width='100px'
                name='firstPostDate'
                value={firstDate ? firstDate : ''}
                dateHandler={dateHandler}
                format={formatValue}
                min={min ? min : ''}
                max={max ? max : ''}
              />
            </div>
            {/* {
            channel === 'twitter' &&
              <div>
                <div>Time:</div>
                <input
                  type="time"
                  name="firstPostTime"
                  value={firstTime}
                  onChange={ timeHandler}
                />
              </div>
          } */}
          </ContainerInput>
          <ContainerInput>
            <div>
              <div>Last Date:</div>
              {/* <input
                type="date"
                name="lastPostDate"
                // value={lastDate}
                value={lastDate ? formatDateFilterForDisplay(lastDate) : ''} // Ensure value is in YYYY-MM-DD format

                min={min ? formatDateFilterForDisplay(min) : ''}
                max={max ? formatDateFilterForDisplay(max) : ''}
                onChange={dateHandler}
              /> */}
              <MyDatePicker
                width='100px'
                name='lastPostDateReview'
                value={lastDate ? lastDate : ''}
                dateHandler={dateHandler}
                format={formatValue}
                min={min ? min : ''}
                max={max ? max : ''}
              />
            </div>
            {/* {
            channel === 'twitter' &&
            <div>
              <div>Time:</div>
              <input
                type="time"
                name="lastPostTime"
                value={lastTime}
                onChange={timeHandler}
              />
            </div>
          } */}
          </ContainerInput>
          <Button
            value={'Save'}
            onClick={handleSave}
            style={{
              width: '100px',
              borderRadius: '10px',
              height: '35px',
              fontSize: '18px',
            }}
          />
        </Container>
      )}
      {loading == true ? (
        <div style={{ marginTop: '-80px' }}>
          <Lottie
            data-testid='loader'
            animationData={Loader}
            loop={true}
            width='100px'
            height='100px'
          />
        </div>
      ) : (
        <MessagesTab
          infoVal={channel == 'instagram' && search_type == "hashtag" ? 'Review a detailed list of all posts within the specified time period, including post content, links to the post page, user profile, and engagement metrics such as likes , comments and views. ' :
            channel == 'instagram' ? 'Review a detailed list of all posts within the specified time period, including post content, links to the post page and engagement metrics such as likes, comments and views.'
              : channel == 'twitter' ? 'Review a detailed list of all posts within the specified time period, including post content, links to the post page, user profile, and engagement metrics such as likes, replies, and views.'
                : 'Review a detailed list of all posts within the specified time period, including post content, links to the post page, user profile, and engagement metrics such as likes and comments.'}
          isNews={channel === 'news' ? true : false}
          download={download}
          graphStyles={{
            gridTemplateColumns:
              channel === 'news'
                ? '1fr 50% 1fr'
                : channel === 'twitter'
                  ? '45% 1fr 1fr 1fr 1fr'
                  : channel === 'instagram' && search_type == 'hashtag'
                    ? '45% 1fr 1fr 1fr 1fr'
                    : '45% 1fr 1fr 1fr',
          }}
          reviews={filterData ? filterData : reviewsForTopicData || []}
          filterCol={
            channel === 'facebook'
              ? [2, 3, 4]
              : channel === 'twitter'
                ? [2, 3, 4, 5]
                : channel === 'instagram' && search_type == 'hashtag'
                  ? [2, 3, 4, 5]
                  : [2, 3, 4]
          }
          // startDate={startDate}
          // endDate={endDate}
          columns={
            channel === 'news'
              ? ['News Source', 'Posts', 'Posted on']
              : channel === 'facebook'
                ? ['Posts', 'Profile', 'Likes', 'Comments']
                : channel === 'twitter'
                  ? ['Posts', 'Profile', 'Likes', 'Replies', 'Views']
                  : channel === 'instagram' && search_type == 'user'
                    ? ['Posts', 'Likes', 'Comments', 'Views']
                    : channel === 'instagram' && search_type == 'hashtag'
                      ? ['Posts', 'Profile', 'Likes', 'Comments', 'Views']
                      : ['Posts', 'Profile', 'Likes', 'Comments']
          }
          channel={channel}
        />
      )}
    </Wrapper>
  );
}

export default ReviewsTab;
