import React from 'react';
import {
    OverallPosts,
    Title,
    RelevantPost,
    MainDivForOverview,
    Logo,
    TextDiv,
    DateFontStyle,
    RelevantPost2,
    LogoOneChannel
} from './ViewCompetitive.styled';


const TopicMentions = ({ data, iconA, iconB, theme = false }) => {
    return (

        <OverallPosts>
            <Title theme={theme}>{'Topics Mentions'}</Title>

            {
                data?.[0]?.channel == data?.[1]?.channel ?
                    <div>
                        <LogoOneChannel>
                          
                        {iconA}  
                        </LogoOneChannel>
                        <div>
                            <RelevantPost border={'5px 5px 0px 0px'}>
                                <MainDivForOverview showTab={true}>
                                    {/* <Logo>
                                    {iconA}
                                </Logo> */}
                                    <TextDiv width={'230px'}>
                                        <h5 style={{
                                            margin: '0px', fontWeight: '500',
                                            color: 'white',
                                            fontSize: '15px'
                                        }}>Topic A :</h5>
                                        <p style={{
                                            margin: '0px', fontWeight: '400',
                                            color: 'white',
                                            fontSize: '13px'
                                        }}>{data?.[0]?.chatter_topic_title || ''} - {data?.[0]?.channel === 'amazon' || data?.[0]?.channel === 'flipkart' ? data?.[0]?.ci_report?.overall_reviews && `${data?.[0]?.ci_report?.overall_reviews} Mentions` : data?.[0]?.ci_report?.topic_mentions && `${data?.[0]?.ci_report?.topic_mentions} Mentions`}</p>
                                    </TextDiv>
                                </MainDivForOverview>
                                {/* 
        <MainDivForOverview>
            <Logo>
                {iconB}
            </Logo>
            <TextDiv>
                <h5 style={{ margin: '0px' }}>Topic B :</h5>
                <p style={{ margin: '0px' }}>{data?.[1]?.chatter_topic_title || ''} - {data?.[1]?.channel === 'amazon' || data?.[1]?.channel === 'flipkart' ? data?.[1]?.ci_report?.overall_reviews && `${data?.[1]?.ci_report?.overall_reviews} Mentions` : data?.[1]?.ci_report?.topic_mentions && `${data?.[1]?.ci_report?.topic_mentions} Mentions`}</p>
            </TextDiv>
        </MainDivForOverview> */}
                            </RelevantPost>

                            <RelevantPost2 showTab={true} border={'0px 0px 5px 5px'}>
                                <MainDivForOverview showTab={true}>
                                    {/* <Logo>
                                    {iconB}
                                </Logo> */}
                                    <TextDiv width={'230px'}>
                                        <h5 style={{
                                            margin: '0px', fontWeight: '500',
                                            color: 'white',
                                            fontSize: '15px'
                                        }}>Topic B :</h5>
                                        <p style={{
                                            margin: '0px', fontWeight: '400',
                                            color: 'white',
                                            fontSize: '13px'
                                        }}>{data?.[1]?.chatter_topic_title || ''} - {data?.[1]?.channel === 'amazon' || data?.[1]?.channel === 'flipkart' ? data?.[1]?.ci_report?.overall_reviews && `${data?.[1]?.ci_report?.overall_reviews} Mentions` : data?.[1]?.ci_report?.topic_mentions && `${data?.[1]?.ci_report?.topic_mentions} Mentions`}</p>
                                    </TextDiv>
                                </MainDivForOverview>

                                {/* <MainDivForOverview>
            <Logo>
                {iconB}
            </Logo>
            <TextDiv>
                <h5 style={{ margin: '0px' }}>Topic B :</h5>
                <p style={{ margin: '0px' }}>{data?.[1]?.chatter_topic_title || ''} - {data?.[1]?.channel === 'amazon' || data?.[1]?.channel === 'flipkart' ? data?.[1]?.ci_report?.overall_reviews && `${data?.[1]?.ci_report?.overall_reviews} Mentions` : data?.[1]?.ci_report?.topic_mentions && `${data?.[1]?.ci_report?.topic_mentions} Mentions`}</p>
            </TextDiv>
        </MainDivForOverview> */}
                            </RelevantPost2>
                        </div>
                    </div>

                    :

                    <div>
                        <RelevantPost>
                            <MainDivForOverview>
                                <Logo>
                                    {iconA}
                                </Logo>
                                <TextDiv>
                                    <h5 style={{
                                        margin: '0px', fontWeight: '500',
                                        color: 'white',
                                        fontSize: '15px'
                                    }}>Topic A :</h5>
                                    <p style={{
                                        margin: '0px', fontWeight: '400',
                                        color: 'white',
                                        fontSize: '13px'
                                    }}>{data?.[0]?.chatter_topic_title || ''} - {data?.[0]?.channel === 'amazon' || data?.[0]?.channel === 'flipkart' ? data?.[0]?.ci_report?.overall_reviews && `${data?.[0]?.ci_report?.overall_reviews} Mentions` : data?.[0]?.ci_report?.topic_mentions && `${data?.[0]?.ci_report?.topic_mentions} Mentions`}</p>
                                </TextDiv>
                            </MainDivForOverview>
                            {/* 
                <MainDivForOverview>
                    <Logo>
                        {iconB}
                    </Logo>
                    <TextDiv>
                        <h5 style={{ margin: '0px' }}>Topic B :</h5>
                        <p style={{ margin: '0px' }}>{data?.[1]?.chatter_topic_title || ''} - {data?.[1]?.channel === 'amazon' || data?.[1]?.channel === 'flipkart' ? data?.[1]?.ci_report?.overall_reviews && `${data?.[1]?.ci_report?.overall_reviews} Mentions` : data?.[1]?.ci_report?.topic_mentions && `${data?.[1]?.ci_report?.topic_mentions} Mentions`}</p>
                    </TextDiv>
                </MainDivForOverview> */}
                        </RelevantPost>

                        <RelevantPost2>
                            <MainDivForOverview>
                                <Logo>
                                    {iconB}
                                </Logo>
                                <TextDiv>
                                    <h5 style={{
                                        margin: '0px', fontWeight: '500',
                                        color: 'white',
                                        fontSize: '15px'
                                    }}>Topic B :</h5>
                                    <p style={{
                                        margin: '0px', fontWeight: '400',
                                        color: 'white',
                                        fontSize: '13px'
                                    }}>{data?.[1]?.chatter_topic_title || ''} - {data?.[1]?.channel === 'amazon' || data?.[1]?.channel === 'flipkart' ? data?.[1]?.ci_report?.overall_reviews && `${data?.[1]?.ci_report?.overall_reviews} Mentions` : data?.[1]?.ci_report?.topic_mentions && `${data?.[1]?.ci_report?.topic_mentions} Mentions`}</p>
                                </TextDiv>
                            </MainDivForOverview>

                            {/* <MainDivForOverview>
                    <Logo>
                        {iconB}
                    </Logo>
                    <TextDiv>
                        <h5 style={{ margin: '0px' }}>Topic B :</h5>
                        <p style={{ margin: '0px' }}>{data?.[1]?.chatter_topic_title || ''} - {data?.[1]?.channel === 'amazon' || data?.[1]?.channel === 'flipkart' ? data?.[1]?.ci_report?.overall_reviews && `${data?.[1]?.ci_report?.overall_reviews} Mentions` : data?.[1]?.ci_report?.topic_mentions && `${data?.[1]?.ci_report?.topic_mentions} Mentions`}</p>
                    </TextDiv>
                </MainDivForOverview> */}
                        </RelevantPost2>
                    </div>
            }
            <DateFontStyle>
                {/* This data is observed from 22/03/2023 to 22/04/2023 */}
            </DateFontStyle>
        </OverallPosts>
    );
};

export default TopicMentions;