import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
    productDetailFlipkart,
    productDetailList,
} from '../../../../store/redux/slices/chatterSlice';
import {
    AmazonIcon,
    CrossIconWhite,
    SearchIcon,
    FlipkartLogo,
    InstagramLogo,
} from '../../../../Common/SvgIcon/CommonSvgIcons';
import ProductDetails from '../../../ChatterTracker/MainComponent/ChatterTrackerBuilder/ShoppingChannels/ProductDetails';
import { getinstaSearchList, topicEmptysearchInstaValue } from '../../../../store/redux/slices/topicSlice';
// import FlipkartPlus from '../../../../../../src/assests/images/flipkartPlus.png' 
// import ProductDetails from './ProductDetails';
// import CountrySelect from './CountriesSelect';

// Styled components
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the popup is on top of other content */
`;

const PopupContainer = styled.div`
  width: ${(props) => props?.popupwidth ? props?.popupwidth : '58%'}; /* Adjust the width as needed */
  background-color: white;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: ${(props) => props.topic ? '-10px' : '-70px'};
  /*  overflow-y: scroll; */
`;

const PopupHeader = styled.div`
  display: flex;
  background-color: ${(pop) =>
        pop.selectedChannel === 'Flipkart' ? '#353cf7' : '#353cf7'};
  justify-content: space-between;
  border-bottom: ${(pop) =>
        pop.selectedChannel === 'Flipkart' ? '1px solid #2222' : 'none'};
  align-items: center;
  height: 40px;
  /* color: white; */
  padding: 10px;
  border-radius: 0px;
`;

const IconSpan = styled.span`
  //display:flex;
  margin: 10px 0px 0px 35px;
`;

const CloseIcon = styled.span`
  //float:right;
  margin-right: 15px;

  & > svg {
    fill: blue;
    stroke: ${(pop) => (pop.selectedChannel === 'Flipkart' ? 'none' : 'none')};
  }
`;

const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
  height: ${(props) => (props.expanded ? '460px' : '180px')};

  span {
    display: flex;
    margin-left: 25px;

    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
`;

const SearchWrapper = styled.div`
  // display: flex;
  // margin-left: -40px;
  // margin-bottom: ;
  // margin-bottom: ${(props) => (props.selectedChannel === 'Amazon' ? '105px' : '0px')};

    display: grid;
    grid-template-columns: 70% 14% 170px;
    gap: 25px;
    margin-top:20px;

    .flexWrap {
      
    display: flex;
    margin-left: -50px;
    }
  span {
    // position: relative;
    // left: 50px;
    // top: 45%;
        position: relative;
    left: 60px;
    // /* top: 35%; */
    height: 48px;
    display: flex;
    justify-content: initial;
    align-items: center;
    // margin-top: 10px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  width: ${(props) =>props.topic ? '135px' :'170px' };
  height: 50px;
  padding: 7px 21px;
  justify-content: center;
  align-items: center;
  // margin: 0px 0px 0px 20px;
  color: white;

    outline: none; /* Remove default outline */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  border-radius:25px;
  // border-radius: 100px;
  background: ${({ backcolor }) => backcolor || '#353cf7'} ;
  border: none;
`;

const Input = styled.input`
  margin: 0px 0px 0px 30px;
  padding-left: 40px;
  width: 100%;
  height: 45px;
  border-radius: 15px;
  border: 1px solid #a2a2a2;

  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
    outline: none; /* Remove default outline */
  font-weight: 500;
  &:focus {
  border: 1px solid #a2a2a2;
    outline: none; /* Remove default outline */
  }
`;

const Line = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  background: #dddddd;
`;

// Component
const PoPup = ({ isOpen, onClose, selectedChannel, searchType = '', setInstaDetails ,popupwidth='58%' , topic=false}) => {
    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = useState('');
    const [productList, setProductList] = useState('');
    const [expanded, setExpanded] = useState(false);
    let { searchInstaValue } = useSelector((state) => state.topicBuilder) || {};
    let { isLoadingVal } = useSelector((state) => state.topicBuilder) || {};
    if (!isOpen) {
        return null;
    }

    const handleProductClick = (e) => {
        setSearchQuery('');
        setProductList('');
        setExpanded(false);
        setInstaDetails(
            [{
                ...e,
                // type: searchType
            }]);
    }
    const handleSearch = () => {
        // if (selectedChannel === 'Amazon') {
        //   dispatch(productDetailList({ searchQuery, selectedCountry }));
        // } else {
        //   dispatch(productDetailFlipkart({ searchQuery, selectedCountry: 'India'}));
        // }

        setExpanded(true);
        let val = searchType.toLowerCase() == 'account' ? 'user' : searchType.toLowerCase() == 'hashtag' ? 'hashtag' : '';


        dispatch(getinstaSearchList({ searchType: val, value: searchQuery }))
        .then((res) =>{
        })
        
    };

    //   const loading = useSelector((state) => state.topicBuilder.isLoadingVal);
    // const loading = true;
    const handleChange = (event) => {
      let value = event.target.value;
    
      // If the searchType is 'Hashtag', handle '#' and space cases
      if (searchType === 'Hashtag') {
        // Remove the leading '#' if it exists
        if (value.startsWith('#')) {
          value = value.substring(1); // Remove the '#'
        }
    
        // If there are spaces, remove them
        if (value.includes(' ')) {
          value = value.replace(' ', ''); // Remove spaces
        }
    
        // Update the state for hashtags without space and without '#'
        setSearchQuery(value);
      } else {
        // Handle case when it's not a Hashtag searchType
        // If there is a leading space, remove it
        if (value.startsWith(' ')) {
          setSearchQuery(value.trimStart()); // Remove leading space
        } else {
          setSearchQuery(value); // Otherwise, update the value normally
        }
      }
    };

      const handleClose = () => {
        dispatch(topicEmptysearchInstaValue())
        onClose()
      }

    return (
        <PopupOverlay>
            <PopupContainer topic={topic} popupwidth={popupwidth}>
                <PopupHeader selectedChannel={selectedChannel}>
                    <IconSpan>
                        {InstagramLogo}
                        {/* {selectedChannel === 'Amazon' ? AmazonIcon : FlipkartLogo } */}
                    </IconSpan>
                    <CloseIcon selectedChannel={selectedChannel} onClick={handleClose}>
                        {CrossIconWhite}
                    </CloseIcon>
                </PopupHeader>
                <PopupBody expanded={expanded}>
                    <span>Search for the {searchType}</span>
                    <SearchWrapper selectedChannel={selectedChannel}>
                        <div className='flexWrap'>
                        
                            <span>{SearchIcon}</span>
                            <Input
                                data-testid='your-input-test-id'
                                type='text'
                                value={`${searchType === 'Hashtag' ? '#' : ''}${searchQuery}`}
                                // value={`${searchType  == 'Hashtag' && '#'}` + searchQuery}
                                // value=`${searchQuery}`
                                onChange={handleChange}
                            ></Input>
                        </div>
                        {/* {selectedChannel === 'Amazon' ? (
              <CountrySelect
                countries={countries}
                onSelect={handleCountrySelect}
              />
            ) : (
              ''
            )} */}
                        {
                            searchQuery ?

                                <Button topic={topic} onClick={handleSearch}>Search</Button>
                                :

                                <Button topic={topic} backcolor={'rgb(196 197 235)'} style={{cursor:'auto'}}>Search</Button>
                        }
                    </SearchWrapper>
                    {expanded && (
                        <div style={{ height: 'auto' }}>
                            <Line />
                            <ProductDetails
                                productList={
                                    searchInstaValue
                                }
                                isOpen={isOpen}
                                onClose={onClose}
                                showInsta={true}
                                account={searchType}
                                onProductClick={handleProductClick}
                            onLoading={isLoadingVal}
                            />
                        </div>
                    )}
                </PopupBody>
            </PopupContainer>
        </PopupOverlay>
    );
};

export default PoPup;
