import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PlanCard from './partials/PlanCard';
const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
const Container = styled.div`
  padding-top: 245px;
  padding-left: 120px;
  padding-right: 120px;
  @media only screen and ${device.tablet} {
    padding: 10px 24px 0px 24px;
    margin-top: 120px;
  }
`;
const TitleText = styled.div`
  color: #391c99;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media only screen and ${device.tablet} {
    font-size: 24px;
  }
`;

const CardListContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 109px;
  @media only screen and ${device.tablet} {
    flex-direction: column;
    margin-top: 60px;
    align-items: center;
  }
`;
const FindAPlanCards = () => {
  const planData = [
    {
      id: '0',
      planName: 'Standard',
      planDesc: 'For Individuals exploring analytics tools ​',
      plancost: 'Free (30-day trial)​',
      showSubscription: false,
      monthlySubscription: '100',
      yearlySubscription: '85',
      fundamentals: [
        '1 user login ​​',
        '2 channels​​',
        'Analyze Trending discussions (Up to 3 )​',
      ],
      includes: [
        'Platform Analytics​​',
        'Gender demographics analysis​',
        'Sentiment analysis​',
        'E-commerce sentiment analysis​​',
      ],
    },
    {
      id: '1',
      planName: 'Pro',
      planDesc: 'For freelancers and small businesses',
      plancost: '$5 trial (7 days)',
      showSubscription: true,
      monthlySubscription: '100',
      yearlySubscription: '85',
      fundamentals: [
        '3 user login​',
        'Up to 3 channels​',
        'Analyze Trending discussions (Up to 6 )​',
      ],
      includes: [
        'Sentiment insights​',
        'Emotional analysis',
        'GEN AI assistant',
        'Campaign analysis​',
        'Hashtag analysis​',
        'Influencer recognition​',
      ],
    },
    {
      id: '2',
      planName: 'Scale',
      planDesc: 'For start-ups and agencies​',
      plancost: '$10 trial (7 days)​',
      showSubscription: true,
      monthlySubscription: '280',
      yearlySubscription: '195',
      fundamentals: [
        '6 user login​​',
        'Up to 4 channels​',
        'Trending discussions (Up to 12 )​',
      ],
      includes: [
        'Geographical demographics analysis (applicable for e-commerce)​',
        'Competitor Intelligence​',
        'Custom Analysis ​',
        'Cluster analysis​​',
        'Sentinel hub (reporting)​​',
        'Unlimited access to past ​reports & analysis​',
      ],
    },
    {
      id: '3',
      planName: 'Elite​',
      planDesc: 'For Large enterprises​​',
      plancost: 'Custom Pricing​​',
      showSubscription: false,
      monthlySubscription: '280',
      yearlySubscription: '195',
      fundamentals: ['Up to 12 user login/s​​​', 'Customized channels​​'],
      includes: [
        'Customized queries​​',
        'Enterprise API integrations​​',
        'Alerts & notification​​',
        'Custom Analysis ​​',
        'Advanced correlation analysis​​',
        'Priority onboarding​',
      ],
    },
  ];
  return (
    <Container>
      <TitleText>Find a plan that works for your business</TitleText>
      <CardListContainer>
        {planData?.map((data, index) => {
          return (
            <div key={index}>
              <PlanCard
                highlighted={data?.planName === 'Scale' ? true : false}
                planData={data}
              />
            </div>
          );
        })}
      </CardListContainer>
    </Container>
  );
};

export default FindAPlanCards;
