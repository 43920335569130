import React, { useState } from 'react';
import styled from 'styled-components';
import ReviewLineChart from './LineChart';
import AreaGraph from './AreaGraph';
import { format } from 'date-fns';
import TitleWithInfo from '../TitleWithInfo/TitleWithInfo';
import { filter_icon } from '../SvgIcon/CommonSvgIcons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 25px;
  box-sizing: border-box;
`;

const Title = styled.div`
  /* color: #636363; */
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: min-content;

  color: #fff;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid #d9d9d9;

  & :not(:first-child) {
    border-left: 1px solid #e4e4e4;
    border-radius: 0px;
  }
`;

const Option = styled.span`
  white-space: nowrap;
  cursor: pointer;
  color: #222;
  border-radius: 0px;
  ${(props) =>
    props.selected &&
    `
      color: #fff;
      background:#353CF7;
      border-radius: 4px !important;
      font-weight: 500;
  `};
  padding: ${(props) => props.padding};
`;

const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;

const MainContainer = styled.div`
  flex: 1;
  /* display: flex; */
  width: ${(props) => props.graphWidth};
  margin: ${(props) => props.margin};
  align-items: center;
  margin-top: 10px;
  min-height: 300px;
`;

const StyledButton = styled.button`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  background: #353cf7;
  color: #fff;

  height: 35px;
  padding: 5px 20px;
  border-radius: 10px;

  border: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  padding-right: 180px; 
  padding-top:50px;
  z-index: 9;
`;
const FilterOptions = ({
  filterChange,
  cancelFunction,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setSelectedOptions((prev) =>
      checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
  };
  return (
    <Container>
      <Options>
        <input
          type='checkbox'
          id='likes_count'
          checked={selectedOptions.includes('likes_count')}
          onChange={handleCheckboxChange}
        />
        Likes
      </Options>
      <Options>
        <input
          type='checkbox'
          id='comments_count'
          checked={selectedOptions.includes('comments_count')}
          onChange={handleCheckboxChange}
        />
        Comments
      </Options>
      <Options>
        <input
          type='checkbox'
          id='reposts'
          onChange={handleCheckboxChange}
          checked={selectedOptions.includes('reposts')}
        />
        Reposts
      </Options>
      <Options>
        <input
          type='checkbox'
          id='views'
          onChange={handleCheckboxChange}
          checked={selectedOptions.includes('views')}
        />
        Views
      </Options>
      <ButtonContainer>
        <Button onClick={cancelFunction}>Cancel</Button>
        <Button
          onClick={() => {
            filterChange(selectedOptions);
          }}
          apply
        >
          Apply
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const ToggleButton = ({
  options = [],
  onClick = () => {},
  padding = '5px 20px',
  option,
}) => {
  const [selectedOption, setSelectedOption] = useState(option || options[0]);
  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onClick(option);
  };

  return (
    <ToggleButtonWrapper>
      {options.map((option) => (
        <Option
          selected={selectedOption === option}
          onClick={() => handleSelectOption(option)}
          padding={padding}
          key={option}
        >
          {option}
        </Option>
      ))}
    </ToggleButtonWrapper>
  );
};
const Container = styled.div`
  width: 200px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #a2a2a2;
  border-radius: 2px;
`;

const Options = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  cursor: pointer;

  input {
    margin-right: 10px;
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top:1px solid #A2A2A2;
  padding-top:10px
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;

  ${({ apply }) =>
    apply
      ? `
    background-color: #3b82f6;
    color: white;
  `
      : `
    background-color: #f5f5f5;
    color: #333;
  `}

  &:hover {
    opacity: 0.9;
  }
`;

const TrendsInUserActivity = ({
  data1,
  title,
  ylabel,
  option,
  hideDropdown,
  startDate,
  endDate,
  formatValue = 'dd-yyyy-MM',
  infoText = '',
  showFilters = false,
  typeVal = '',
}) => {
  const [timeSpan, setTimeSpan] = useState(option || 'Days');
  const [lineGraph, setLineGraph] = useState(true);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showFiltereData, setShowFilteredData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  let trendsInUserActivity;

  if (timeSpan === 'Days') {
    trendsInUserActivity = data1?.trends_in_user_activity?.days || {};
  } else if (timeSpan === 'Weeks') {
    trendsInUserActivity = data1?.trends_in_user_activity?.week || {};
  } else if (timeSpan === 'Months') {
    trendsInUserActivity = data1?.trends_in_user_activity?.month || {};
  } else if (timeSpan === 'Years') {
    trendsInUserActivity = data1?.trends_in_user_activity?.year || {}; //post API integration, update with year attribute
  }

  const timeline = trendsInUserActivity?.timeline || [];
  const reviewsCount =
    title === 'Impressions'
      ? trendsInUserActivity?.impression || []
      : trendsInUserActivity?.count || [];
  const engagementCount = trendsInUserActivity?.engagement_count || [];
  const likesCount = trendsInUserActivity?.likes_count || [];
  const commentsCount = trendsInUserActivity?.comments_count || [];
  const repostsCount = trendsInUserActivity?.reposts || [];
  const viewsCount = trendsInUserActivity?.views || [];

  const userActivityData =
    timeline &&
    (likesCount || engagementCount || commentsCount) &&
    timeline.length > 0 &&
    (reviewsCount.length > 0 ||
      engagementCount.length > 0 ||
      likesCount.length > 0 ||
      commentsCount.length > 0)
      ? timeline
          .map((date, index) => {
            if (date) {
              if (
                typeVal == 'instagram' &&
                title == 'Trends in User Activity'
              ) {
                return {
                  date,
                  engagement_count: engagementCount[index] || 0,
                  likes_count: likesCount[index] || 0,
                  comments_count: commentsCount[index] || 0,
                  // [`${ylabel?.toLowerCase() || 'reviews'}`]: reviewsCount[index] || 0,
                };
              } else if (
                typeVal === 'twitter' &&
                title == 'Trends in User Activity'
              ) {
                {
                  return {
                    date,
                    engagement_count: engagementCount[index] || 0,
                    likes_count: likesCount[index] || 0,
                    comments_count: commentsCount[index] || 0,
                    reposts: repostsCount[index] || 0,
                    views: viewsCount[index] || 0,
                    // [`${ylabel?.toLowerCase() || 'reviews'}`]: reviewsCount[index] || 0,
                  };
                }
              } else {
                return {
                  date,
                  [`${ylabel?.toLowerCase() || 'reviews'}`]:
                    reviewsCount[index] || 0,
                };
              }
            }
            // Return null if the date is invalid, to be filtered later
            return null;
          })
          .filter((item) => item !== null) // Remove any null entries
      : [];

  function filterAndCleanData(data, filterKeys) {
    const keyMap = {
      likes_count: 'likes_count',
      comments_count: 'comments_count',
      reposts: 'reposts',
      views: 'views',
    };

    return data.map((entry) => {
      const newEngagementCount = filterKeys.reduce((sum, key) => {
        const field = keyMap[key];
        return sum + (entry[field] || 0);
      }, 0);

      const filteredEntry = {
        engagement_count: newEngagementCount,
        date: entry.date,
      };

      filterKeys.forEach((key) => {
        const field = keyMap[key];
        if (field && field in entry) {
          filteredEntry[key] = entry[field];
        }
      });

      return filteredEntry;
    });
  }
  const handleActiveFilter = (selectedFilter) => {
    setShowFilteredData(true);
    setSelectedOptions(selectedFilter);
    setFilteredData(filterAndCleanData(userActivityData, selectedFilter));
  };
  // console.log(data1, reviewsCount, userActivityData, 'userActivityData');
  // console.log(title, JSON.stringify(userActivityData));
  const handleTimeSpanClick = (option) => {
    setTimeSpan(option);
  };

  const handleGraphSelection = (option) => {
    setLineGraph((prev) => !prev);
  };

  return (
    <Wrapper>
      <Title>
        <p>
          <TitleWithInfo title={title} infoText={infoText} />
        </p>

        {/* <p>{title}</p> */}
        <div style={{ display: 'flex' }}>
          {showFilters && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <StyledButton
                onClick={() => {
                  setShowFilterOptions(!showFilterOptions);
                }}
              >
                Filters
                {filter_icon}
              </StyledButton>
            </div>
          )}
          {hideDropdown !== true && (
            <ToggleButton
              options={['Line Graph', 'Area Graph']}
              onClick={handleGraphSelection}
            />
          )}
        </div>
      </Title>
      {hideDropdown !== true && (
        <ToggleButton
          options={['Days', 'Weeks', 'Months', 'Years']} //add years once api is available
          onClick={handleTimeSpanClick}
          padding='3px 30px'
          option={option}
        />
      )}
      {showFilterOptions && (
        <ModalOverlay>
          <FilterOptions
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            cancelFunction={() => {
              setShowFilterOptions(false);
              setShowFilteredData(false);
            }}
            filterChange={(data) => {
              handleActiveFilter(data, userActivityData);
              setShowFilterOptions(false);
            }}
          />
        </ModalOverlay>
      )}
      {userActivityData && (
        <MainContainer>
          {lineGraph ? (
            <ReviewLineChart
              height={370}
              data={showFiltereData ? filteredData : userActivityData}
              ylabel={ylabel}
              typeVal={typeVal}
              showTitle={title}
            />
          ) : (
            <AreaGraph
              height={370}
              data={showFiltereData ? filteredData : userActivityData}
              typeVal={typeVal}
              ylabel={ylabel}
              showTitle={title}
            />
          )}
        </MainContainer>
      )}
      {(startDate || endDate) && (
        <Span>
          This data is observed from {startDate} to {endDate}
        </Span>
      )}
    </Wrapper>
  );
};

export default TrendsInUserActivity;
