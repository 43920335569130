import React, { useMemo } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import Loader from '../../../assests/animation/Loader2.json'
import OverallReview from '../../../components/OverallReview/OverallReview';

import { FacebookIcon, FacebookLogo, Instagram, InstagramIconSmall, InstagramLogo, X_icon } from '../../../Common/SvgIcon/CommonSvgIcons';

import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUser_activity';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import VerifiedAndNonVerifiedUsers from '../../../Common/VerifiedNonVerifiedUserGraph/VerifiedNonVerifiedUserGraph';
import { format, parse } from 'date-fns';
import { useParsedEndDate, useParsedStartDate } from '../../../utils/ParseDate';

const Wrapper = styled.div`
  padding: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const Container = styled.div`
  display: flex;
  gap: 30px;
  height: 380px;
`;

const GraphWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  padding: 0px 10px;
  align-items: center;
  margin-top: 30px;

  &> p: first-child {
    ${'' /* color: #636363; */}
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0px 0px 10px;
  }
`;

const ChildContainer = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const ChildContainer1 = styled(ChildContainer)`
  width: 50%;
  height: max-content;
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box;
  padding: 5px;

  span {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px 10px;
  }

  div {
    margin: 10px 0px 0px 0px;
  }
`;

const OverviewTabFb = ({
    overview_tab,
    startDate = '',
    endDate = '',
    filteredStartDate,
    filteredEndDate,
    filterOption,
    filteredData,
    resetFlag,
    view,
    channel,
    hideDropdown,
    formatValue = "dd-yyyy-MM"
}) => {
    const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];
    const engagementData = [
        {
            name: 'Likes',
            value: resetFlag === true && filteredData ? filteredData?.engagement?.likes : overview_tab?.engagement?.likes || 0,
            fill: '#6640B8',
        },
        {
            name: 'Comments',
            value: resetFlag === true && filteredData ? filteredData?.engagement?.comments : overview_tab?.engagement?.comments || 0,
            fill: '#5FE495',
        },
        // {
        //   name: 'Shares',
        //   // value: 869,
        //   value: resetFlag === true && filteredData ? filteredData?.engagement?.shares : overview_tab?.engagement?.shares || 0,
        //   fill: '#6F62FE',
        // },
        // {
        //     name:'Saved',
        //     value:resetFlag === true && filteredData ? filteredData?.engagement.saved : overview_tab?.engagement?.saved || 0,
        //     fill:'#AB71CE'
        // }
    ];

    const genderDemographicsData = [
        {
            name: 'Male',
            value: resetFlag === true && filteredData ? filteredData?.gender_demographics?.male_percentage : overview_tab?.gender_demographics?.male_percentage || 0,
            // fill: '#6640B8',
            fill: 'rgba(13, 135, 246, 1)',
        },
        {
            name: 'Female',
            value: resetFlag === true && filteredData ? filteredData?.gender_demographics?.female_percentage : overview_tab?.gender_demographics?.female_percentage || 0,
            // fill: '#EAAABD',
            fill: 'rgba(246, 79, 129, 1)',
        },
        {
            name: 'Unknown',
            value: resetFlag === true && filteredData ? filteredData?.gender_demographics?.unknown_percentage : overview_tab?.gender_demographics?.unknown_percentage || 0,
            // fill: '#E4E4E4',
            fill: 'rgba(218, 217, 219, 1)',
        },
    ];

    // const engagementGraphData = /

    const engagementGraphData = [
        {
            name: 'Likes',
            value: resetFlag === true && filteredData ? filteredData?.engagement?.likes : overview_tab?.engagement?.likes || 0,
            // fill: '#6640B8',
            fill: 'rgba(53, 60, 247, 0.5)',
        },
        {
            name: 'Comments',
            value: resetFlag === true && filteredData ? filteredData?.engagement?.comments : overview_tab?.engagement?.comments || 0,
            // fill: '#EAAABD',
            fill: 'rgba(0, 213, 208, 1)',
        },
        // {
        //     name: 'Shares',
        //     value: resetFlag === true && filteredData ? filteredData?.engagement?.shares : overview_tab?.engagement?.shares || 0,
        //     fill: '#E4E4E4',
        // },
    ];



    const TopActiveUsers = resetFlag === true && filteredData ? filteredData?.top_active_users : overview_tab?.top_active_users || []

    // [
    //     {
    //         "rating": 'Alice Smith',
    //         "verified": 59,
    //         "nonverified": 15
    //     },
    //     {
    //         "rating": 'Cario Davis',
    //         "verified": 30,
    //         "nonverified": 85
    //     },
    //     {
    //         "rating": 'John',
    //         "verified": 32,
    //         "nonverified": 75
    //     },
    //     {
    //         "rating": 'Rohan',
    //         "verified": 40,
    //         "nonverified": 60
    //     },
    //     {
    //         "rating": 'Priyank',
    //         "verified": 15,
    //         "nonverified": 50
    //     }
    // ];



    const verifiedAndNonVerified = [
        {
            name: 'Verified',
            value: resetFlag === true && filteredData ? filteredData?.verified_non_verified_users?.verified : overview_tab?.verified_non_verified_users?.verified || 0,
            fill: '#6F62FE'
        },
        {
            name: 'Non Verified',
            value: resetFlag === true && filteredData ? filteredData?.verified_non_verified_users?.nonverified : overview_tab?.verified_non_verified_users?.nonverified || 0,
            fill: '#5FE495'
        }
    ]

    const totalNoOfPosts = resetFlag === true && filteredData ? filteredData?.total_count :
        overview_tab?.total_count


    const renderScreen = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph
                    data={engagementData}
                    /*  type={'%'} */
                    view={view}
                />
            ) : (
                <BarGraph
                    data={engagementData}
                    /*  type={'%'} */
                    borderRadius={'0px 0px 0px 0px'}
                    barHeight={'35px'}
                    view={view}
                />
            ),
        'Pie Chart': (
            <RatingDonutChart
                data={engagementData}
                width={450}
            />
        ),
        'Donut Chart': (
            <DonutChart data={engagementData} />
        ),
        'Funnel Chart': <FunnelGraph data={engagementData} />,
    };

    const renderVerifiedNonVerified = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph
                    data={verifiedAndNonVerified}
                    type={'%'}
                    view={view}
                />
            ) : (
                <BarGraph
                    data={verifiedAndNonVerified}
                    type={'%'}
                    borderRadius={'0px 0px 0px 0px'}
                    barHeight={'35px'}
                    view={view}
                />
            ),
        'Pie Chart': (
            <RatingDonutChart
                data={verifiedAndNonVerified}
                width={450}
                type='percentage'
            />
        ),
        'Donut Chart': (
            <DonutChart data={verifiedAndNonVerified} type='percentage' />
        ),
        'Funnel Chart': <FunnelGraph data={verifiedAndNonVerified} type={'%'} />,
    }

    const renderGenderDemography = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph
                    data={genderDemographicsData}
                    type={'%'}
                    view={view}
                />
            ) : (
                <BarGraph
                    paddingVal={'20px'}
                    data={genderDemographicsData}
                    type={'%'}
                    borderRadius={'0px 0px 0px 0px'}
                    barHeight={'35px'}
                    view={view}
                />
            ),
        'Pie Chart': (
            <RatingDonutChart
                data={genderDemographicsData}
                width={450}
                type='percentage'
            />
        ),
        'Donut Chart': (
            <DonutChart data={genderDemographicsData} type='percentage' />
        ),
        'Funnel Chart': <FunnelGraph data={genderDemographicsData} type={'%'} />,
    };
    const renderEngagement = {
        'Bar Graph':
            view === 'vertical' ? (
                <VerticalBarGraph
                    data={engagementGraphData}
                    // type={'%'}
                    view={view}
                />
            ) : (
                <BarGraph
                    paddingVal={'20px'}
                    data={engagementGraphData}
                    // type={'%'}
                    borderRadius={'0px 0px 0px 0px'}
                    barHeight={'35px'}
                    view={view}
                />
            ),
        'Pie Chart': (
            <RatingDonutChart
                data={engagementGraphData}
                width={450}
            // type='percentage'
            />
        ),
        'Donut Chart': (
            <DonutChart data={engagementGraphData} />
        ),
        'Funnel Chart': <FunnelGraph data={engagementGraphData} />,
    };

    let genderDemographicsGraph;

    if (filterOption) {
        if (filterOption === 'Bar Graph') {
            genderDemographicsGraph = renderScreen['Bar Graph'];
        } else if (filterOption === 'Pie Chart') {
            genderDemographicsGraph = renderScreen['Pie Chart'];
        } else if (filterOption === 'Donut Chart') {
            genderDemographicsGraph = renderScreen['Donut Chart'];
        } else {
            genderDemographicsGraph = renderScreen['Funnel Chart'];
        }
    }


    const startDateParsed = useParsedStartDate(resetFlag, filteredStartDate, startDate, formatValue);
    const endDateParsed = useParsedEndDate(resetFlag, filteredEndDate, endDate, formatValue);


    return (
        <>
            {
                resetFlag === true && filteredData === undefined ? (
                    <div style={{ marginTop: '-80px' }}>
                        <Lottie
                            data-testid='loader'
                            animationData={Loader}
                            loop={true}
                            width='100px'
                            height='100px'
                        />
                    </div>
                ) : (
                    <Wrapper>
                        <Container>
                            <ChildContainer>
                                <OverallReview
                                    backgroundColor='rgba(0, 213, 208, 0.5)'
                                    icon={FacebookLogo}
                                    message={`${totalNoOfPosts} Relevant posts were observed From ${startDateParsed} to ${endDateParsed}`}
                                    inforText={'The total number of posts relevant to your query within the specified time period.'}

                                />
                            </ChildContainer>
                            {/* {filterOption ? (
            <FilterContainer>
            <span>Engagement</span>
            <div>{genderDemographicsGraph}</div>
          </FilterContainer>
          ) : ( */}
                            <ChildContainer>
                                {/* <GraphHOC
                                    title={'Gender Demographics'}
                                    options={options}
                                    renderScreen={renderGenderDemography}
                                    startDate={resetFlag ? filteredStartDate : startDate}
                                    endDate={resetFlag ? filteredEndDate : endDate}
                                    option={filterOption}
                                    hideDropdown={hideDropdown}
                                /> */}
                                <GraphHOC
                                    graphWidth={'100%'}
                                    margin={'20px 0px 20px 0px'}
                                    title={'Engagement'}
                                    options={options}
                                    renderScreen={renderEngagement}
                                    startDate={startDateParsed}
                                    endDate={endDateParsed}
                                    option={filterOption}
                                    hideDropdown={hideDropdown}
                                    formatValue={formatValue}
                                    infoText={'The total number of likes and comments on posts relevant to your query within the specified time period.'}
                                />
                            </ChildContainer>
                            {/* )} */}
                        </Container>
                        <ChildContainer>
                            <GraphHOC
                                title={'Gender Demographics'}
                                options={options}
                                graphWidth={'100%'}
                                margin={'20px 0px 20px 0px'}
                                renderScreen={renderGenderDemography}
                                startDate={resetFlag ? filteredStartDate : startDate}
                                endDate={resetFlag ? filteredEndDate : endDate}
                                option={filterOption}
                                hideDropdown={hideDropdown}
                                formatValue={formatValue}
                                infoText={'The percentage gender distribution of user accounts relevant to your query, with categories for Male, Female, and Unknown when gender data is unavailable.'}

                            />
                            {/* <GraphHOC
                                    graphWidth={'100%'}
                                    margin={'20px 0px 20px 0px'}
                                    title={'Engagement'}
                                    options={options}
                                    renderScreen={renderEngagement}
                                    startDate={startDateParsed}
                                    endDate={endDateParsed}
                                    option={filterOption}
                                    hideDropdown={hideDropdown}
                                    formatValue={formatValue}
                                    infoText={'This metric shows the percentage of user engagement with your product, including likes, comments, and shares.'}
                                /> */}
                        </ChildContainer>
                        <ChildContainer>
                            <TrendsInUserActivity
                                data1={{
                                    trends_in_user_activity: resetFlag === true && filteredData ? filteredData?.volume : overview_tab?.volume,
                                }}
                                title={`Volume`}
                                ylabel={'Volume'}
                                // countName = {'engagement_count'}
                                option='Weeks'
                                hideDropdown={hideDropdown}
                                startDate={startDateParsed}
                                endDate={endDateParsed}
                                formatValue={formatValue}
                                infoText={'Track the total number of posts over a specified time period.'}

                            />
                        </ChildContainer>

                        <ChildContainer>
                            <TrendsInUserActivity
                                data1={{
                                    trends_in_user_activity: resetFlag === true && filteredData ? filteredData?.trends_in_user_activity : overview_tab?.trends_in_user_activity,
                                }}
                                title={`Trends in User Activity`}
                                ylabel={'Engagement'}
                                // countName = {'engagement_count'}
                                option='Weeks'
                                hideDropdown={hideDropdown}
                                startDate={startDateParsed}
                                endDate={endDateParsed}
                                formatValue={formatValue}
                                infoText={'Track the total engagement on posts, combining likes and comments, over a specified time period.'}
                                typeVal='instagram'
                            />
                        </ChildContainer>

                        {/* <GraphWrapper>
                            
                        </GraphWrapper> */}

                        {/* <ChildContainer> */}
                        {/* <VerifiedAndNonVerifiedUsers
                            title={'Top Active Users'}
                            dontShowStar={true}
                            data={TopActiveUsers}
                            Width={100}

                            graphStyles={{
                                display: 'grid',
                                gridTemplateColumns: '200px 1fr',
                                gap: '15px',
                                alignItems: 'center'
                            }}
                            Contents='contents'
                            startDate={startDateParsed}
                            endDate={endDateParsed}
                            formatValue={formatValue}
                            infoText='This feature the top active users, calculated based on their engagement (total likes, shares, and comments) and the number of posts they create'
                       
                        /> */}
                        {/* </ChildContainer> */}

                    </Wrapper>
                )
            }
        </>
    );

};

export default OverviewTabFb;
