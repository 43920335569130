




import React from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import './SentimentCharts.css'

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
  // max-width: ${(props) => props.maxWidth};

  max-width: ${(props) => props.showData ? props.maxWidth : '100%'};
  width: ${(props) => props.showData ? props.maxWidth : '100%'};
  `;

const NoDataWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 20% 1fr min-content;
  gap: 2%;
  padding-top: 5px;
  box-sizing: border-box;

width:100%;
  color: #636363;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  
  min-height: 215px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const COLORS = ['#57E188', '#F95D54', '#FFCA63', '#6F62FE', '#6F62FE'];

const renderCustomizedLabel =
  (data, type) =>
    ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      if (percent <= 0.01) {
        return null;
      }
      const radius = innerRadius + (outerRadius - innerRadius) * 2;
      const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
      const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

      const angle = midAngle > 180 ? midAngle - 360 : midAngle;

      let textAnchor;
      if (angle > 0) {
        textAnchor = 'start';
      } else {
        textAnchor = 'end';
      }

      return (
        <>
          <text
            x={x}
            y={y}
            fill='black'
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline='central'
            fontSize='14px'
          >
            {`${data[index].name} - ${data[index].value}${type === 'percentage' ? '%' : ''
              }`}
          </text>
        </>
      );
    };

const DonutChart = ({ data, align = "right", sentiment, type,maxWidth = '100%' }) => {
  // let finalData = data.filter(row => row.value > 0);
  let finalData = data
  .map(row => ({ ...row, value: parseFloat(row.value.toFixed(2)) }))
  .filter(row => row.value >= 0);
  const sortedData = finalData.sort((a, b) => b.value - a.value);

  const tooltipFormatter = (value) => {
    return ` ${ typeof value === 'number' && value !== 0 && value}`;
  };



  const CustomLegend = ({ payload }) => {
    return (
      <div style={{ width: '230px' ,marginLeft:'15px'}}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center',
            marginBottom: '15px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: entry.color, marginRight: '10px' }} />
            <span style={{
              wordBreak:'break-word',
              width:'220px'
            }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Wrapper className="pdf-page" style={{ pageBreakInside: 'avoid' }}  maxWidth={sortedData && sortedData.length > 0 ? maxWidth : 100}   showData={sortedData && sortedData?.length ? true :false}>
     {
        sortedData && sortedData?.length ? 
      
        
      <PieChart width={450} height={250}>
      <Pie
        data={sortedData}
        dataKey='value'
        cx='50%'
        cy='50%'
        startAngle={90}
        endAngle={-270}
        innerRadius={80} // Adjust the size of the hole
        outerRadius={100}
        //  label={renderCustomizedLabel(sortedData, type)}
      >
        {sortedData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.fill} />
        ))}
      </Pie>
      <Legend content={<CustomLegend />} layout='vertical' verticalAlign='middle' align='right' />
        
      {/* <Legend layout='vertical' verticalAlign='middle' align="right" /> */}
      <Tooltip formatter={tooltipFormatter} />
    </PieChart>
    :
    
    <NoDataWrapper>
    {/* No Data Available */}Insufficient Data
    </NoDataWrapper>
      } 
      {/*  <div style={{display:'inline'}}>
    <SquareWrapper/>
    <span>Positive</span>    
    </div>  */}
    </Wrapper>
  );
};

export default DonutChart;



