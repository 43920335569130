import React, { useMemo } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import Loader from '../../../assests/animation/Loader2.json'
import OverallReview from '../../../components/OverallReview/OverallReview';

import { Instagram, InstagramIconSmall, InstagramLogo, X_icon } from '../../../Common/SvgIcon/CommonSvgIcons';

import GraphHOC from '../../../Common/GraphHOC/GraphHOC';
import TrendsInUserActivity from '../../../Common/TendsInUserActivity/TrendsInUser_activity';
import BarGraph from '../../../Common/BarGraph/BarGraph';
import RatingDonutChart from '../../../Common/RatingDonutChart/DonutChart';
import DonutChart from '../../../Common/SentimentCharts/SentimentCharts';
import FunnelGraph from '../../../Common/FunnelGraph/FunnelGraph';
import VerticalBarGraph from '../../../Common/BarGraph/VarticalBargraph';
import { format, parse } from 'date-fns';
import { useParsedEndDate, useParsedStartDate } from '../../../utils/ParseDate';

const Wrapper = styled.div`
  padding: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const Container = styled.div`
  display: flex;
  gap: 30px;
  height: 380px;
`;

const ChildContainer = styled.div`
  flex: 1;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const ChildContainer1 = styled(ChildContainer)`
  width: 50%;
  height: max-content;
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box;
  padding: 5px;

  span {
    color: #636363;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;

    justify-content: space-between;
    align-items: center;
    margin: 10px 0px 0px 10px;
  }

  div {
    margin: 10px 0px 0px 0px;
  }
`;

const OverviewTabInsta = ({
  overview_tab,
  startDate = '',
  endDate = '',
  filteredStartDate,
  filteredEndDate,
  filterOption,
  filteredData,
  resetFlag,
  view,
  channel,
  hideDropdown,
  formatValue = "dd-yyyy-MM"

}) => {
  const options = ['Bar Graph', 'Pie Chart', 'Donut Chart', 'Funnel Chart'];
  const engagementData = [
    {
      name: 'Likes',
      value: resetFlag === true && filteredData ? filteredData?.engagement?.likes : overview_tab?.engagement?.likes || 0,
      // fill: '#6640B8',
      fill: 'rgba(53, 60, 247, 0.5)'
    },
    {
      name: 'Comments',
      value: resetFlag === true && filteredData ? filteredData?.engagement?.comments : overview_tab?.engagement?.comments || 0,
      // fill: '#5FE495',
      fill: 'rgba(0, 213, 208, 1)'
    },
    // {
    //   name: 'Shares',
    //   // value: 869,
    //   value: resetFlag === true && filteredData ? filteredData?.engagement?.shares : overview_tab?.engagement?.shares || 0,
    //   fill: '#6F62FE',
    // },
    // {
    //     name:'Saved',
    //     value:resetFlag === true && filteredData ? filteredData?.engagement.saved : overview_tab?.engagement?.saved || 0,
    //     fill:'#AB71CE'
    // }
  ];

  const genderDemographicsData = [
    {
      name: 'Male',
      value: resetFlag === true && filteredData ? filteredData?.gender_demographics?.male_percentage : overview_tab?.gender_demographics?.male_percentage || 0,
      // fill: '#6640B8',
      fill: 'rgba(13, 135, 246, 1)'
    },
    {
      name: 'Female',
      value: resetFlag === true && filteredData ? filteredData?.gender_demographics?.female_percentage : overview_tab?.gender_demographics?.female_percentage || 0,
      // fill: '#EAAABD',
      fill: 'rgba(246, 79, 129, 1)'
    },
    {
      name: 'Unknown',
      value: resetFlag === true && filteredData ? filteredData?.gender_demographics?.unknown_percentage : overview_tab?.gender_demographics?.unknown_percentage || 0,
      // fill: '#E4E4E4',
      fill: 'rgba(218, 217, 219, 1)'
    },
  ];

  const verifiedAndNonVerified = [
    {
      name: 'Verified',
      value: resetFlag === true && filteredData ? filteredData?.verified_non_verified_users?.verified : overview_tab?.verified_non_verified_users?.verified || 0,
      fill: '#6F62FE'
    },
    {
      name: 'Non Verified',
      value: resetFlag === true && filteredData ? filteredData?.verified_non_verified_users?.nonverified : overview_tab?.verified_non_verified_users?.nonverified || 0,
      fill: '#5FE495'
    }
  ]

  const totalNoOfPosts = resetFlag === true && filteredData ? filteredData?.total_count :
    overview_tab?.total_count


  const renderScreen = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={engagementData}
          /*  type={'%'} */
          view={view}
        />
      ) : (
        <BarGraph
          data={engagementData}
          /*  type={'%'} */
          borderRadius={'0px 0px 0px 0px'}
          barHeight={'35px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={engagementData}
        width={450}
      />
    ),
    'Donut Chart': (
      <DonutChart data={engagementData} />
    ),
    'Funnel Chart': <FunnelGraph data={engagementData} />,
  };

  const renderVerifiedNonVerified = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={verifiedAndNonVerified}
          type={'%'}
          view={view}
        />
      ) : (
        <BarGraph
          data={verifiedAndNonVerified}
          type={'%'}
          borderRadius={'0px 0px 0px 0px'}
          barHeight={'35px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={verifiedAndNonVerified}
        width={450}
        type='percentage'
      />
    ),
    'Donut Chart': (
      <DonutChart data={verifiedAndNonVerified} type='percentage' />
    ),
    'Funnel Chart': <FunnelGraph data={verifiedAndNonVerified} type={'%'} />,
  }

  const renderGenderDemography = {
    'Bar Graph':
      view === 'vertical' ? (
        <VerticalBarGraph
          data={genderDemographicsData}
          type={'%'}
          view={view}
        />
      ) : (
        <BarGraph
          data={genderDemographicsData}
          type={'%'}
          borderRadius={'0px 0px 0px 0px'}
          barHeight={'35px'}
          view={view}
        />
      ),
    'Pie Chart': (
      <RatingDonutChart
        data={genderDemographicsData}
        width={450}
        type='percentage'
      />
    ),
    'Donut Chart': (
      <DonutChart data={genderDemographicsData} type='percentage' />
    ),
    'Funnel Chart': <FunnelGraph data={genderDemographicsData} type={'%'} />,
  };

  let genderDemographicsGraph;

  if (filterOption) {
    if (filterOption === 'Bar Graph') {
      genderDemographicsGraph = renderScreen['Bar Graph'];
    } else if (filterOption === 'Pie Chart') {
      genderDemographicsGraph = renderScreen['Pie Chart'];
    } else if (filterOption === 'Donut Chart') {
      genderDemographicsGraph = renderScreen['Donut Chart'];
    } else {
      genderDemographicsGraph = renderScreen['Funnel Chart'];
    }
  }


  const startDateParsed = useParsedStartDate(resetFlag, filteredStartDate, startDate, formatValue);
  const endDateParsed = useParsedEndDate(resetFlag, filteredEndDate, endDate, formatValue);

  return (
    <>
      {
        resetFlag === true && filteredData === undefined ? (
          <div style={{ marginTop: '-80px' }}>
            <Lottie
              data-testid='loader'
              animationData={Loader}
              loop={true}
              width='100px'
              height='100px'
            />
          </div>
        ) : (
          <Wrapper>
            <Container>
              <ChildContainer>
                <OverallReview
                  inforText='The total number of posts relevant to your topic within the specified time period.'
                  backgroundColor={'rgba(0, 213, 208, 0.5)'}
                  icon={InstagramLogo}
                  message={`${totalNoOfPosts} Relevant posts were observed From ${startDateParsed} to ${endDateParsed}`}
                />
              </ChildContainer>
              {/* {filterOption ? (
            <FilterContainer>
            <span>Engagement</span>
            <div>{genderDemographicsGraph}</div>
          </FilterContainer>
          ) : ( */}
              <ChildContainer>
                <GraphHOC
                  title={'Engagement'}
                  options={options}
                  renderScreen={renderScreen}
                  startDate={startDateParsed}
                  endDate={endDateParsed}
                  option={filterOption}
                  hideDropdown={hideDropdown}
                  formatValue={formatValue}
                  infoText='The total number of likes and comments on posts relevant to your topic within the specified time period.'
                // infoText={'This metric shows the percentage of user engagement with your product, including likes and comments.'}
                />
              </ChildContainer>
              {/* )} */}
            </Container>
            <Container>
              <ChildContainer>
                <GraphHOC
                  title={'Gender Demographics'}
                  options={options}
                  renderScreen={renderGenderDemography}
                  startDate={startDateParsed}
                  endDate={endDateParsed}
                  option={filterOption}
                  hideDropdown={hideDropdown}
                  formatValue={formatValue}
                  infoText='The percentage gender distribution of user accounts relevant to your query, with categories for Male, Female, and Unknown when gender data is unavailable.'
                // infoText={'This shows the percentage distribution of male and female users who are actively engaged with your product. It helps to understand the gender-based user engagement with your product'}

                />
              </ChildContainer>
              {/* {filterOption ? (
            <FilterContainer>
            <span>Verified and Non verified Users</span>
            <div>{genderDemographicsGraph}</div>
          </FilterContainer>
          ) : ( */}
              {/* <ChildContainer>
              <GraphHOC
                title={'Verified and Non verified Users'}
                options={options}
                renderScreen={renderVerifiedNonVerified}
                startDate={startDateParsed}
                endDate={endDateParsed}
                option={filterOption}
                hideDropdown={hideDropdown}
                formatValue={formatValue}
                infoText='This feature displays the percentage of top active users, broken down into verified and non-verified categories.'
                       
              />
            </ChildContainer> */}
              {/* )} */}
            </Container>
            <ChildContainer>
              <TrendsInUserActivity
                data1={{
                  trends_in_user_activity: resetFlag === true && filteredData ? filteredData?.volume : overview_tab?.volume,
                }}
                title={`Volume`}
                ylabel={'Volume'}
                // countName = {'engagement_count'}
                option='Weeks'
                hideDropdown={hideDropdown}
                startDate={startDateParsed}
                endDate={endDateParsed}
                formatValue={formatValue}
                infoText={'Track the total number of posts over a specified time period.'}
                typeVal='instagram'
              />
            </ChildContainer>
            <ChildContainer>
              <TrendsInUserActivity
                data1={{
                  trends_in_user_activity: resetFlag === true && filteredData ? filteredData?.trends_in_user_activity : overview_tab?.trends_in_user_activity,
                }}
                title={`Trends in User Activity`}
                ylabel={'Engagement'}
                // countName = {'engagement_count'}
                option='Weeks'
                hideDropdown={hideDropdown}
                startDate={startDateParsed}
                endDate={endDateParsed}
                formatValue={formatValue}
                infoText={'Track the total engagement on posts, combining likes and comments, over a specified time period.'}
                typeVal='instagram'
              />
            </ChildContainer>
            {/* <ChildContainer>
          <TrendsInUserActivity
            data1={{
              trends_in_user_activity:resetFlag === true && filteredData ? filteredData?.likes_trend_activity : overview_tab?.likes_trend_activity,
            }}
            title={`Likes`}
            ylabel={'Likes'}
            // countName = {'likes_count'}
            option = 'Weeks'
            hideDropdown={hideDropdown}
            startDate={startDateParsed}
            endDate={endDateParsed}
            formatValue={formatValue}
            infoText={'Trends in user activity highlight user engagement on posts across daily, weekly, and monthly intervals. This data helps identify the total number of posts and the specific dates of highest activity.'}
             
          />
          </ChildContainer> */}

            {/* <ChildContainer>
            <TrendsInUserActivity
              data1={{
                trends_in_user_activity:resetFlag === true && filteredData ? filteredData?.comments_trend_activity : overview_tab?.comments_trend_activity,
              }}
              title={`Comments`}
              ylabel={'Comments'}
              // countName = {'likes_count'}
              option = 'Weeks'
              hideDropdown={hideDropdown}
              startDate={startDateParsed}
              endDate={endDateParsed}
              formatValue={formatValue}
              infoText={'Trends in user activity highlight user engagement on posts across daily, weekly, and monthly intervals. This data helps identify the total number of posts and the specific dates of highest activity.'}
             
            />
            </ChildContainer> */}


          </Wrapper>
        )
      }
    </>
  );

};

export default OverviewTabInsta;
