import React, { useEffect, useMemo, useState } from 'react';

import { date_icon, sort_icon, X_LOGO } from '../../Common/SvgIcon/CommonSvgIcons';
import SearchBar from '../../Common/SearchBar/SearchBar';

import { useDispatch, useSelector } from 'react-redux';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { getPosts, getSearchPosts } from '../../store/redux/slices/sentinelHubSlice';
import {
    StyledTable,
    CustomTableCell,
    TableCellEllipsis,
    CustomTableContainer,
    SearchWrapper,
    Wrapper,
    EllipsisText,
    CustomTableRow,
    StyledTableHeader,
    TableHeaderFlexWrapper, DropDownWrapper, DropDownContentDiv, InputDropDownCheckBox
} from './PostReport.styled'
import PostsSlpitScreen from './PostsSlpitScreen';
import Pagination from '../../components/Pagination/Pagination';
import Dropdown from './Download/ReportDownload/DropDown/Dropdown';


const PostsReport = () => {

    const PageSize = 10;
    const [channelName, setChannelName] = useState('')
    const [queryVal, setQueryVal] = useState('')
    const [setId, handleSetId] = useState(-1)
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedOption, setSelectedOption] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();

    const { getAllPostsData, total_count } =
        useSelector((state) => state.sentinelHub) || [];

    const [popUpData, setPopUpData] = useState();

    const [showSplit, setShowSplit] = useState(false);

    const [columns, setColumns] = useState([
        {
            id: 'Channel', label: 'Channel', width: '10%',

            sort: { news: false,amazon: false, twitter: false, flipkart: false,facebook:false,instagram:false, all: true },

        },
        {
            id: 'Date', label: 'Date and Time',
            sort: { asc: false, desc: false }, width: ''
        },
        {
            id: 'Posts',
            label: 'Posts',
            sort: { asc: false, desc: false }, width: '28%'
        },
        {
            id: 'Title',
            label: 'Title',
            sort: { asc: false, desc: false }, width: '15%'
        },
        {
            id: 'Name',
            label: 'Name',
            sort: { asc: false, desc: false }, width: ''
        },
    ]);

    const dropDownOptions = [
        {
            label: 'Arrange By',
            options: [
                { value: 'reviewed_on', label: 'Date and Time', sec: 1 },
                { value: 'user_name', label: 'From', sec: 1 },
                // { value: 'channel', label: 'Channel', sec: 1 },
                { value: 'title', label: 'Title', sec: 1 },
                { value: 'posts', label: 'Posts', sec: 1 },

            ]
        },
        {
            label: 'Sort',
            options: [
                { value: 'asc', label: 'Newest on top', sec: 2 },
                { value: 'desc', label: 'Oldest on top', sec: 2 }
            ]
        }
    ];



    const handleOpenPopup = (data, index) => {
        setPopUpData(data)
        setShowSplit(true)
    }

    useEffect(() => {
        if (getAllPostsData === null || getAllPostsData.length === 0) {
            // dispatch(getPosts());
            dispatch(getSearchPosts({
                page: 1,
                query: queryVal,

            }))
        }
    }, []);


    // const currentTableData = useMemo(() => {
    //     // const firstPageIndex = (pageNumber) * PageSize;
    //     // const lastPageIndex = firstPageIndex + PageSize;
    //     // ?.slice(firstPageIndex, lastPageIndex)
    //     return getAllPostsData;
    // }, [currentPage, getAllPostsData]);

    const handleChangePageNumber = (page) => {
        setPageNumber(page)
        dispatch(getSearchPosts({
            page: page,
            query: queryVal,
            "column_name": selectedOption?.length > 0 ? selectedOption[0].value : '',
            "sortorder": selectedOption?.length > 1 ? selectedOption[1].value : 'asc',
            channel: channelName

        }))
    }


    // const handleSelectChange = () => {

    // }

    const handleSelectChange = (selVal) => {
        // const tempReviews = [...selectedTab];

        // Filter out options from both sections
        const sec1Options = selVal.filter(option => option.sec === 1);
        const sec2Options = selVal.filter(option => option.sec === 2);

        setSelectedOption([...sec1Options.slice(-1), ...sec2Options.slice(-1)]);

        if (sec1Options?.length > 0 || sec2Options?.length > 0) {
            dispatch(getSearchPosts(
                {
                    "query": queryVal,
                    "page": 1,
                    "column_name": sec1Options?.length > 0 ? sec1Options[sec1Options?.length - 1].value : '',
                    "sortorder": sec2Options?.length > 0 ? sec2Options[sec2Options?.length - 1].value : 'asc',
                    channel: channelName
                }
            ))
        } else {
            dispatch(getSearchPosts(
                {
                    "query": queryVal,
                    "page": 1,
                    channel: channelName
                }
            ))
        }


    };


    const handleChangeSearch = (event) => {
        dispatch(getSearchPosts({
            page: 1,
            query: event,

            channel: channelName,
            "column_name": selectedOption?.length > 0 ? selectedOption[0].value : '',
            "sortorder": selectedOption?.length > 1 ? selectedOption[1].value : 'asc'
        }))
        setQueryVal(event)
    }

    const handleChangeChannel = (val, id, type) => {

        const updatedFilters = [...columns];

        // if (id == 0) {
        if (type == 'amazon') {
            updatedFilters[id].sort['amazon'] = val;
            
            updatedFilters[id].sort['instagram'] = false;
            updatedFilters[id].sort['twitter'] = false;
            updatedFilters[id].sort['flipkart'] = false;
            updatedFilters[id].sort['facebook'] = false;
            updatedFilters[id].sort['news'] = false;
            updatedFilters[id].sort['all'] = false;
        }else  if (type == 'instagram') {
            updatedFilters[id].sort['amazon'] = false;
            
            updatedFilters[id].sort['news'] = false;
            updatedFilters[id].sort['instagram'] = val;
            updatedFilters[id].sort['twitter'] = false;
            updatedFilters[id].sort['flipkart'] = false;
            updatedFilters[id].sort['facebook'] = false;
            updatedFilters[id].sort['all'] = false;
        }else if (type == 'facebook') {
            updatedFilters[id].sort['amazon'] = false;

            updatedFilters[id].sort['news'] = false;
            updatedFilters[id].sort['instagram'] = false;
            updatedFilters[id].sort['twitter'] = false;
            updatedFilters[id].sort['flipkart'] = false;
            updatedFilters[id].sort['facebook'] = val;
            updatedFilters[id].sort['all'] = false;

        }else if (type == 'news') {
            updatedFilters[id].sort['amazon'] = false;

            updatedFilters[id].sort['news'] = val;
            updatedFilters[id].sort['instagram'] = false;
            updatedFilters[id].sort['twitter'] = false;
            updatedFilters[id].sort['flipkart'] = false;
            updatedFilters[id].sort['facebook'] = false;
            updatedFilters[id].sort['all'] = false;

        } else if (type == 'twitter') {
            updatedFilters[id].sort['amazon'] = false;
            updatedFilters[id].sort['instagram'] = false;

            updatedFilters[id].sort['news'] = false;
            updatedFilters[id].sort['facebook'] = false;
            updatedFilters[id].sort['twitter'] = val;
            updatedFilters[id].sort['flipkart'] = false;
            updatedFilters[id].sort['all'] = false;

        } else if (type == 'flipkart') {

            updatedFilters[id].sort['news'] = false;
            updatedFilters[id].sort['instagram'] = false;
            updatedFilters[id].sort['amazon'] = false;

            updatedFilters[id].sort['facebook'] = false;
            updatedFilters[id].sort['twitter'] = false;
            updatedFilters[id].sort['flipkart'] = val;
            updatedFilters[id].sort['all'] = false;
        } else {
            updatedFilters[id].sort['amazon'] = false;

            updatedFilters[id].sort['news'] = false;
            updatedFilters[id].sort['facebook'] = false;
            updatedFilters[id].sort['instagram'] = false;
            updatedFilters[id].sort['twitter'] = false;
            updatedFilters[id].sort['flipkart'] = false;
            updatedFilters[id].sort['all'] = val;

        }
        // }else{
        //     updatedFilters[id].sort[type] = val;
        // }


        dispatch(getSearchPosts(
            {
                page: 1,
                query: queryVal,

                channel: type == 'all' ? null : type,

                "column_name": selectedOption?.length > 0 ? selectedOption[0].value : '',
                "sortorder": selectedOption?.length > 1 ? selectedOption[1].value : 'asc',
            }))
        setChannelName(type == 'all' ? null : type)

        setColumns([...updatedFilters])
    }


    return (
        <Wrapper>
            <SearchWrapper>
                {/* <div className='dateIcon'>{date_icon}</div> */}
                <div className='searchInput'>
                    <SearchBar placeholder='Search reports' height='40px' onChange={(e) => handleChangeSearch(e.target.value)} />

                </div>

                <div>
                    <Dropdown
                        options={dropDownOptions}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        handleSelectChange={handleSelectChange}
                    />

                </div>
            </SearchWrapper>

            <CustomTableContainer showsplit={`${showSplit}`} sx={{}}>

                <StyledTable stickyHeader aria-label="sticky table">

                    <StyledTableHeader>
                        <TableRow >
                            {columns.map((column, i) => (
                                <CustomTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ width: column.width }}
                                    // onClick={() => handleSetId(i)}
                                    // onMouseLeave={(e) => handleSetId(-1)}

                                    onMouseOver={(e) => handleSetId(i)}
                                    onMouseLeave={(e) => handleSetId(-1)}
                                >
                                    {
                                        column.id == 'Channel' ?
                                            <TableHeaderFlexWrapper style={{ margin: '0' }}>{column.label}&nbsp;{sort_icon}</TableHeaderFlexWrapper>
                                            :
                                            <TableHeaderFlexWrapper style={{ margin: '0' }}>{column.label}</TableHeaderFlexWrapper>


                                    }
                                    {
                                        setId === i && (
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                position:'fixed',
                                                marginTop:'10px'
                                                // left: '55%'
                                            }}>

                                                {

                                                    setId == 0 &&
                                                    <DropDownWrapper style={{ position: 'absolute' }}>
                                                        <DropDownContentDiv ><InputDropDownCheckBox type='radio' checked={column.sort.twitter} onClick={(e) => handleChangeChannel(e.target.checked, 0, 'twitter')} />{X_LOGO}</DropDownContentDiv>
                                                        <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.amazon} onClick={(e) => handleChangeChannel(e.target.checked, 0, 'amazon')} />Amazon</DropDownContentDiv>
                                                        <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.facebook} onClick={(e) => handleChangeChannel(e.target.checked, 0, 'facebook')} />Facebook</DropDownContentDiv>
                                                        <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.instagram} onClick={(e) => handleChangeChannel(e.target.checked, 0, 'instagram')} />Instagram</DropDownContentDiv>

                                                        <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.news} onClick={(e) => handleChangeChannel(e.target.checked, 0, 'news')} />News</DropDownContentDiv>
                                                        <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.flipkart} onClick={(e) => handleChangeChannel(e.target.checked, 0, 'flipkart')} />Flipkart</DropDownContentDiv>
                                                        <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.all} onClick={(e) => handleChangeChannel(e.target.checked, 0, 'all')} />All</DropDownContentDiv>
                                                        {/* <DropDownContentDiv><InputDropDownCheckBox type='checkbox' style={{ height: '14px',marginRight:'10px' }} /> Z - A</div> */}

                                                    </DropDownWrapper>




                                                }
                                            </div>
                                        )
                                    }
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </StyledTableHeader>
                    <TableBody style={{minHeight:'300px',height:getAllPostsData?.length > 0 ? '100%' : '300px'}}>


                        {getAllPostsData?.length > 0 && getAllPostsData?.map((column, index) => (
                            <CustomTableRow onClick={() => handleOpenPopup(column, index)} key={index}>
                                <TableCell>
                                    {column.channel}
                                </TableCell>
                                <TableCell>
                                    {column.date_and_time}
                                </TableCell>
                                <TableCellEllipsis>
                                    <EllipsisText>
                                        {column.posts}
                                    </EllipsisText>
                                </TableCellEllipsis>
                                <TableCell>
                                    {column.title}
                                </TableCell>
                                <TableCell>
                                    {column.name}
                                </TableCell>
                            </CustomTableRow>
                        ))}
                    </TableBody>

                </StyledTable>
                <Pagination
                    currentPage={pageNumber}
                    totalCount={total_count}
                    pageSize={PageSize}
                    onPageChange={(page) => handleChangePageNumber(page)}
                />
            </CustomTableContainer>
            {
                showSplit ?
                    popUpData && popUpData != null && popUpData != undefined &&
                    <PostsSlpitScreen
                        setShowSplit={setShowSplit}
                        popUpData={popUpData}
                    />
                    :
                    <></>
            }




        </Wrapper >
    );
};


export default PostsReport;