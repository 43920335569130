import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { debounce } from 'lodash';
import { FixedSizeList as List } from 'react-window';

const VirtualizedSelect = ({ options, setValue }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFilteredOptions(options.slice(0, 20));
    }, [options]);

    const handleChange = (selectedOption) => {
        setValue(selectedOption?.value || '')
        setSelectedOption(selectedOption);

    };

    const handleInputChange = (inputValue) => {
        setLoading(true);
        debouncedSearch(inputValue);
    };

    // Debounced search function
    const debouncedSearch = debounce((inputValue) => {
        setLoading(true);
        const filtered = options.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredOptions(filtered);
        setLoading(false);
    }, 500);

    const MenuList = (props) => {
        const { children, maxHeight, getValue } = props;

        return (
            <div style={{ maxHeight }}>
                <List
                    height={maxHeight}
                    itemCount={filteredOptions.length}
                    itemSize={35}
                >
                    {({ index, style }) => {
                        const option = filteredOptions[index];
                        return option ? (
                            <div
                                style={{
                                    ...style,
                                    padding: '10px',
                                    overflow: 'hidden',
                                    cursor: 'pointer',
                                    width: '100%',
                                }}
                                onClick={() => {
                                    setValue(selectedOption?.value || '')
                                    setSelectedOption(option); // Select the option
                                    props.setValue(option); // Update react-select value
                                    props.selectOption(option); // Trigger the select option callback
                                }}
                            >
                                {option.label}
                            </div>
                        ) : null;
                    }}
                </List>
            </div>
        );
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '5px 10px',
            borderRadius: '15px',
            minHeight: '50px',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#fff',
            borderRadius: '4px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#e9ecef' : '#fff',
            color: state.isSelected ? '#fff' : '#333',
            padding: '8px 12px',
            cursor: 'pointer',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#007bff',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    return (
        <Select
            value={selectedOption}
            onChange={handleChange}
            options={filteredOptions.slice(0, 20)}
            onInputChange={handleInputChange}
            isSearchable
            components={{ MenuList }}
            cacheOptions
            defaultOptions={filteredOptions.slice(0, 20)}
            isLoading={loading}
            placeholder={'Select the Country'}
            styles={customStyles}
        />
    );
};

export default VirtualizedSelect;
