import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ActiveGlass } from '../../../assests/images/LandingAssets/ActiveGlass.svg';
import { ReactComponent as BulletEllipse } from '../../../assests/images/LandingAssets/BulletEllipse.svg';
import { ReactComponent as InActiveGlass } from '../../../assests/images/LandingAssets/InactiveGlass.svg';
import { ReactComponent as ActiveAssess } from '../../../assests/images/LandingAssets/ActiveAssess.svg';
import { ReactComponent as InActiveAssess } from '../../../assests/images/LandingAssets/InActiveAssess.svg';
import { ReactComponent as ActiveBenchMark } from '../../../assests/images/LandingAssets/ActiveBenchMark.svg';
import { ReactComponent as InActiveBenchMark } from '../../../assests/images/LandingAssets/InActiveBenchMark.svg';
import { ReactComponent as ActiveForesee } from '../../../assests/images/LandingAssets/ActiveForesee.svg';
import { ReactComponent as InActiveForesee } from '../../../assests/images/LandingAssets/InActiveForesee.svg';
import { ReactComponent as ActiveBond } from '../../../assests/images/LandingAssets/ActiveBond.svg';
import { ReactComponent as InActiveBond } from '../../../assests/images/LandingAssets/InActiveBond.svg';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};
const Wrapper = styled.div`
  padding-left: 120px;
  padding-right: 120px;
  @media only screen and ${device.tablet} {
    padding: 10px 24px;
  }
`;
const Container = styled.div`
  width: 100%;
  border-radius: 20px;
  margin-top: 92px;
  @media only screen and ${device.tablet} {
    margin-top: 60px;
  }
`;

const TabBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @media only screen and ${device.tablet} {
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 10px;
    justify-content: flex-start;
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  padding-right: 45px;
  padding-bottom: 15px;
  color: ${(props) => (props.active ? '#FFCC00' : '#ebebeb')};
  cursor: pointer;
  background: none;
  border-bottom: ${(props) => (props.active ? '1px solid #151515' : 'none')};
  svg {
    font-size: 24px;
    margin-bottom: 5px;
    color: ${(props) => (props.active ? '#FFCC00' : '#ebebeb')};
  }
  @media only screen and ${device.tablet} {
    flex-wrap: wrap;
    border-bottom: none;
    padding-bottom: unset;
    border-radius: 5px;
    border: 1px solid #151515;
    padding: 5px 10px;
    background: ${(props) => (props.active ? '#fbbc05' : '#D4D4D4')};
    svg {
      width: 20px;
      height: 20px;
      margin-top: 7px;
      align-items: center;
      justify-content: center;
    }
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(99deg, #5937c8 0%, #391c99 100.01%);
  padding: 50px;
  height: 508px;
  border-radius: 20px;
  @media only screen and ${device.tablet} {
    padding: 15px 0px;
    height: unset;
    flex-direction: column;
  }
`;

const TabText = styled.div`
  color: #151515;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-left: 15px;
  line-height: normal;
  @media only screen and ${device.tablet} {
    font-size: 16px;
  }
`;
const TextSection = styled.div`
  padding: 50px;
  @media only screen and ${device.tablet} {
    padding: 15px 24px;
  }
`;

const Title = styled.div`
  color: #e8e8e8;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
`;

const SubTitle = styled.h2`
  color: #fbbc05;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  @media only screen and ${device.tablet} {
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const Description = styled.p`
  color: rgba(232, 232, 232, 0.7);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  max-width: 453px;
  @media only screen and ${device.tablet} {
    margin-top: 5px;
  }
`;

const BulletPoints = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 50px;
`;

const BulletPoint = styled.li`
  color: #fafafa;
  flex-shrink: 0;
  font-family: Inter;
  margin-top: 5px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  text-align: left;
  & > div {
    margin-left: 15px;
  }

  @media only screen and ${device.tablet} {
    font-size: 18px;
    align-items: center;
    & svg {
      width: 14px;
      height: 14px;
    }
    & > div {
      margin-left: 10px;
    }
  }
`;

const ImageSection = styled.div``;

const TitleText = styled.div`
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  text-align: left;
  width: 175px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fbbc05;
  color: #151515;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  @media only screen and ${device.tablet} {
    width: 115px;
    font-size: 12px;
  }
`;

const HeaderText = styled.div`
  color: #391c99;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  margin-top: 50px;
  @media only screen and ${device.tablet} {
    font-size: 32px;
    margin-top: 30px;
  }
`;

const HeaderDesc = styled.div`
  color: #151515;
  font-family: Inter;
  font-size: 24px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 672px;
  @media only screen and ${device.tablet} {
    font-size: 15px;
    margin-top: 15px;
    width: 100%;
  }
`;
const ContentText = styled.div`
  width: 320px;
  margin-top: 1px;
  @media only screen and ${device.tablet} {
    width: unset;
    margin-top: unset;
  }
`;
const EmotionalEvolutionTabs = () => {
  const content = [
    {
      titleText: 'STAGE 1',
      subTitleText: 'Uncover',
      description:
        'Lay the foundation by discovering emotional triggers behind conversations about your brand, industry, and competitors.',
      points: ['Analyze Trending Discussions', 'Platform Analytics'],
      ImageComp: require('../../../assests/images/LandingAssets/Uncover.png'),
    },
    {
      titleText: 'STAGE 2',
      subTitleText: 'Assess',
      description:
        'Stay ahead by having a bird’s eye view on emotions and sentiments in real time across platforms and touchpoints​',
      points: [
        'Sentiment Analysis',
        'Gender Demographics Analysis',
        'E-commerce Sentiment Analysis',
      ],
      ImageComp: require('../../../assests/images/LandingAssets/Assess.png'),
    },
    {
      titleText: 'STAGE 3',
      subTitleText: 'Benchmark',
      description:
        'Outpace competitors by leveraging emotional intelligence to uncover gaps ​ & develop smarter strategies.​',
      points: [
        'Competitor Intelligence',
        'Custom Analysis',
        'Hashtag Analysis',
        'Influencer Recognition',
        'Advanced Correlation Analysis',
      ],
      ImageComp: require('../../../assests/images/LandingAssets/Benchmark.png'),
    },
    {
      titleText: 'STAGE 4',
      subTitleText: 'Foresee',
      description:
        'Anticipate what’s next by leveraging AI to predict emotional shifts and market trends.​',
      points: [
        'Sentiment Insights (Drill Down)',
        'Geographical Demographics Analysis',
        'Emotional Analysis​',
        'Cluster Analysis​',
        'GEN AI Assistant​ ',
      ],
      ImageComp: require('../../../assests/images/LandingAssets/Foresee.png'),
    },
    {
      titleText: 'STAGE 5',
      subTitleText: 'Bond',
      description:
        'Achieve brand loyalty by forging deep, meaningful campaigns with your audience.',
      points: ['Campaign Analysis (Word Cloud)', 'Sentinel Hub​'],
      ImageComp: require('../../../assests/images/LandingAssets/Bond.png'),
    },
  ];
  const [active, setActive] = useState(0);
  const [prevActive, setPrevActive] = useState(0);
  return (
    <Wrapper>
      <TitleText>What do we do?</TitleText>
      <HeaderText>The 5 Stages of Emotional Evolution</HeaderText>
      <HeaderDesc>
        Chart your brand’s journey from understanding emotions to building
        unshakable loyalty.
      </HeaderDesc>
      <Container>
        <TabBar>
          <Tab
            active={window?.innerWidth > 768 ? true : active === 0}
            onClick={() => {
              setActive(0);
            }}
            // onMouseEnter={() => {
            //   if (window?.innerWidth > 768) {
            //     setPrevActive(active);
            //     setActive(0);
            //   }
            // }}
            // onMouseLeave={() => {
            //   if (window?.innerWidth > 768) {
            //     setActive(prevActive);
            //   }
            // }}
          >
            {active === 0 ? <ActiveGlass /> : <InActiveGlass color='black' />}
            <TabText>Uncover</TabText>
          </Tab>
          <Tab
            onClick={() => {
              setActive(1);
            }}
            // onMouseEnter={() => {
            //   if (window?.innerWidth > 768) {
            //     setPrevActive(active);
            //     setActive(1);
            //   }
            // }}
            // onMouseLeave={() => {
            //   if (window?.innerWidth > 768) {
            //     setActive(prevActive);
            //   }
            // }}
            active={window?.innerWidth > 768 ? active > 0 : active === 1}
          >
            {active === 1 ? <ActiveAssess /> : <InActiveAssess />}

            <TabText>Assess</TabText>
          </Tab>
          <Tab
            active={window?.innerWidth > 768 ? active > 1 : active === 2}
            onClick={() => {
              setActive(2);
            }}
            // onMouseEnter={() => {
            //   if (window?.innerWidth > 768) {
            //     setPrevActive(active);
            //     setActive(2);
            //   }
            // }}
            // onMouseLeave={() => {
            //   if (window?.innerWidth > 768) {
            //     setActive(prevActive);
            //   }
            // }}
          >
            {active === 2 ? <ActiveBenchMark /> : <InActiveBenchMark />}

            <TabText>Benchmark</TabText>
          </Tab>
          <Tab
            active={window?.innerWidth > 768 ? active > 2 : active === 3}
            onClick={() => {
              setActive(3);
            }}
            // onMouseEnter={() => {
            //   if (window?.innerWidth > 768) {
            //     setPrevActive(active);
            //     setActive(3);
            //   }
            // }}
            // onMouseLeave={() => {
            //   if (window?.innerWidth > 768) {
            //     setActive(prevActive);
            //   }
            // }}
          >
            {active === 3 ? <ActiveForesee /> : <InActiveForesee />}

            <TabText>Foresee</TabText>
          </Tab>
          <Tab
            active={window?.innerWidth > 768 ? active > 3 : active === 4}
            onClick={() => {
              setActive(4);
            }}
            // onMouseEnter={() => {
            //   if (window?.innerWidth > 768) {
            //     setPrevActive(active);
            //     setActive(4);
            //   }
            // }}
            // onMouseLeave={() => {
            //   if (window?.innerWidth > 768) {
            //     setActive(prevActive);
            //   }
            // }}
          >
            {active === 4 ? <ActiveBond /> : <InActiveBond />}

            <TabText>Bond</TabText>
          </Tab>
        </TabBar>

        <Content>
          <TextSection>
            <Title>{content[active]?.titleText}</Title>
            <SubTitle>{content[active]?.subTitleText}</SubTitle>
            <Description>{content[active]?.description}</Description>
            <BulletPoints>
              {content[active]?.points?.map((data, index) => {
                return (
                  <BulletPoint key={index}>
                    <BulletEllipse />
                    <ContentText>{data}</ContentText>
                  </BulletPoint>
                );
              })}
            </BulletPoints>
          </TextSection>
          <ImageSection>
            <img
              src={content[active]?.ImageComp}
              width={window?.innerWidth < 728 ? '302px' : '510px'}
            />
          </ImageSection>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default EmotionalEvolutionTabs;
