
import React, { useRef, useSelector, useState } from 'react';
// import ViewReport from './ViewReport';




// import React, { useState } from 'react';
import styled from 'styled-components';
import {
  download_icon,
  report_icon,
} from '../../Common/SvgIcon/CommonSvgIcons';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PopUp from '../../Common/PopUp/PopUp';
import ViewReport from './ViewReport';
import ViewReportsChatter from './ViewReportsChatter';
import ViewCompetitive from '../CompetitiveIntelligence/viewCompetitive';
import DownladInsights from './DownladInsights';
import html2pdf from 'html2pdf.js';

const Wrapper = styled.div`
  border-left: 1px solid rgba(99, 99, 99, 0.2);
  text-align: left;
  padding: 0 20px;
  padding-bottom: 100px;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #353cf7;
    border-radius: 10px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
`;

const Subject = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 20px 0;
`;
const DownloadButton = styled.button`
  border-radius: 10px;
  background: #353cf7;
  color: #fff;

  font-size: 20px;
  font-weight: 600;

  padding: 5px 15px;
  border: none;

  display: flex;
  justify=-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

const MailPreview = styled.div`
  border-radius: 10px;
  background: rgba(174, 177, 252, 0.16);
  min-height: 70%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;

  color: #000;
  font-size: 24px;
  font-weight: 600;

  & > span {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 400;
  }
`;

const OpenButton = styled(DownloadButton)`
  font-size: 15px;
  padding: 9px 50px;
`;

const ReportWrapper = styled.div`
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #a2a2a2;
  position: relative;

  .fullScreenIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 20;
    cursor: pointer;
  }
`;

const PopupReportWrapper = styled.div`
  overflow: scroll !important;
  width: 100%;
`;



const ReportsModalInsights = ({
  splitData,
  formatValue, data, open, handleClose }) => {

    const [loading,setLoading] = useState(false)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,

  };


  const contentDivRef = useRef(null);

  const handleGeneratePdf = () => {
    setLoading(true)
    const element = document.getElementById('element-to-convert-insights');
    // console.log('started', splitData?.competitive_intelligence_outlook_id);

    const options = {
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' }
    };
    setTimeout(() => {


      // Call the html2pdf function with the selected element and options
      html2pdf().from(element).set(options).save();

      // setDownload(false)
      setLoading(false)
    }, 3000); // 3000 milliseconds = 3 seconds

  }

  return (
    <div>
      onClose={() => handleClose()}
      <PopUp >
        <PopupReportWrapper>
          <ViewCompetitive downloadFeature={true} loading={loading} shareReport={true} ci_id={splitData?.competitive_intelligence_outlook_id || 0} showReportPopup={true} handleCancelClick={handleClose} handleGeneratePdf={handleGeneratePdf} />

          <div style={{ visibility: 'hidden', position: 'absolute', top: '-9999px' }}>
            {/* <div style={{ display: 'none' }}> */}
            <div ref={contentDivRef} id="element-to-convert-insights">
              <DownladInsights
                ci_id={splitData?.competitive_intelligence_outlook_id || 0}
              // email_id={data?.email_id + ''}
              // created_at={data.creation_time}
              // is_topic={data.is_topic}
              // data={data}

              // formatValue={formatValue}
              />
            </div>
          </div>
        </PopupReportWrapper>

      </PopUp>

    </div>
  );
};


export default ReportsModalInsights;