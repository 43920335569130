import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import TitleWithInfo from '../TitleWithInfo/TitleWithInfo';

const GraphWrapper = styled.div`
  width: 48%;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid #d9d9d9;

  padding: 20px;
  padding-bottom: 10px;
  //margin-left: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  ${'' /* color: #000000; */}
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
`;
const GraphHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & > div:last-child {
    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;

    padding: 2px;
  }
`;

const GraphBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GraphLabel = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;

  & > div {
    color: #636363;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;

    & > span {
      display: inline-block;
      width: 15px;
      height: 15px;
      background: red;
      margin-right: 5px;
      border-radius: 2px 0px 0px 2px;
    }

    & .verified {
      background: #5fe495;
    }

    & .nonVerified {
      background: #6f62fe;
    }
  }
`;

const DateRange = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;

  text-align: right;
  margin-top: 10px;
`;

const EachRow = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  justify-items: center;
  gap: 10px;
  font-family: Open Sans;
`;

const Star = styled.div`
  color: #636363;
  font-size: 14px;
  font-weight: 400;
  text-align:end;
  word-break:break-word;
`;

const PercentageBar = styled.div`
  font-size: 15px;
  font-weight: 400;
  flex: 1;

  & > div {
    width: 100%;
    border-radius: 2px;
    display: flex;

    &:before {
      content: '';
      border-radius: 2px;
      margin-right: 5px;
      min-width: 2px;
    }
  }
`;

const VerifiedBar = styled.div`
  &:before {
    width: ${(props) => props?.children[0]}%;
    background: #5fe495;
  }
`;

const NonVerifiedBar = styled.div`
  margin-top: 2px;
  &:before {
    width: ${(props) => props?.children[0]}%;
    background: #6f62fe;
  }
`;

const VerifiedAndNonVerifiedUsers = ({ infoText=' ' ,title = 'Verified and Non-Verified Reviews', data, startDate, endDate, Width = 48, graphStyles, Contents, dontShowStar,

formatValue="dd-yyyy-MM"
 }) => {

  let graphData = [];
  if (data && data.length > 0) {
    graphData = [...data];
    graphData.sort((a, b) => b.verified - a.verified);
  }
  return (
    <GraphWrapper style={{ width: `${Width}%`, gap: '25px' }}>
      <GraphHeader>
        
      <Title style={{ color: 'black', fontWeight: 500 }}>
        <TitleWithInfo
              title={title}
              infoText={infoText}
            /></Title>
        {/* <Title>{title}</Title> */}
        {/* <div>Bar Graph</div> */}
      </GraphHeader>
      {/* <GraphBarContainer style={graphStyles}> */}

      <GraphBarContainer style={graphStyles}>
        {graphData?.map((el) => (
          <EachRow key={el.rating || el?.user_name} style={{
            display: Contents
          }}>
            <Star>{el?.rating || el?.user_name} {dontShowStar ? '' : 'Star'}</Star>
            <PercentageBar>
              <VerifiedBar>{el?.verified || el?.engagement}{dontShowStar ? '' : '%'}</VerifiedBar>
              <NonVerifiedBar>{el?.nonverified || el?.mentions}{dontShowStar ? '' : '%'}</NonVerifiedBar>
            </PercentageBar>
          </EachRow>
        ))}
      </GraphBarContainer>
      <GraphLabel>
        <div>
          <span className='verified'></span>{dontShowStar ? 'Engagement' : 'Verified'}
        </div>
        <div>
          <span className='nonVerified'></span>{dontShowStar ? 'Active Posts' : 'Non Verified'}
        </div>
      </GraphLabel>
      <DateRange>
      This data is observed from {startDate } to {endDate }
        
        {/* {`This data is observed from ${startDate} to ${endDate}`} */}
        </DateRange>
    </GraphWrapper>
  );
};

export default VerifiedAndNonVerifiedUsers;
