import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../store/redux/slices/alertMessageSlice';
import './RequestDemoForm.css';
import { countries } from './data';

const RequestDemoForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    country: '',
    companySize: '',
    isMarketingAgency: '',
    notes: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const scriptURL =
      'https://script.google.com/macros/s/AKfycbzy04U8mkrN12UNyGqgIiRo-n0MtuJE1tN3wtv7zq0TVgYXeh6-J0q6Y5RFVfYakxYL/exec';

    try {
      await fetch(scriptURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'no-cors',
        body: JSON.stringify(formData),
      });
      window.scrollTo(0, 0);

      // alert('Submitted the Form');
      dispatch(
        setMessage({
          message: 'Submitted the request successfully!!',
          type: 'Success',
        })
      );

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        country: '',
        companySize: '',
        isMarketingAgency: '',
        notes: '',
      });
    } catch (error) {
      alert(JSON.stringify(error));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='demo-request-container'>
      <div className='info-panel'>
        <h1>Request your personalized demo</h1>
        <ul>
          <li>Assess your Brand's emotional GPS</li>
          <li>See a live product demo</li>
          <li>Determine your best path forward</li>
        </ul>
      </div>
      <form className='form-panel' onSubmit={handleSubmit}>
        <label>
          First Name
          <input
            type='text'
            name='firstName'
            value={formData.firstName}
            onChange={handleChange}
            required
            pattern='[A-Za-z]+'
            title='Please enter a valid input.'
          />
        </label>
        <label>
          Last Name
          <input
            type='text'
            name='lastName'
            value={formData.lastName}
            onChange={handleChange}
            required
            pattern='[A-Za-z]+'
            title='Please enter a valid input.'
          />
        </label>
        <label>
          Business Email
          <input
            type='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Phone Number
          <input
            type='tel'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            required
            pattern='[0-9]{10,14}'
            title='Please enter a valid input.'
          />
        </label>
        <label>
          Company Name
          <input
            type='text'
            name='company'
            value={formData.company}
            onChange={handleChange}
            required
            pattern='^[A-Za-z@&]+(\s[A-Za-z@&]+)*$'
            title='Please enter a valid input.'
          />
        </label>
        <label>
          Country
          <select
            name='country'
            value={formData.country}
            onChange={handleChange}
            required
          >
            <option value='' disabled>
              Select your country
            </option>
            {countries.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
        </label>
        <label>
          Company Size
          <input
            type='number'
            name='companySize'
            value={formData.companySize}
            onChange={handleChange}
            required
            min='1'
            title='Please enter a valid input.'
          />
        </label>
        <label>
          Do you work at a marketing agency?
          <div style={{ display: 'flex', marginTop: '9px' }}>
            <label>
              <input
                type='radio'
                name='isMarketingAgency'
                value='Yes'
                checked={formData.isMarketingAgency === 'Yes'}
                onChange={handleChange}
                required
              />
            </label>
            <div style={{ marginLeft: '10px' }}>Yes</div>
            <label style={{ marginLeft: '20px' }}>
              <input
                type='radio'
                name='isMarketingAgency'
                value='No'
                checked={formData.isMarketingAgency === 'No'}
                onChange={handleChange}
              />
            </label>
            <div style={{ marginLeft: '10px' }}>No</div>
          </div>
        </label>
        <label>
          Notes (optional)
          <input name='notes' value={formData.notes} onChange={handleChange} />
        </label>
        <button type='submit'>Submit My Request</button>
      </form>
    </div>
  );
};

export default RequestDemoForm;
