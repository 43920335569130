import React, { useState } from 'react';
import styled from 'styled-components';
import { DownArrowIcon } from '../SvgIcon/CommonSvgIcons';
const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.div`
  display: flex;
  min-width: 120px;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  padding: 5px 8px;
  color: #636363;
  font-family: Open Sans;
  font-weight: 400;
  cursor: pointer;
  position: relative;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.22);
  z-index: 10;
`;

const Option = styled.label`
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  color: #636363;
  &:hover {
    background: #6f62fe;
    color: #fff;
  }
  input {
    margin-right: 10px;
    cursor: pointer;
  }
`;

const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none; 
  -webkit-appearance: none;
  -moz-appearance: none; 
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  position: relative;
  &:checked {
    background: white; 
    border-color: #6f62fe; 
  }
  &:checked::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 3px;
    width: 7px;
    height: 4px;
    border: solid #6f62fe;
    border-width: 0 2px 2px 0;
    transform: rotate(140deg) scaleY(-1);
    display: inline-block;
  }
`;
const WordCloudDropDown = ({ onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(['keywords']);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    setSelectedItems((prev) =>
      checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
    onSelect(selectedItems);
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        <span>
          {selectedItems[0]
            ? selectedItems[0]?.charAt(0)?.toUpperCase() +
              selectedItems[0]?.slice(1)
            : 'Keywords'}
        </span>
        <span>{DownArrowIcon}</span>
      </DropdownButton>

      {isOpen && (
        <DropdownMenu>
          <Option>
            <CustomCheckbox
              id='keywords'
              checked={selectedItems.includes('keywords')}
              onChange={handleCheckboxChange}
            />
            Keywords
          </Option>
          <Option>
            <CustomCheckbox
              id='hashtags'
              checked={selectedItems.includes('hashtags')}
              onChange={handleCheckboxChange}
            />
            Hashtags
          </Option>
          <Option>
            <CustomCheckbox
              id='mentions'
              checked={selectedItems.includes('mentions')}
              onChange={handleCheckboxChange}
            />
            Mentions
          </Option>
          <Option>
            <CustomCheckbox
              id='emojis'
              checked={selectedItems.includes('emojis')}
              onChange={handleCheckboxChange}
            />
            Emojis
          </Option>
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};

export default WordCloudDropDown;
