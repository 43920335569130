import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LandingHeader from '../../components/LandingPageComponents/LandingHeader/LandingHeader';
import 'typeface-inter';
import LandingFooter from '../../components/LandingPageComponents/LandingFooter/LandingFooter';
import ListenObserveActBanner from '../../components/LandingPageComponents/ListenObserveActBanner/ListenObserveActBanner';
import WhoIsBrandAstraForBanner from '../../components/LandingPageComponents/WhoIsBrandAstraForBanner/WhoIsBrandAstraForBanner';
import DataPointsBanner from '../../components/LandingPageComponents/DataPointsBanner/DataPointsBanner';
import BookaDemoBanner from '../../components/LandingPageComponents/BookaDemoBanner/BookaDemoBanner';
import FindAPlanCards from '../../components/LandingPageComponents/FindAPlanCards/FindAPlanCards';
import PlatformIntegrationTicker from '../../components/LandingPageComponents/PlatformintegrationTicker/PlatformIntegrationTicker';
import EmotionalEvolutionTabs from '../../components/LandingPageComponents/EmotionalEvolutionTabs/EmotionalEvolutionTabs';
import CustomerReviewsCarousal from '../../components/LandingPageComponents/CustomerReviewsCarousal/CustomerReviewsCarousal';
import BrandAstraBanner from '../../components/LandingPageComponents/BrandAstraBanner/BrandAstraBanner';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-y: hidden;
  background: #ebebeb;
  @media only screen and (max-width: 768px) {
    max-height: ${(props) => (props.menuOpen ? '100vh' : 'unset')};
  }
`;
const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  background: #ebebeb;
  z-index: 9;
  @media only screen and (max-width: 768px) {
    position: unset;
  }
`;

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Wrapper>
      <HeaderWrapper>
        <LandingHeader
          setMenuOpen={(data) => {
            setMenuOpen(data);
          }}
        />
      </HeaderWrapper>
      <BrandAstraBanner />
      <DataPointsBanner />
      <ListenObserveActBanner />
      <PlatformIntegrationTicker />
      <div id='what-do-we-do'>
        <EmotionalEvolutionTabs />
      </div>
      <div id='solutions'>
        <WhoIsBrandAstraForBanner />
      </div>
      <CustomerReviewsCarousal />
      <div id='pricing'>
        <FindAPlanCards />
      </div>
      <BookaDemoBanner />
      <LandingFooter />
    </Wrapper>
  );
};

export default LandingPage;
