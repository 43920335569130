import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './alertMessageSlice';
import AnalysisService from '../services/competitiveIntelligence.service';

// Thunk for creating a new analysis
export const createNewAnalysis = createAsyncThunk(
  'competitiveIntelligence/createNewAnalysis',
  async (inputData, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await AnalysisService.createNewAnalysis(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        inputData
      );
      return { data };
    } catch (error) {
      const message =
        (error.response?.data?.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const competitiveIntelligenceDelete = createAsyncThunk(
  'competitiveIntelligence/competitiveIntelligenceDelete',
  async (jobId, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const response = await AnalysisService.competitiveIntelligenceDelete(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id,
        jobId
        
      );
      thunkAPI.dispatch(setMessage('Report is deleted successfully.'));
      return { response };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Thunk for fetching topic list
export const competitiveIntelligenceListTopic = createAsyncThunk(
  'competitiveIntelligence/competitiveIntelligenceListTopic',
  async (_, thunkAPI) => {  // Removed unused inputData parameter
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await AnalysisService.competitiveIntelligenceListTopic(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response?.data?.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Thunk for fetching chatter list
export const competitiveIntelligenceListChatter = createAsyncThunk(
  'competitiveIntelligence/competitiveIntelligenceListChatter',
  async (_, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await AnalysisService.competitiveIntelligenceListChatter(
        brandUser?.tenant_id,
        brandUser?.user_details.user_id
      );
      return { data };
    } catch (error) {
      const message =
        (error.response?.data?.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Thunk for generating report
export const competitiveIntelligenceShareReport = createAsyncThunk(
  'competitiveIntelligence/competitiveIntelligenceShareReport',
  async (cid, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await AnalysisService.competitiveIntelligenceShareReport(
        brandUser?.tenant_id,
        cid
      );
      return { data };
    } catch (error) {
      const message =
        (error.response?.data?.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Thunk for generating report
export const competitiveIntelligenceReport = createAsyncThunk(
  'competitiveIntelligence/competitiveIntelligenceReport',
  async (cid, thunkAPI) => {
    try {
      const brandUser = JSON.parse(localStorage.getItem('brandUser'));
      const data = await AnalysisService.competitiveIntelligenceReport(
        brandUser?.tenant_id,
        cid
      );
      return { data };
    } catch (error) {
      const message =
        (error.response?.data?.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  ciPreviewData: null,
  CIListTopic:[],
  CIListChatter:[],
  error: null,  // Added error state
};

// Create competitive intelligence slice
const competitiveIntelligenceSlice = createSlice({
  name: 'competitiveIntelligence',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewAnalysis.fulfilled, (state, action) => {
        // console.log(action.payload, "action.payload in ci");
        state.ciPreviewData = action?.payload?.data?.competitive_intelligence_id || null;
        state.error = null;  // Clear error on success
      })
      .addCase(createNewAnalysis.rejected, (state, action) => {
        state.ciPreviewData = null;
        state.error = action.payload || 'Failed to create analysis';  // Store error message
      })
      .addCase(competitiveIntelligenceDelete.fulfilled, (state, action) => {
        // Handle successful topic fetching
        state.error = null;
      })
      .addCase(competitiveIntelligenceDelete.rejected, (state, action) => {
        state.error = action.payload || 'Failed to fetch topics';
      })
      .addCase(competitiveIntelligenceListTopic.fulfilled, (state, action) => {
        // Handle successful topic fetching
        
        state.CIListTopic = action?.payload?.data;
        state.error = null;
      })
      .addCase(competitiveIntelligenceListTopic.rejected, (state, action) => {
        state.error = action.payload || 'Failed to fetch topics';
      })
      .addCase(competitiveIntelligenceListChatter.fulfilled, (state, action) => {
        // Handle successful chatter fetching
        state.CIListChatter = action?.payload?.data;
        state.error = null;
      })
      .addCase(competitiveIntelligenceListChatter.rejected, (state, action) => {
        state.error = action.payload || 'Failed to fetch chatter';
      });
  }
});

export default competitiveIntelligenceSlice.reducer;
