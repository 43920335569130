import React from 'react';
import BrandLogo from '../../assests/images/BrandLogo.png'
import BrandAstraLogo from '../../assests/images/BrandAstraLogo.png'
import { HeaderDivMain } from '../CompetitiveIntelligence/ViewCompetitive.styled';

const HeaderForInsights = ({margin,theme=false}) => {
    return (
        <HeaderDivMain theme={theme} margin={margin}>
            <div>
                <img src={theme == true ? BrandLogo :BrandAstraLogo} style={{ width: '110px' }} />
            </div>
            <div>
                {/* <h3></h3> */}
                <h3 style={{ color: theme == true ? 'white':'black' , margin: '0px', fontSize: '15px', paddingBottom: '7px' }}>
                    Comprehensive Competitive Analysis
                </h3>
            </div>
        </HeaderDivMain>
    );
};


export default HeaderForInsights;