import React from 'react';
import styled from 'styled-components';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

const ColoredContainer = styled.div`
  flex-shrink: 0;
  background: linear-gradient(127deg, #5937c8 0%, #391c99 100%);
  padding: 115px 120px 167px 120px;
  margin-top: 231px;
  @media only screen and ${device.tablet} {
    margin-top: 140px;
    padding: 69px 24px 81px 24px;
  }
`;

const HightlightedText = styled.div`
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  width: 241px;
  gap: 10px;
  border-radius: 5px;
  background: #fbbc05;
  color: #151515;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  @media only screen and ${device.tablet} {
    width: 154px;
    font-size: 12px;
    padding: 10px 12px;
  }
`;

const TitleText = styled.div`
  color: #fafafa;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 840px;
  text-align: left;
  margin-top: 40px;
  @media only screen and ${device.tablet} {
    padding: unset;
    font-size: 32px;
    width: unset;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 133px;
  @media only screen and ${device.tablet} {
    margin-top: 63px;
    flex-direction: column;
    gap: 15px;
  }
`;

const ImageTitle = styled.div`
  color: #fafafa;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media only screen and ${device.tablet} {
    width: 100%;
  }
`;

const imageWidth = (window.innerWidth * 0.2).toLocaleString();
const mobileImageWidth = (window.innerWidth * 0.8).toLocaleString();
const ImageText = styled.div`
  color: #e8e8e8;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  width: ${imageWidth}px;
  @media only screen and ${device.tablet} {
    width: unset;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  flex-direction: column;
  height: 330px;
  align-items: flex-start;
  gap: 20px;
  border-radius: 20px;
  border: 2px solid #fafafa;
  background: linear-gradient(163deg, #5937c9 1.04%, #852aac 101.67%);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media only screen and ${device.tablet} {
    height: unset;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    text-align: left;
  }
`;
const WhoIsBrandAstraForBanner = () => {
  return (
    <ColoredContainer>
      <HightlightedText>Who Is Brandastra For?</HightlightedText>
      <TitleText>
        Built for Every Team That Cares About Emotional Engagement
      </TitleText>
      <BottomContainer>
        <ImageContainer>
          <img
            width={
              window.innerWidth < 768
                ? `${mobileImageWidth}`
                : `${imageWidth}px`
            }
            src={require('../../../assests/images/LandingAssets/Agencies.png')}
          />
          <ImageTitle>Agencies</ImageTitle>
          <ImageText>
            Use emotional intelligence to design campaigns that not only perform
            but resonate deeply with audiences.
          </ImageText>
        </ImageContainer>
        <ImageContainer>
          <img
            width={
              window.innerWidth < 768
                ? `${mobileImageWidth}`
                : `${imageWidth}px`
            }
            src={require('../../../assests/images/LandingAssets/MarketingTeams.png')}
          />
          <ImageTitle>Marketing Teams</ImageTitle>
          <ImageText>
            Turn consumer data into emotional strategies that connect, inspire,
            and drive results.
          </ImageText>
        </ImageContainer>
        <ImageContainer>
          <img
            width={
              window.innerWidth < 768
                ? `${mobileImageWidth}`
                : `${imageWidth}px`
            }
            src={require('../../../assests/images/LandingAssets/BusinessLeaders.png')}
          />
          <ImageTitle>Business Leaders</ImageTitle>
          <ImageText>
            Empower your organization with insights that drive loyalty and
            competitive advantage.
          </ImageText>
        </ImageContainer>
      </BottomContainer>
    </ColoredContainer>
  );
};

export default WhoIsBrandAstraForBanner;
