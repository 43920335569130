import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  text-align: left;
  font-family: Open Sans;
`;

export const HeaderWrapper = styled.div`
  padding: 20px 0 20px 25px;
  display: flex;
  flex-direction: column;

  position: relative;
`;
export const Span1 = styled.span`
  color: #636363;
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 700;
`;

export const Span2 = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  margin: 8px 0;
    word-break: break-word;
`;

export const ChannelsText = styled.div`
  display: flex;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.84);
  font-size: 15px;
  font-weight: 600;
  margin-right: 8px;
`;

export const TwitterText = styled.p`
  display: flex;
  align-items: center;
  gap: 2px;
  color: rgba(0, 0, 0, 0.84);
  margin: 0;

  & svg {
    width: 20px;
    height: 20px;
    padding-top: 5px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-top: 1px solid #d2d2d2;
  padding: 30px;
  & .button {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    background: #353cf7;
    color: #fff;

    height: 35px;
    padding: 5px 20px;
    border-radius: 10px;

    border: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    & > span {
      margin-left: 10px;
    }
    &:active {
      transform: scale(0.98);
    }
  }

  & .buttonContainer {
    display: flex;
    gap: 10px;
  }
`;
export const DateWrapper = styled.div`
  display: flex;
  align-items: end;
  gap: 30px;

  font-size: 15px;
  font-weight: 600;

  & input {
    width: 150px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(162, 162, 162, 0.24);
    ${'' /* text-align: center; */} 
    padding: 1px 8px;
    color: #636363;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
`;
export const CreateTopic = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  background: #353cf7;
  color: #fff;

  padding: 10px 20px;
  border-radius: 20px;

  border: 0px;

  &:active {
    transform: scale(0.98);
  }
`;

export const Icon = styled.div``;

export const StyledContainer = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
`;

export const QueryPara = styled.div`
  color: rgba(0, 0, 0, 0.84);
  font-size: 15px;
  margin-top: 10px;
`;

export const TabHeading = styled.div`
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;

  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5px;

  & > div {
    color: #353cf7;
    color: #000;
    font-size: 18px;
    font-weight: 400;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  //Active Tab (Monthly or Annual Plan)
  & .activePlanTab {
    color: #353cf7;
    border-bottom: 2px solid #353cf7;
    font-weight: 600;
  }
`;
export const DarkWhiteThemeDiv = styled.div`
  margin-bottom: 20px;
  width: 250px;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
`;

export const DeletePopupWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > p:nth-child(1) {
    color: #636363;
    text-align: left;
    color: #636363;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  & > p:nth-child(3) {
    //User name that will be displayed in the popup window
    span {
      color: #222;
    }
  }

  // Cancel and Delete button containers
  & > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    & > button {
      width: 200px;
      height: 45px;
      margin: 0 10px;
    }
    & > button:nth-child(1) {
      background-color: #fff;
      color: #222;
      width: max-content;
      margin-right: 30px;
      border: 1px #000;
    }
    & > button:nth-child(2) {
      background-color: #fff;
      color: #222;
      width: max-content;
      margin-right: 30px;
      border: 1px solid #000;
      border-radius: 100px;
      padding: 7px 21px;
    }
  }

  & .selectContainer {
    display: flex;
    justify-content: center;
    & > div {
      width: 40%;
    }
  }
`;

export const DivContainer = styled.div`
  display: flex;
  flex-direction:row;
  margin: 20px 10px 20px 0px;
`;

export const RadioContainer = styled.div`
 margin-left:10px;
 margin-top: 5px;
 padding-left: 10px;
 text-align: center;
 color: #636363;
font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;

 & > input: nth-child(2) {
   margin-left: 80px;
 } 
`;