import React from 'react';
import styled from 'styled-components';
import './MobileNavBarMenu.css';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileNavBarMenu = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={`fullscreen-menu`}>
      <button onClick={() => props?.toggleMenu('what-do-we-do')}>
        What do we do?
      </button>
      <button onClick={() => props?.toggleMenu('solutions')}>Solution</button>
      <button onClick={() => props?.toggleMenu('pricing')}>Pricing</button>
      {!location.pathname?.includes('book-a-demo') && (
        <button
          onClick={() => {
            navigate('/book-a-demo');
          }}
        >
          Book a Demo
        </button>
      )}
    </div>
  );
};

export default MobileNavBarMenu;
