import React, { useEffect } from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { StyledAddIcon } from '../../../../Common/SvgIcon/CommonSvgIcons';
import { useState } from 'react';
import Toggle from './Toggle';
import Input from './Input';
import ItemList from '../ToggleList/ToggleList';

const Wrapper = styled.div`
  //margin: 100px;
  // width: 100%;

  border-radius: 15px;
  border: 1px solid #a2a2a2;
  min-height: 40px;
  padding: 7px 21px;

  font-family: Montserrat;
  font-weight: 500;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  ${(p) =>
    p.openLayout === 'true'
      ? 'min-height:200px; justify-content:start;padding:20px;'
      : ''};

  // plus and arrow down icon container
  & > :nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > :nth-child(2) {
      cursor: pointer;
      ${(p) => (p.openLayout === 'true' ? 'display:none;' : '')};
      color: #353cf7;
    }

    //Arrow button styles
    & > :nth-child(3) {
      ${(p) => (p.openLayout === 'true' ? 'transform: rotate(180deg);' : '')};
    }
  }

  //Add + button styles
  & > :nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: ${(props) => (props.channel == 'facebook' ? '0px' : '20px')};
    padding-bottom: ${(props) =>
      props.channel == 'facebook' ? '0px' : '40px'};

    & > button {
      text-align: center;
      width: 132px;
      height: 39px;
      border-radius: 40px;
      font-size: 16px;
      background: #353cf7;
      color: #fff;
      outline: none;
      border: none;

      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
`;

const PlaceholderWrapper = styled.div`
  width: 83%;
  text-align: left;
  color: #000;
  font-size: 15px;
  font-family: Montserrat;
  line-height: 20px;
  overflow-wrap: break-word;
  word-break: break-word;

  & > span {
    color: #636363;
    font-weight: 400;
  }

  & > span[alt='innerCombinator'] {
    color: #636363;
  }

  & > span[alt='outerCombinator'] {
    color: #353cf7;
  }
`;
const CardContainer = styled.div`
  width: 85%;
  border-radius: 10px;
  background: #f2f2fe;
  padding: 30px 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  position: relative;
`;

const SideLine = styled.div`
  position: absolute;
  bottom: -88px;
  right: -20px;

  height: 110px;
  width: 18px;

  border: 1px solid #222;
  border-left: 0;
`;

const QueryContainer = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 60px;
  }

  & > :last-child {
    margin-bottom: 20px;
  }
`;

const ArrowSpan = styled.span`
  position: relative;
  color: #636363;
  margin-right: -10px;

  cursor: pointer;
`;

const ErrorSpan = styled.span`
  color: red;
  display: flex;
  margin-top: 0px;
  margin-left: 10px;
`;

const ItemListWrapper = styled.div`
  width: 400px;
  height: 250px;
  padding: 7px 21px;
  align-items: center;
  margin: 0px 0px 0px 0px;
  //gap: 10px;
  flex-shrink: 0;
`;

const NoiseLabel = styled.span`
  color: #8e8e8e;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
  padding: 2px 10px;

  color: #d74748;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`;

const InputContainer = ({
  channel,
  sideLine,
  query,
  index,
  addNewInputToGroup,
  removeInputFromGroup,
  updateInputValue,
  updateInputKeywordType,
  toggleIndividualGroupCombinator,
  toggleGroupCombinator,
  keywordType,
  exclude,
}) => {
  const keywords = query.group;

  return (
    <CardContainer>
      {keywords.map((keyword, i) => (
        <Input
          key={i}
          exclude={exclude}
          inputData={{
            index: i,
            inputChangeHandler: (i, value) => updateInputValue(index, i, value),
            removeNewKeyword: (indexToRemove) =>
              removeInputFromGroup(index, indexToRemove),
            addNewKeyword: () => addNewInputToGroup(index, i),
            changeKeywordType: (newKeywordType, i) =>
              updateInputKeywordType(index, i, newKeywordType),
            keyword,
          }}
          keywordType={keywordType}
        />
      ))}
      {channel != 'facebook' && (
        <Toggle
          toggleValue={query.combinator}
          toggleIndividualGroup={() => toggleIndividualGroupCombinator(index)}
        />
      )}

      {sideLine && (
        <Toggle
          pos={{ bottom: '-42px', right: '-40px' }}
          toggleValue={query.combinatorWithNextGroup}
          toggleIndividualGroup={() => toggleGroupCombinator(index)}
        />
      )}
      {sideLine && <SideLine />}
    </CardContainer>
  );
};

const TempComp = ({
  keywordType,
  exclude,
  placeholder = 'Enter keywords',
  setQueryArrays,
  queryArrays,
  error,
  channel,
  returnQueryString = () => {},
}) => {
  const [openLayout, setOpenLayout] = useState(false);
  const [emptyErrorMessage, setEmptyErrorMessage] = useState('');
  const [keywordMessage, setKeywordMessage] = useState('');

  const exclusion = [
    // { id: 1, name: 'Select all', isActive: false },
    { id: 2, name: 'Sweepstakes', isActive: false },
    { id: 3, name: 'Sales Listings', isActive: false },
    { id: 4, name: 'Coupons', isActive: false },
    { id: 5, name: 'Supplemental Income', isActive: false },
    { id: 6, name: 'Cryptocurrency', isActive: false },
    // Add more items as needed
  ];
  const addNewQueryGroup = () => {
    // to add new group to queryArrays
    setQueryArrays([
      ...queryArrays,
      {
        group: [{ value: '', keywordType: 'Word' }],
        combinator: 'OR',
        combinatorWithNextGroup: 'OR',
      },
    ]);
  };

  const addNewInputToGroup = (index, group_index) => {
    // to add new input to specific group
    const temp = [...queryArrays];
    const tempValue = temp[index].group[group_index].value;
    if (
      tempValue === '' ||
      (tempValue.length === 1 &&
        (tempValue.charAt(0) === '#' || tempValue.charAt(0) === '@'))
    ) {
      setEmptyErrorMessage('Please Enter a keyword');
    } else if (
      exclude &&
      (tempValue.length === 2 || tempValue.length === 1) &&
      (tempValue.charAt(1) === '#' ||
        tempValue.charAt(1) === '@' ||
        tempValue.charAt(0) === '-')
    ) {
      setEmptyErrorMessage('Please Enter a keyword');
    } /* else if (
      !exclude &&
      temp[group_index].group[index].keywordType === 'Word' &&
      !tempValue.match(/^[A-Za-z]+$/)
    ) {
      setKeywordMessage('Please enter a valid word or Phrase');
    } */ else {
      temp[index].group.push({ value: '', keywordType: 'Word' });
      setQueryArrays(temp);
      setEmptyErrorMessage('');
      setKeywordMessage('');
    }
  };

  const removeInputFromGroup = (group_index, inputIndex) => {
    // to remove input from specific group
    const temp = [...queryArrays];

    // if only one group and only one input is present then reset the queryArrays
    if (temp[group_index].group.length === 1 && temp.length === 1) {
      setQueryArrays([
        {
          group: [{ value: '', keywordType: 'Word' }],
          combinator: 'OR',
          combinatorWithNextGroup: 'OR',
        },
      ]);
    }
    // if only one input is present in a group then remove the group from queryArrays
    else if (temp[group_index].group.length === 1) {
      temp.splice(group_index, 1);
      setQueryArrays(temp);
    }
    // else remove the input from the specific group
    else {
      temp[group_index].group.splice(inputIndex, 1);
      setQueryArrays(temp);
    }
  };

  // to update input value
  const updateInputValue = (group_index, index, value) => {
    const temp = [...queryArrays];
    //temp[inputIndex].keywordType = keywordType;
    if (temp[group_index].group[index].keywordType === 'Phrase') {
      temp[group_index].group[index].value = value;
    } else if (
      temp[group_index].group[index].keywordType === 'Mentions' ||
      temp[group_index].group[index].keywordType === 'Hashtags'
    ) {
      let hashVal =
        temp[group_index].group[index].keywordType === 'Mentions' ? '@' : '#';
      let hashMentVal = '';
      if (hashVal === '@') {
        hashMentVal = value
          .replace(/^['"]|['"]$/g, '') // Remove leading and trailing matched quotes
          .replace(/['"]/g, '')
          .replace(/[@#]/g, '') // Remove any remaining unmatched quotes
          .trim();
        temp[group_index].group[index].value = `${hashVal}${hashMentVal
          ?.trim()
          ?.split(' ')
          ?.join('')}`;
      } else {
        if (!exclude) {
          hashMentVal = value
            .replace(/^['"]|['"]$/g, '') // Remove leading and trailing matched quotes
            .replace(/['"]/g, '')
            .replace(/[@#]/g, '') // Remove any remaining unmatched quotes
            .trim();
          temp[group_index].group[index].value =
            channel === 'twitter'
              ? "'" + '#' + hashMentVal?.trim()?.split(' ')?.join('') + "'"
              : hashMentVal?.trim()?.split(' ')?.join('');
        } else {
          hashMentVal = value
            .replace(/^['"]|['"]$/g, '') // Remove leading and trailing matched quotes
            .replace(/['"]/g, '')
            .replace(/[@#-]/g, '') // For Twitter Exclusion
            .trim();
          temp[group_index].group[index].value =
            channel === 'twitter'
              ? '-' +
                "'" +
                '#' +
                hashMentVal?.trim()?.split(' ')?.join('') +
                "'"
              : hashMentVal?.trim()?.split(' ')?.join('');
        }
      }
    }else if(temp[group_index].group[index].keywordType === 'Word'){
      let hashMentVal = value
      .replace(/^['"]|['"]$/g, '') // Remove leading and trailing matched quotes
      .replace(/['"]/g, '')
      .replace(/[@]/g, '') // Remove any remaining unmatched quotes
      .trim();
    temp[group_index].group[index].value = hashMentVal
      ?.trim()
      ?.split(' ')
      ?.join('');
    } else {
      let hashMentVal = value
        .replace(/^['"]|['"]$/g, '') // Remove leading and trailing matched quotes
        .replace(/['"]/g, '')
        .replace(/[@]/g, '') // Remove any remaining unmatched quotes
        .trim();
      temp[group_index].group[index].value = hashMentVal
        ?.trim()
        ?.split(' ')
        ?.join('');
    }
    /*  if(exclude && temp[group_index].group[index].keywordType === 'Word') {
      let temp1 = value.charAt(0);
      temp1 = '-';
      temp[group_index].group[index].value = temp1 + value;

    } */
    if (temp[group_index].group[index].keywordType === 'Phrase' && !exclude) {
      const tt = value.replaceAll("'", '');
      temp[group_index].group[index].value = `'${tt}'`;
    }
    if (temp[group_index].group[index].keywordType === 'Phrase' && exclude) {
      const tt = value.replaceAll("'", '');
      if (tt?.length < 2) {
        temp[group_index].group[index].value = `'${tt}'`;
      } else {
        var swapStr = `'${tt}'`;
        swapStr = swapStr[1] + swapStr[0] + swapStr?.slice(2);
        temp[group_index].group[index].value = swapStr;
      }
    }
    // if (
    //   temp[group_index].group[index].keywordType === 'Word' &&
    //   value.charAt(value.length - 1) === ' '
    // ) {
    //   return;
    // }

    if (
      (exclude && value.trim().length === 1) ||
      value.trim() == '' ||
      (temp[group_index].group[index].keywordType === 'From User' &&
        value.trim()?.length < 5) ||
      (temp[group_index].group[index].keywordType === 'To User' &&
        value.trim()?.length < 3)
    ) {
      updateInputKeywordType(
        group_index,
        index,
        temp[group_index].group[index].keywordType
      );
    }
    if (exclude && temp[group_index].group[index].keywordType === 'Word') {
      const trimmedValue = value.trim();
      const updatedValue = trimmedValue.replaceAll('-', '');
      temp[group_index].group[index].value =
        updatedValue.length > 0
          ? '-' + updatedValue?.trim()?.split(' ')?.join('')
          : '';
    }
    setQueryArrays(temp);
    //setQueryArrays(temp);
    setEmptyErrorMessage('');
  };

  //to update keyword type
  const updateInputKeywordType = (group_index, index, newKeywordType) => {
    let temp = [...queryArrays];
    if (temp[group_index]?.group[index]?.keywordType === 'From User') {
      if (temp[group_index]?.group[index]?.value?.startsWith('from:')) {
        if (
          temp[group_index] &&
          temp[group_index].group &&
          temp[group_index].group[index] &&
          temp[group_index].group[index].value?.startsWith('from:')
        ) {
          temp[group_index].group[index].value =
            temp[group_index].group[index].value.slice(5);
        }
      }
    }
    if (temp[group_index]?.group[index]?.keywordType === 'To User') {
      if (temp[group_index]?.group[index]?.value?.startsWith('to:')) {
        if (
          temp[group_index] &&
          temp[group_index].group &&
          temp[group_index].group[index] &&
          temp[group_index].group[index].value?.startsWith('to:')
        ) {
          temp[group_index].group[index].value =
            temp[group_index].group[index].value.slice(3);
        }
      }
    }
    // if (temp[group_index]?.group[index]?.keywordType === 'From User') {
    //   if (temp[group_index]?.group[index]?.value?.startsWith('from:')) {
    //     if (
    //       temp[group_index] &&
    //       temp[group_index].group &&
    //       temp[group_index].group[index] &&
    //       temp[group_index].group[index].value?.startsWith('from:')
    //     ) {
    //       temp[group_index].group[index].value =
    //         temp[group_index].group[index].value.slice(5);
    //     }
    //   }
    // }
    if (
      newKeywordType == 'Word'
      // ["Word"].includes(
      //   temp[group_index].group[index].keywordType.trim()
      // )
    ) {
      let tempVal = temp[group_index]?.group[index]?.value

        .replace(/^['"]|['"]$/g, '') // Remove leading and trailing matched quotes
        .replace(/['"]/g, '') // Remove any remaining unmatched quotes
        .replace(/[@#]/g, '') // Remove hashtags and mentions
        .trim();
      tempVal = tempVal?.trim()?.split(' ')?.join('');
      temp[group_index].group[index].value = tempVal;
    }
    if (
      ['Hashtags', 'Mentions', 'From User', 'To User'].includes(
        newKeywordType // temp[group_index].group[index].keywordType.trim()
      )
    ) {
      let tempValue = temp[group_index]?.group[index]?.value
        .replace(/^['"]|['"]$/g, '') // Remove leading and trailing matched quotes
        .replace(/['"]/g, '')
        .replace(/[@#]/g, '') // Remove any remaining unmatched quotes
        .trim();
      temp[group_index].group[index].value = tempValue;
      // .substring(
      //   exclude ? 2 : 1
      // );
    } else if (exclude) {
      let tempValue = temp[group_index].group[index].value.trim();
      temp[group_index].group[index].value = tempValue.substring(1);
    }
    let hashMentVal = temp[group_index]?.group[index]?.value
      .replace(/^['"]|['"]$/g, '') // Remove leading and trailing matched quotes
      .replace(/['"]/g, '')
      .replace(/[@#]/g, '') // Remove any remaining unmatched quotes
      .trim();
    if (newKeywordType.trim() === 'Hashtags') {
      temp[group_index].group[index].value =
        "'" + '#' + hashMentVal?.trim()?.split(' ')?.join('') + "'";
    } else if (newKeywordType.trim() === 'Mentions') {
      temp[group_index].group[index].value =
        '@' + hashMentVal?.trim()?.split(' ')?.join('');
    } else if (newKeywordType.trim() === 'From User') {
      if (
        temp[group_index].group[index].value === 'from' ||
        temp[group_index].group[index].value === 'rom:' ||
        temp[group_index].group[index].value === 'fom:' ||
        temp[group_index].group[index].value === 'frm:' ||
        temp[group_index].group[index].value === 'fro:'
      ) {
        temp[group_index].group[index].value = 'from:';
      } else {
        temp[group_index].group[index].value =
          'from:' + temp[group_index].group[index].value;
      }
    } else if (newKeywordType.trim() === 'To User') {
      if (
        temp[group_index].group[index].value === 'to' ||
        temp[group_index].group[index].value === 't:' ||
        temp[group_index].group[index].value === 'o:'
      ) {
        temp[group_index].group[index].value = 'to:';
      } else {
        temp[group_index].group[index].value =
          'to:' + temp[group_index].group[index].value;
      }
    } else if (newKeywordType.trim() === 'Phrase') {
      let tempValue = temp[group_index].group[index]?.value
        ?.replace(/^['"]|['"]$/g, '') // Remove leading and trailing matched quotes
        .replace(/[@#]/g, '') // Remove any remaining unmatched quotes
        .replace(/['"]/g, '');
      temp[group_index].group[index].value = `'${tempValue}'`;
    }

    if (exclude) {
      if(newKeywordType==='Hashtags'){
        temp[group_index].group[index].value=temp[group_index].group[index].value?.replace(/[@-]/g, '')
      }
      temp[group_index].group[index].value =
        '-' + temp[group_index].group[index].value;
    }
    temp[group_index].group[index].keywordType = newKeywordType;
    setQueryArrays(temp);
  };

  // to toggle individual group combinator
  const toggleIndividualGroupCombinator = (index) => {
    const temp = [...queryArrays];
    if (temp[index].combinator === 'OR') {
      temp[index].combinator = 'AND';
    } else {
      temp[index].combinator = 'OR';
    }
    setQueryArrays(temp);
  };

  // to toggle group combinator
  const toggleGroupCombinator = (index) => {
    const temp = [...queryArrays];
    if (temp[index].combinatorWithNextGroup === 'OR') {
      temp[index].combinatorWithNextGroup = 'AND';
    } else {
      temp[index].combinatorWithNextGroup = 'OR';
    }
    setQueryArrays(temp);
  };

  function formQueryString() {
    returnQueryString(formQueryString_InStringFormate);
    // To form query string
    let queryString = [];

    // temporary variables for individual group in query
    let temp;
    // Flag variables for checking string has at least one word
    let flag = false;
    for (let i = 0; i < queryArrays.length; i++) {
      temp = queryArrays[i].group;
      queryString.push(
        <React.Fragment key={i}>
          (&nbsp;
          {temp.map((item, index) => {
            if (item.value.length > 0) {
              flag = true;
              return (
                <React.Fragment key={index}>
                  {item.value}
                  {index + 1 !== temp.length && (
                    <span alt='innerCombinator'>
                      &nbsp;&nbsp;
                      {queryArrays[i].combinator}
                      &nbsp;&nbsp;
                    </span>
                  )}
                </React.Fragment>
              );
            }
            return null;
          })}
          &nbsp;)
          {i + 1 !== queryArrays.length && (
            <span alt='outerCombinator'>
              &nbsp;&nbsp;{queryArrays[i].combinatorWithNextGroup}&nbsp;&nbsp;
            </span>
          )}
        </React.Fragment>
      );
    }

    if (!flag) return false;
    return queryString;
  }

  function formQueryString_InStringFormate() {
    //To form query string
    let queryString = [];

    // temporary variables for individual group in query
    let temp;

    //Flag variables for checking string has at least one word
    let flag = false;

    for (let i = 0; i < queryArrays.length; i++) {
      temp = queryArrays[i].group;
      queryString.push('(');
      for (let j = 0; j < temp.length; j++) {
        if (temp[j].value.length > 0) {
          flag = true;
          if (exclude) {
            if (temp[j].value?.includes('#')) {
              let tempStr = temp[j].value?.slice(2, temp[j]?.value?.length);
              tempStr = '-' + "'" + tempStr;
              queryString.push(tempStr);
              queryString.push(queryArrays[i].combinator);
            } else {
              queryString.push(` ${temp[j].value} `);
              queryString.push(queryArrays[i].combinator);
            }
          } else {
            if (queryString?.includes('#')) {
              queryString.push(` '${temp[j].value}' `);
            } else {
              queryString.push(` ${temp[j].value} `);
            }
            queryString.push(queryArrays[i].combinator);
          }
          // queryString.push(` ${temp[j].value} `);

          // queryString.push(queryArrays[i].combinator);
        }
      }
      queryString.pop();
      queryString.push(')');
      queryString.push(queryArrays[i].combinator);
    }
    queryString.pop();

    if (!flag) return false;

    return queryString.join(` `);
  }
  useEffect(() => {
    // Map over the queryArrays to update the group array with 'Word' as keywordType
    let updatedQueryArrays = queryArrays.map((i) => {
      let { group } = i;
      // Update each group's keywordType to 'Word' and reset value if necessary
      group = group.map((val) => {
        return {
          ...val, // Spread the existing properties
          keywordType: 'Word', // Set keywordType to 'Word'
          value: val.value, // Reset value (you can modify this based on your requirements)
        };
      });
      // Return updated group back to the outer object
      return {
        ...i,
        group,
      };
    });

    // Update the state with the modified queryArrays
    setQueryArrays(updatedQueryArrays);
  }, [channel]); // Re-run when the channel changes

  /* function handleOpenLayout() {
    //console.log(formQueryString_InStringFormate())
    //setOpenLayout((prev) => !prev)
    if(exclude) {
      setOpenLayout((prev) => prev)
    } else {
      setOpenLayout((prev) => !prev)
    }/* else {
      setOpenLayout((prev) => prev)
    } */
  return (
    <Wrapper openLayout={openLayout} channel={channel}>
      <div onClick={() => setOpenLayout((prev) => !prev)}>
        <PlaceholderWrapper>
          {formQueryString() || (
            <span data-testid='placeholder-text'>{placeholder}</span>
          )}
        </PlaceholderWrapper>
        <span>{StyledAddIcon}</span>
        <ArrowSpan>
          <KeyboardArrowDownIcon />
        </ArrowSpan>
      </div>

      {openLayout && (
        <div>
          <QueryContainer>
            {queryArrays.map((query, i) => (
              <InputContainer
                channel={channel}
                query={query}
                index={i}
                exclude={exclude}
                key={i}
                sideLine={queryArrays[i + 1] !== undefined ? true : false}
                addNewInputToGroup={addNewInputToGroup}
                removeInputFromGroup={removeInputFromGroup}
                updateInputValue={updateInputValue}
                updateInputKeywordType={updateInputKeywordType}
                toggleIndividualGroupCombinator={
                  toggleIndividualGroupCombinator
                }
                toggleGroupCombinator={toggleGroupCombinator}
                keywordType={keywordType}
              />
            ))}
          </QueryContainer>
          {
            <ErrorSpan>
              {emptyErrorMessage ? emptyErrorMessage : keywordMessage}
            </ErrorSpan>
          }
          {channel != 'facebook' && (
            <button onClick={addNewQueryGroup}>Add {StyledAddIcon}</button>
          )}
        </div>
      )}
      {/* {exclude && openLayout && (
        <ItemListWrapper>
          <NoiseLabel>Exclude Noise</NoiseLabel>
          <ItemList items={exclusion} exclusion={true} />
        </ItemListWrapper>
      )} */}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};

export default TempComp;
