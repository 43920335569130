export const locationX = [
  {
    label: 'Afghanistan',
    value: '33.93911,67.709953',
    radius: '455.6437831',
  },
  {
    label: 'Albania',
    value: '41.1533,20.1683',
    radius: '95.65967075',
  },
  {
    label: 'Algeria',
    value: '28.0339,1.6596',
    radius: '870.7075896',
  },
  {
    label: 'Andorra',
    value: '42.5078,1.5211',
    radius: '12.20528684',
  },
  {
    label: 'Angola',
    value: '-11.2027,17.8733',
    radius: '629.9499465',
  },
  {
    label: 'Antigua and Barbuda',
    value: '17.0608,-61.7964',
    radius: '11.86140673',
  },
  {
    label: 'Argentina',
    value: '-38.4161,-63.6167',
    radius: '940.759697',
  },
  {
    label: 'Armenia',
    value: '40.0691,45.0382',
    radius: '97.3010326',
  },
  {
    label: 'Australia',
    value: '-25.2744,133.7751',
    radius: '1564.751509',
  },
  {
    label: 'Austria',
    value: '47.5162,14.5501',
    radius: '163.3998621',
  },
  {
    label: 'Azerbaijan',
    value: '40.1431,47.5769',
    radius: '166.029022',
  },
  {
    label: 'Bahamas',
    value: '25.0343,-77.3963',
    radius: '66.46431073',
  },
  {
    label: 'Bahrain',
    value: '25.9304,50.6378',
    radius: '15.60471284',
  },
  {
    label: 'Bangladesh',
    value: '23.685,90.3563',
    radius: '216.7325308',
  },
  {
    label: 'Barbados',
    value: '13.1939,-59.5432',
    radius: '11.69928421',
  },
  {
    label: 'Belarus',
    value: '53.7098,27.9534',
    radius: '257.0625067',
  },
  {
    label: 'Belgium',
    value: '50.5039,4.4699',
    radius: '98.576692',
  },
  {
    label: 'Belize',
    value: '17.1899,-88.4976',
    radius: '85.50032074',
  },
  {
    label: 'Benin',
    value: '9.3075,2.3158',
    radius: '189.3375187',
  },
  {
    label: 'Bhutan',
    value: '27.5142,90.4336',
    radius: '110.5494901',
  },
  {
    label: 'Bolivia',
    value: '-16.2902,-63.5887',
    radius: '591.3452402',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: '43.9159,17.6791',
    radius: '127.6577896',
  },
  {
    label: 'Botswana',
    value: '-22.3285,24.6849',
    radius: '430.3143155',
  },
  {
    label: 'Brazil',
    value: '-14.235,-51.9253',
    radius: '1646.406033',
  },
  {
    label: 'Brunei',
    value: '4.5353,114.7277',
    radius: '42.83755938',
  },
  {
    label: 'Bulgaria',
    value: '42.7339,25.4858',
    radius: '187.8666598',
  },
  {
    label: 'Burkina Faso',
    value: '12.2383,-1.5616',
    radius: '294.7678658',
  },
  {
    label: 'Burundi',
    value: '-3.3731,29.9189',
    radius: '94.12670913',
  },
  {
    label: 'Cabo Verde',
    value: '16.538,-23.0418',
    radius: '35.82937023',
  },
  {
    label: 'Cambodia',
    value: '12.5657,104.991',
    radius: '240.0525573',
  },
  {
    label: 'Cameroon',
    value: '3.848,11.5021',
    radius: '389.0217075',
  },
  {
    label: 'Canada',
    value: '56.1304,-106.3468',
    radius: '1782.756061',
  },
  {
    label: 'Central African Republic',
    value: '6.6111,20.9394',
    radius: '445.3110892',
  },
  {
    label: 'Chad',
    value: '15.4542,18.7322',
    radius: '639.3042264',
  },
  {
    label: 'Chile',
    value: '-35.6751,-71.543',
    radius: '490.5861204',
  },
  {
    label: 'China',
    value: '35.8617,104.1954',
    radius: '1747.800779',
  },
  {
    label: 'Colombia',
    value: '4.5709,-74.2973',
    radius: '602.8512884',
  },
  {
    label: 'Comoros',
    value: '-11.875,43.8723',
    radius: '26.67250636',
  },
  {
    label: 'Congo (Congo-Brazzaville)',
    value: '-0.228,15.827',
    radius: '329.9423905',
  },
  {
    label: 'Congo (Democratic Republic of the Congo)',
    value: '-4.0383,21.7587',
    radius: '863.9395136',
  },
  {
    label: 'Costa Rica',
    value: '9.7489,-83.7534',
    radius: '127.5367993',
  },
  {
    label: 'Croatia',
    value: '45.1,15.2',
    radius: '134.2178442',
  },
  {
    label: 'Cuba',
    value: '21.5218,-77.7812',
    radius: '187.0218264',
  },
  {
    label: 'Cyprus',
    value: '35.1265,33.4299',
    radius: '54.26494962',
  },
  {
    label: 'Czech Republic',
    value: '49.8175,15.473',
    radius: '158.4428786',
  },
  {
    label: 'Denmark',
    value: '56.2639,9.5018',
    radius: '116.8771525',
  },
  {
    label: 'Djibouti',
    value: '11.8251,42.5903',
    radius: '85.93479714',
  },
  {
    label: 'Dominica',
    value: '15.414,-61.3704',
    radius: '15.4612653',
  },
  {
    label: 'Dominican Republic',
    value: '18.7357,-70.1627',
    radius: '124.4687128',
  },
  {
    label: 'Ecuador',
    value: '-1.8312,-78.1834',
    radius: '300.4334696',
  },
  {
    label: 'Egypt',
    value: '26.8206,30.8025',
    radius: '564.880293',
  },
  {
    label: 'El Salvador',
    value: '13.7942,-88.8965',
    radius: '81.8386114',
  },
  {
    label: 'Equatorial Guinea',
    value: '1.6508,10.2679',
    radius: '94.49291305',
  },
  {
    label: 'Eritrea',
    value: '15.1794,39.7823',
    radius: '193.4767237',
  },
  {
    label: 'Estonia',
    value: '58.5953,25.0136',
    radius: '119.9841707',
  },
  {
    label: 'Eswatini',
    value: '-26.5225,31.4659',
    radius: '74.34468955',
  },
  {
    label: 'Ethiopia',
    value: '9.145,40.4897',
    radius: '592.8824566',
  },
  {
    label: 'Fiji',
    value: '-16.5782,179.4144',
    radius: '76.26791501',
  },
  {
    label: 'Finland',
    value: '61.9241,25.7482',
    radius: '327.5362284',
  },
  {
    label: 'France',
    value: '46.6034,1.8883',
    radius: '452.6899856',
  },
  {
    label: 'Gabon',
    value: '-0.8037,11.6094',
    radius: '291.8927382',
  },
  {
    label: 'Gambia',
    value: '13.4432,-15.3101',
    radius: '59.9609053',
  },
  {
    label: 'Georgia',
    value: '42.3154,43.3569',
    radius: '148.9503242',
  },
  {
    label: 'Germany',
    value: '51.1657,10.4515',
    radius: '337.1107121',
  },
  {
    label: 'Ghana',
    value: '7.9465,-1.0232',
    radius: '275.5492916',
  },
  {
    label: 'Greece',
    value: '39.0742,21.8243',
    radius: '204.9468654',
  },
  {
    label: 'Grenada',
    value: '12.2628,-61.6042',
    radius: '10.46415791',
  },
  {
    label: 'Guatemala',
    value: '13.4244,-90.227',
    radius: '186.1731592',
  },
  {
    label: 'Guinea',
    value: '9.9456,-9.6966',
    radius: '279.7475892',
  },
  {
    label: 'Guinea-Bissau',
    value: '11.8037,-15.1804',
    radius: '107.2331322',
  },
  {
    label: 'Guyana',
    value: '4.8604,-58.9302',
    radius: '261.5850873',
  },
  {
    label: 'Haiti',
    value: '18.9712,-72.2852',
    radius: '93.9845697',
  },
  {
    label: 'Honduras',
    value: '13.9094,-83.4332',
    radius: '189.2282107',
  },
  {
    label: 'Hungary',
    value: '47.1625,19.5033',
    radius: '172.0805977',
  },
  {
    label: 'Iceland',
    value: '64.9631,-19.0208',
    radius: '181.0688219',
  },
  {
    label: 'India',
    value: '22.3511,78.6677',
    radius: '1591',
  },
  {
    label: 'Indonesia',
    value: '-0.7893,113.9213',
    radius: '778.6161709',
  },
  {
    label: 'Iran',
    value: '32.4279,53.688',
    radius: '724.3181365',
  },
  {
    label: 'Iraq',
    value: '33.2232,43.6793',
    radius: '373.5246101',
  },
  {
    label: 'Ireland',
    value: '53.4129,-8.2439',
    radius: '149.5613273',
  },
  {
    label: 'Israel',
    value: '31.0461,34.8516',
    radius: '81.30987847',
  },
  {
    label: 'Italy',
    value: '41.8719,12.5674',
    radius: '309.7087359',
  },
  {
    label: 'Jamaica',
    value: '18.1096,-77.2975',
    radius: '59.14849076',
  },
  {
    label: 'Japan',
    value: '36.2048,138.2529',
    radius: '346.8619022',
  },
  {
    label: 'Jordan',
    value: '30.5852,36.2384',
    radius: '168.6370121',
  },
  {
    label: 'Kazakhstan',
    value: '48.0196,66.9237',
    radius: '931.3230422',
  },
  {
    label: 'Kenya',
    value: '-1.2921,36.8219',
    radius: '429.8099042',
  },
  {
    label: 'Kiribati',
    value: '-3.3704,-168.734',
    radius: '16.06702579',
  },
  {
    label: 'Korea (North)',
    value: '40.3399,127.5101',
    radius: '195.8786284',
  },
  {
    label: 'Korea (South)',
    value: '35.9078,127.7669',
    radius: '178.4409553',
  },
  {
    label: 'Kuwait',
    value: '29.3759,47.9774',
    radius: '75.31032832',
  },
  {
    label: 'Kyrgyzstan',
    value: '41.2044,74.7661',
    radius: '252.2823419',
  },
  {
    label: 'Laos',
    value: '19.8563,102.4955',
    radius: '274.5465007',
  },
  {
    label: 'Latvia',
    value: '56.8796,24.6032',
    radius: '143.3519025',
  },
  {
    label: 'Lebanon',
    value: '33.8547,35.8623',
    radius: '57.67993525',
  },
  {
    label: 'Lesotho',
    value: '-29.6094,28.2336',
    radius: '98.29698162',
  },
  {
    label: 'Liberia',
    value: '6.4281,-9.4295',
    radius: '188.2813154',
  },
  {
    label: 'Libya',
    value: '26.3351,17.2283',
    radius: '748.3842443',
  },
  {
    label: 'Liechtenstein',
    value: '47.1415,9.5215',
    radius: '7.136496465',
  },
  {
    label: 'Lithuania',
    value: '55.1694,23.8813',
    radius: '144.1722427',
  },
  {
    label: 'Luxembourg',
    value: '49.6117,6.13',
    radius: '28.69057974',
  },
  {
    label: 'Madagascar',
    value: '-18.7669,46.8691',
    radius: '432.2741652',
  },
  {
    label: 'Malawi',
    value: '-13.2543,34.3015',
    radius: '194.2025452',
  },
  {
    label: 'Malaysia',
    value: '4.2105,101.9758',
    radius: '324.4963255',
  },
  {
    label: 'Maldives',
    value: '3.2028,73.2207',
    radius: '9.739422266',
  },
  {
    label: 'Mali',
    value: '17.5707,-3.9962',
    radius: '628.3035686',
  },
  {
    label: 'Malta',
    value: '35.9375,14.3754',
    radius: '10.02925341',
  },
  {
    label: 'Marshall Islands',
    value: '7.1315,171.1845',
    radius: '7.590394548',
  },
  {
    label: 'Mauritania',
    value: '21.0079,-10.9408',
    radius: '572.7844269',
  },
  {
    label: 'Mauritius',
    value: '-20.3484,57.5522',
    radius: '25.48238937',
  },
  {
    label: 'Mexico',
    value: '23.6345,-102.5528',
    radius: '790.7464718',
  },
  {
    label: 'Micronesia',
    value: '7.4256,150.5508',
    radius: '14.94836246',
  },
  {
    label: 'Moldova',
    value: '47.4116,28.3699',
    radius: '103.7909508',
  },
  {
    label: 'Monaco',
    value: '43.7333,7.4167',
    radius: '0.797884561',
  },
  {
    label: 'Mongolia',
    value: '46.8625,103.8467',
    radius: '705.600224',
  },
  {
    label: 'Montenegro',
    value: '42.4411,19.2636',
    radius: '66.81777057',
  },
  {
    label: 'Morocco',
    value: '31.7917,-7.0926',
    radius: '475.6790752',
  },
  {
    label: 'Mozambique',
    value: '-18.6657,35.5296',
    radius: '505.1277281',
  },
  {
    label: 'Myanmar (Burma)',
    value: '21.9139,95.956',
    radius: '464.0705401',
  },
  {
    label: 'Namibia',
    value: '-22.9576,18.4904',
    radius: '512.6416065',
  },
  {
    label: 'Nauru',
    value: '-0.5228,166.9315',
    radius: '2.585441473',
  },
  {
    label: 'Nepal',
    value: '28.3949,84.124',
    radius: '216.4466848',
  },
  {
    label: 'Netherlands',
    value: '52.1326,5.2913',
    radius: '115.4178008',
  },
  {
    label: 'New Zealand',
    value: '-40.9006,174.886',
    radius: '292.0851486',
  },
  {
    label: 'Nicaragua',
    value: '12.8654,-85.2072',
    radius: '203.7146323',
  },
  {
    label: 'Niger',
    value: '17.6078,8.0817',
    radius: '635.0579704',
  },
  {
    label: 'Nigeria',
    value: '9.082,8.6753',
    radius: '542.2586901',
  },
  {
    label: 'North Macedonia',
    value: '41.6086,21.7453',
    radius: '90.46934345',
  },
  {
    label: 'Norway',
    value: '60.472,8.4689',
    radius: '217.581964',
  },
  {
    label: 'Oman',
    value: '21.5126,55.9233',
    radius: '313.8740349',
  },
  {
    label: 'Pakistan',
    value: '30.3753,69.3451',
    radius: '529.8316965',
  },
  {
    label: 'Palau',
    value: '7.5149,134.5825',
    radius: '12.08735859',
  },
  {
    label: 'Panama',
    value: '8.5375,-80.7821',
    radius: '154.9386223',
  },
  {
    label: 'Papua New Guinea',
    value: '-6.314993,143.9555',
    radius: '383.8314053',
  },
  {
    label: 'Paraguay',
    value: '-23.4425,-58.4438',
    radius: '359.8238219',
  },
  {
    label: 'Peru',
    value: '-9.19,-75.0152',
    radius: '639.6068782',
  },
  {
    label: 'Philippines',
    value: '12.8797,121.774',
    radius: '309.0193616',
  },
  {
    label: 'Poland',
    value: '51.9194,19.1451',
    radius: '315.4849073',
  },
  {
    label: 'Portugal',
    value: '39.3999,-8.2245',
    radius: '171.2108566',
  },
  {
    label: 'Qatar',
    value: '25.3548,51.1839',
    radius: '60.68907392',
  },
  {
    label: 'Romania',
    value: '45.9432,24.9668',
    radius: '275.4672614',
  },
  {
    label: 'Russia',
    value: '55.7558,37.6173',
    radius: '2332.925088',
  },
  {
    label: 'Rwanda',
    value: '-1.9403,29.8739',
    radius: '91.56225086',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: '17.3576,-62.782',
    radius: '9.11476167',
  },
  {
    label: 'Saint Lucia',
    value: '13.9094,-60.9789',
    radius: '14.00281721',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: '12.9843,-61.2872',
    radius: '11.12755794',
  },
  {
    label: 'Samoa',
    value: '-13.759,-172.1046',
    radius: '30.077179',
  },
  {
    label: 'San Marino',
    value: '43.9333,12.45',
    radius: '4.406461512',
  },
  {
    label: 'Sao Tome and Principe',
    value: '0.1864,6.6131',
    radius: '17.51715531',
  },
  {
    label: 'Saudi Arabia',
    value: '23.8859,45.0792',
    radius: '827.204678',
  },
  {
    label: 'Senegal',
    value: '14.4974,-14.4524',
    radius: '250.2370025',
  },
  {
    label: 'Serbia',
    value: '44.0165,21.0059',
    radius: '167.7086159',
  },
  {
    label: 'Seychelles',
    value: '-4.6796,55.492',
    radius: '12.03457512',
  },
  {
    label: 'Sierra Leone',
    value: '8.4606,-11.7799',
    radius: '151.1143648',
  },
  {
    label: 'Singapore',
    value: '1.3521,103.8198',
    radius: '15.03329702',
  },
  {
    label: 'Slovakia',
    value: '48.669,19.699',
    radius: '124.9358311',
  },
  {
    label: 'Slovenia',
    value: '46.1511,14.9955',
    radius: '80.33116657',
  },
  {
    label: 'Solomon Islands',
    value: '-29.348,152.9775',
    radius: '30.08776024',
  },
  {
    label: 'Somalia',
    value: '5.1521,46.1996',
    radius: '450.5247242',
  },
  {
    label: 'South Africa',
    value: '-30.5595,22.9375',
    radius: '622.935309',
  },
  {
    label: 'South Sudan',
    value: '6.8777,30.2002',
    radius: '444.1519564',
  },
  {
    label: 'Spain',
    value: '40.4637,-3.7492',
    radius: '401.3256233',
  },
  {
    label: 'Sri Lanka',
    value: '7.8731,80.7718',
    radius: '144.5140534',
  },
  {
    label: 'Sudan',
    value: '12.8628,30.2176',
    radius: '769.7588974',
  },
  {
    label: 'Suriname',
    value: '3.9196,-56.0278',
    radius: '228.353948',
  },
  {
    label: 'Sweden',
    value: '60.1282,18.6435',
    radius: '378.593912',
  },
  {
    label: 'Switzerland',
    value: '46.8182,8.2275',
    radius: '114.6360487',
  },
  {
    label: 'Syria',
    value: '34.8021,38.9968',
    radius: '242.7851411',
  },
  {
    label: 'Taiwan',
    value: '23.6978,120.9605',
    radius: '107.33401',
  },
  {
    label: 'Tajikistan',
    value: '38.861,71.2761',
    radius: '213.4247987',
  },
  {
    label: 'Tanzania',
    value: '-6.369028,34.8888',
    radius: '548.4802051',
  },
  {
    label: 'Thailand',
    value: '15.87,100.9925',
    radius: '404.1425105',
  },
  {
    label: 'Togo',
    value: '8.6195,0.8248',
    radius: '134.4441404',
  },
  {
    label: 'Tonga',
    value: '-21.1781,-175.1982',
    radius: '15.43035304',
  },
  {
    label: 'Trinidad and Tobago',
    value: '10.6918,-61.2225',
    radius: '40.40952507',
  },
  {
    label: 'Tunisia',
    value: '33.8869,9.5375',
    radius: '228.2075382',
  },
  {
    label: 'Turkey',
    value: '38.9637,35.2433',
    radius: '499.3495361',
  },
  {
    label: 'Turkmenistan',
    value: '38.9697,59.5563',
    radius: '395.4200288',
  },
  {
    label: 'Tuvalu',
    value: '-7.1095,177.6493',
    radius: '2.876813696',
  },
  {
    label: 'Uganda',
    value: '1.3733,32.2903',
    radius: '276.9923796',
  },
  {
    label: 'Ukraine',
    value: '48.3794,31.1656',
    radius: '438.2921586',
  },
  {
    label: 'United Arab Emirates',
    value: '23.4241,53.8478',
    radius: '163.1278838',
  },
  {
    label: 'United Kingdom',
    value: '55.3781,-3.436',
    radius: '278.4662841',
  },
  {
    label: 'United States',
    value: '37.0902,-95.7129',
    radius: '1769.210467',
  },
  {
    label: 'Uruguay',
    value: '-32.965,-56.191',
    radius: '236.8353365',
  },
  {
    label: 'Uzbekistan',
    value: '41.3775,64.5853',
    radius: '377.374937',
  },
  {
    label: 'Vanuatu',
    value: '-15.3767,166.9592',
    radius: '62.28867636',
  },
  {
    label: 'Vatican City',
    value: '41.9029,12.4534',
    radius: '0.394932708',
  },
  {
    label: 'Venezuela',
    value: '6.4238,-66.5897',
    radius: '538.8084369',
  },
  {
    label: 'Vietnam',
    value: '14.0583,108.2772',
    radius: '324.6958845',
  },
  {
    label: 'Yemen',
    value: '15.5525,48.5164',
    radius: '409.9480869',
  },
  {
    label: 'Zambia',
    value: '-13.1339,27.8493',
    radius: '489.4525923',
  },
  {
    label: 'Zimbabwe',
    value: '-19.0154,29.1549',
    radius: '352.6780631',
  },
];
