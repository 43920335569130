import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import EllipsisBg from '../../../assests/images/LandingAssets/EllipsisBg.svg';
import MobileEllipsisBG from '../../../assests/images/LandingAssets/MobileEllipsisBG.svg';

const sizes = {
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  desktop: '2560px',
};
export const device = {
  tablet: `(max-width: ${sizes.tablet})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

const ImageBackground = styled.div`
  margin-top: 102px;
  margin-bottom: 102px;
  background-image: url(${EllipsisBg});
  height: 414px;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media only screen and ${device.tablet} {
    background-image: url(${MobileEllipsisBG});
  }
`;

const LightText = styled.div`
  color: #151515;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  @media only screen and ${device.tablet} {
    font-size: 18px;
    max-width: 258px;
  }
`;

const BoldText = styled.div`
  color: #391c99;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media only screen and ${device.tablet} {
    font-size: 24px;
    max-width: 317px;
  }
`;

const DataPointsBanner = () => {
  return (
    <ImageBackground animate={true}>
      <LightText>The Good Thing: There’s Billions of Data Points</LightText>
      <BoldText>The Bad Thing: There’s Billions of Data Points</BoldText>
    </ImageBackground>
  );
};
export default DataPointsBanner;
